import { Box, Grid, Paper } from '@mui/material'
import Breadcrumbs from 'app/components/Breadcrumbs'
import { Feedback } from 'app/components/Feedback'
import { TitleBlock } from 'app/components/TitleBlock'
import { useCustomer } from 'app/modules/Customer'
import React from 'react'
import { names } from 'utils'

import useCustomerReviews from './useCustomerReviews'

const CustomerReviewsPage = () => {
    const { customer } = useCustomer()
    const { reviews, totalCount } = useCustomerReviews()

    if (!customer || reviews.length === 0) {
        return null
    }

    const breadcrumbsLinks = [
        {
            link: '/customers',
            label: 'Клиенты',
        },
        {
            link: `/customers/${customer?.id}`,
            label: names.getFullName({ firstName: customer.firstname, lastName: customer.lastname }) ?? customer.id,
        },
        {
            link: '',
            label: `Все отзывы`,
        },
    ]

    return (
        <Paper
            sx={{
                bgcolor: 'white',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                p: 4,
            }}
            elevation={0}
        >
            <Breadcrumbs items={breadcrumbsLinks} />
            <Box mt={2}>
                <TitleBlock title="Отзывы" count={totalCount} variant="h5" px={0} />
                <Box mt="11px">
                    <Grid container spacing={1}>
                        {reviews.map((item) => (
                            <Grid item key={item.id} xs={4}>
                                <Feedback
                                    name={item.worker.firstname}
                                    text={item.message}
                                    rating={item.score}
                                    id={item.id}
                                    date={item.createdAt}
                                    photoUrl={item.worker.avatarUrl}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </Paper>
    )
}

export default CustomerReviewsPage
