import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { CircleLoader } from 'app/components/Loaders'
import React from 'react'
import { dateHelper } from 'utils'

import useWorkerTariffsHistory from './useWorkerTariffsHistory'

const width = 700

function WorkerTariffHistory() {
    const { tariffs, loaded, error } = useWorkerTariffsHistory()

    if (!loaded) {
        return (
            <Box width={width} display="flex" alignItems="center" justifyContent="center">
                <CircleLoader />
            </Box>
        )
    }

    if (error) {
        return (
            <Box width={width} display="flex" alignItems="center">
                {error}
            </Box>
        )
    }

    if (tariffs.length === 0) {
        return (
            <Box width={width} display="flex" alignItems="center">
                Пока что тут пусто
            </Box>
        )
    }

    return (
        <Table
            sx={(theme) => ({ width: width, '& .MuiTableCell-root': { borderBottomColor: theme.palette.grey[400] } })}
        >
            <TableHead>
                <TableRow>
                    <TableCell sx={{ fontWeight: 600 }} align="left">
                        Тип
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }} align="right">
                        Кол-во откликов
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }} align="right">
                        Стоимость
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }} align="right">
                        Период действия
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {tariffs.map((t) => (
                    <TableRow key={t.id}>
                        <TableCell align="left">{t.typeText}</TableCell>
                        <TableCell align="right">{t.responsesCount}</TableCell>
                        <TableCell align="right">{`${t.purchasePrice.value} ${t.purchasePrice.currencySymbol}`}</TableCell>
                        <TableCell align="right">{`${dateHelper.formatDate(t.activatedAt)} – ${dateHelper.formatDate(
                            t.finishedAt
                        )}`}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default WorkerTariffHistory
