import { useWorker } from 'app/modules/Worker'
import { useMemo } from 'react'
import apiService, { GetWorkerResponsesResponse } from 'services/api-service'
import useSWR from 'swr'

export type WorkerResponseItem = GetWorkerResponsesResponse['items'][number] & {
    worker: {
        id: string
        firstName: string | null
        lastName: string | null
        avatarUrl: string | null
    }
}

const useWorkerResponses = () => {
    const { worker } = useWorker()
    if (!worker) {
        throw Error()
    }

    const params = { workerId: worker.id, limit: 3 }
    const { data, error } = useSWR(['worker-responses', params], () => apiService.getWorkerResponses(params))

    const responses = useMemo<WorkerResponseItem[]>(
        () =>
            (data?.items ?? []).map((item) => ({
                ...item,
                worker: {
                    id: worker.id,
                    firstName: worker.firstname,
                    lastName: worker.lastname,
                    avatarUrl: worker.avatarUrl,
                },
            })),
        [data, worker]
    )

    return {
        responses,
        error,
        loaded: Boolean(data) || Boolean(error),
        totalCount: data?.totalCount ?? 0,
        totalPages: data?.totalPages ?? 0,
        page: data?.page ?? 0,
    }
}

export default useWorkerResponses
