import { useRouteParams, useToast } from 'hooks'
import { useCallback, useEffect, useState } from 'react'
import apiService from 'services/api-service'

type Tariff = {
    id: string
    typeText: string
    availableResponsesCount: number
    totalResponsesCount: number
    purchasePrice: {
        value: number
        currencySymbol: string
    }
    activatedAt: Date
    expiresAt: Date
}

const useWorkerTariff = () => {
    const { workerId = '' } = useRouteParams()
    const toast = useToast()
    const [data, setData] = useState<{
        tariff?: Tariff | null
        loaded: boolean
        error?: string
    }>({ tariff: null, loaded: false })
    const [deactivating, setDeactivating] = useState(false)

    const loadTariff = useCallback(async () => {
        try {
            const tariff = await apiService.getCurrentWorkerTariff(workerId)
            setData({ loaded: true, tariff, error: undefined })
        } catch (error: any) {
            setData({ loaded: true, error: error.message, tariff: undefined })
        }
    }, [workerId, setData])

    const deactivateTariff = useCallback(async () => {
        try {
            setDeactivating(true)
            await apiService.deactivateWorkerTariff(workerId)
            setData((data) => ({ ...data, tariff: null }))
        } catch (error: any) {
            toast.showError(error.message)
        } finally {
            setDeactivating(false)
        }
    }, [workerId, toast, setData, setDeactivating])

    useEffect(() => {
        loadTariff()
    }, [loadTariff])

    return {
        tariff: data.tariff,
        loaded: data.loaded,
        error: data.error,
        updateTariff: loadTariff,
        deactivateTariff,
        deactivating,
    }
}

export default useWorkerTariff
