import { useParams } from 'react-router-dom'

type RouteParams = {
    employeeId?: string
    workerId?: string
    customerId?: string
    orderId?: string
}

function useRouteParams() {
    return useParams<RouteParams>()
}

export default useRouteParams
