import { useWorker } from 'app/modules/Worker'
import { useMemo, useState } from 'react'
import apiService, { GetWorkerResponsesResponse } from 'services/api-service'
import useSWR from 'swr'

export type WorkerResponseItem = GetWorkerResponsesResponse['items'][number] & {
    worker: {
        id: string
        firstName: string | null
        lastName: string | null
        avatarUrl: string | null
    }
}

const useWorkerResponses = () => {
    const { worker } = useWorker()

    const [limit, changeLimit] = useState<10 | 25 | 50>(50)
    const [page, changePage] = useState(1)

    const params = { workerId: worker?.id ?? '', page, limit }
    const { data, error } = useSWR(worker ? ['worker-responses', params] : null, () =>
        apiService.getWorkerResponses(params)
    )

    const responses = useMemo<WorkerResponseItem[]>(() => {
        if (!data || !worker) {
            return []
        }

        return data.items.map((item) => ({
            ...item,
            worker: {
                id: worker.id,
                firstName: worker.firstname,
                lastName: worker.lastname,
                avatarUrl: worker.avatarUrl,
            },
        }))
    }, [data, worker])

    const pagination = useMemo(
        () => (data ? { limit, page, total_pages: data.totalPages } : undefined),
        [data, limit, page]
    )

    return {
        responses,
        error,
        loaded: Boolean(data) || Boolean(error),
        totalCount: data?.totalCount ?? 0,
        totalPages: data?.totalPages ?? 0,
        page: data?.page ?? 0,
        pagination,
        changeLimit,
        changePage,
    }
}

export default useWorkerResponses
