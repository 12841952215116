import { Box, CircularProgress, Skeleton as MuiSkeleton } from '@mui/material'
import React from 'react'

export const CircleLoader = () => <CircularProgress />

type PageLoaderProps = {
    error?: React.ReactNode
}
export const PageLoader = ({ error }: PageLoaderProps) => (
    <Box
        sx={{
            mt: 10,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        }}
    >
        {error || <CircularProgress />}
    </Box>
)

type SkeletonProps = {
    width?: number | string
    height?: number | string
    children?: React.ReactNode
    fullWidth?: boolean
}
export const Skeleton = ({ width, height, children, fullWidth = true }: SkeletonProps) => {
    const w = width ?? (fullWidth ? '100%' : undefined)

    return (
        <MuiSkeleton width={w} height={height} variant="rounded" animation="pulse" sx={{ backgroundColor: '#f4f4f4' }}>
            {children}
        </MuiSkeleton>
    )
}
