import { Box, Grid, IconButton } from '@mui/material'
import { ReactComponent as DndSVG } from 'assets/icons/DND.svg'
import type { Identifier, XYCoord } from 'dnd-core'
import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { TTableRowData } from 'types/ITable'

import { TableRow } from './TableRow'

interface DragItem {
    index: number
}

interface DragTableItemProps {
    item: any
    positionIndex: number
    px: number
    rows: TTableRowData[]
    isDraggable?: boolean
    onPositionChange?: (dragIndex: number, hoverIndex: number) => void
    onPositionChangeFinished?: () => void
    handleClickRow?: (data: any) => void
}

const dragType = 'drag'

export const DragTableItem: React.FC<DragTableItemProps> = ({
    item,
    positionIndex,
    px,
    rows,
    isDraggable,
    onPositionChange,
    onPositionChangeFinished,
    handleClickRow,
}) => {
    const ref = useRef<HTMLDivElement>(null)
    const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
        accept: dragType,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = positionIndex

            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            onPositionChange?.(dragIndex, hoverIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
        drop: onPositionChangeFinished,
    })

    const [{ isDragging }, drag] = useDrag({
        type: dragType,
        item: () => ({
            index: positionIndex,
        }),
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1
    if (isDraggable) {
        drag(drop(ref))
    }

    return (
        <Box
            ref={ref}
            data-handler-id={handlerId}
            key={item.id}
            sx={(theme) => ({
                opacity,
                bgcolor: 'white',
                px: px,
                borderRadius: 1,
                cursor: isDraggable ? 'move' : handleClickRow ? 'pointer' : 'default',
                '&:hover': handleClickRow
                    ? {
                          bgcolor: theme.palette.background.default,
                      }
                    : undefined,
            })}
            onClick={(e) => {
                if (e.target instanceof Element) {
                    if (e.target.tagName.toLowerCase() !== 'svg' && e.target.tagName.toLowerCase() !== 'button') {
                        handleClickRow?.(item)
                    }
                } else {
                    handleClickRow?.(item)
                }
            }}
        >
            <Box
                display={'flex'}
                alignItems={'center'}
                minHeight={'60px'}
                py={2}
                sx={{ borderBottom: '1px solid #f2f2f7' }}
            >
                <Grid container columnSpacing={2}>
                    {rows.map((row, index) => (
                        <React.Fragment key={index}>
                            {row.xs ? (
                                <Grid item xs={row.xs}>
                                    <TableRow>
                                        {!index && isDraggable && (
                                            <IconButton sx={{ cursor: 'move' }}>
                                                <DndSVG />
                                            </IconButton>
                                        )}
                                        {row.element(item)}
                                    </TableRow>
                                </Grid>
                            ) : (
                                <Grid item xs>
                                    <TableRow>
                                        {!index && isDraggable && (
                                            <IconButton sx={{ cursor: 'move' }}>
                                                <DndSVG />
                                            </IconButton>
                                        )}
                                        {row.element(item)}
                                    </TableRow>
                                </Grid>
                            )}
                        </React.Fragment>
                    ))}
                </Grid>
            </Box>
        </Box>
    )
}
