import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { MoreButton } from 'app/components/Button'
import Table from 'app/components/Table'
import { TitleBlock } from 'app/components/TitleBlock'
import { ReactComponent as EditIconSVG } from 'assets/icons/edit_icon.svg'
import React, { useMemo } from 'react'
import apiService from 'services/api-service'
import { TTableRowData } from 'types/ITable'

import { Work } from './types'
import useSortList from './useSortList'

type WorksListProps = {
    loaded: boolean
    editable: boolean
    works: Work[]
    onNewWork: () => void
    onEditWork: (work: Work) => void
    onPositionChanged: () => void
}

function WorksList({
    works: initialWorks,
    onNewWork,
    onEditWork,
    loaded,
    editable,
    onPositionChanged,
}: WorksListProps) {
    const {
        sortedValues: works,
        startSort,
        sortEnabled,
        changeSort,
        saveSort,
        cancelSort,
        sorting,
    } = useSortList(initialWorks, {
        saveHandler: (works) => {
            const sortedWorkIds = works.map((work) => work.id)
            return apiService.updateWorkPositions({ sortedWorkIds })
        },
        onSaved: onPositionChanged,
    })

    const showEditIcon = editable && !sortEnabled
    const showMoreButton = editable && !sortEnabled
    const showSortActions = sortEnabled

    const tableRows: TTableRowData[] = useMemo(
        () => [
            {
                title: 'Название',
                name: 'name',
                isSort: false,
                element: (work: Work) => (
                    <>
                        <Box
                            alt={work.name}
                            src={work.iconUrl}
                            component={'img'}
                            width={'28px'}
                            height={'28px'}
                            ml={2}
                        />
                        <Typography variant="body2" fontWeight={600} ml={2}>
                            {work.name}
                        </Typography>
                    </>
                ),
            },
            {
                title: 'Цена, ₽',
                name: 'avgPrice',
                isSort: false,
                element: (work: Work) => (
                    <Typography variant="body2" color="grey.600">
                        {work.avgPrice}
                    </Typography>
                ),
            },
            {
                title: 'Ед. измерения',
                name: 'priceUnit',
                isSort: false,
                element: (work: Work) => (
                    <Typography variant="body2" color="grey.600">
                        {work.priceUnit}
                    </Typography>
                ),
            },
            {
                title: 'Ключевые слова',
                name: 'keywordsCount',
                element: (work: Work) => (
                    <Typography variant="body2" color="grey.600">
                        {work.keywords.length}
                    </Typography>
                ),
            },
            {
                title: 'Онлайн-услуга',
                name: 'onlineWork',
                element: (work: Work) => (
                    <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                        <Typography variant="body2" color="grey.600">
                            {work.online ? 'Да' : 'Нет'}
                        </Typography>

                        {showEditIcon && (
                            <IconButton
                                onClick={(e) => {
                                    e.preventDefault()
                                    onEditWork(work)
                                }}
                            >
                                <EditIconSVG />
                            </IconButton>
                        )}
                    </Box>
                ),
            },
        ],
        [onEditWork]
    )

    const rightElement = (
        <>
            {showMoreButton && (
                <MoreButton
                    options={[
                        { title: 'Создать услугу', onSelect: onNewWork },
                        { title: 'Изменить порядок', onSelect: startSort },
                    ]}
                    position="right"
                />
            )}
            {showSortActions && (
                <>
                    <Button variant="contained" color="grey" onClick={cancelSort} disabled={sorting}>
                        Отменить
                    </Button>
                    <LoadingButton
                        loading={sorting}
                        variant="contained"
                        color="primary"
                        sx={{
                            ml: 1,
                        }}
                        onClick={saveSort}
                    >
                        Сохранить
                    </LoadingButton>
                </>
            )}
        </>
    )

    return (
        <Box>
            <TitleBlock title="Услуги" rightElement={rightElement} />

            <Box mt={4}>
                <Table
                    isLoading={!loaded}
                    items={works}
                    rows={tableRows}
                    isDraggable={sortEnabled}
                    onPositionChange={sortEnabled ? changeSort : undefined}
                />
            </Box>
        </Box>
    )
}

export default WorksList
