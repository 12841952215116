import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, Button, Grid } from '@mui/material'
import { Feedback } from 'app/components/Feedback'
import { TitleBlock } from 'app/components/TitleBlock'
import React from 'react'
import { CustomerReview } from 'services/api-service'

type CustomerReviewsProps = {
    reviews: CustomerReview[]
    totalCount: number
    onShowAllReviews: () => void
}

function CustomerReviews({ reviews, totalCount, onShowAllReviews }: CustomerReviewsProps) {
    return (
        <Box>
            <TitleBlock
                title="Отзывы"
                count={totalCount}
                variant="h5"
                px={0}
                rightElement={
                    totalCount > 0 && (
                        <Button
                            variant="text"
                            onClick={onShowAllReviews}
                            color="secondary"
                            endIcon={<ChevronRightIcon />}
                        >
                            Показать все
                        </Button>
                    )
                }
            />
            <Box mt="11px">
                <Grid container spacing={1}>
                    {reviews &&
                        reviews.map((item) => (
                            <Grid item key={item.id} xs={4}>
                                <Feedback
                                    name={item.worker.firstname}
                                    text={item.message}
                                    rating={item.score}
                                    id={item.id}
                                    date={item.createdAt}
                                    photoUrl={item.worker.avatarUrl}
                                />
                            </Grid>
                        ))}
                </Grid>
            </Box>
        </Box>
    )
}

export default CustomerReviews
