import { Grid } from '@mui/material'
import { CityFilter } from 'app/components/Filters'
import React, { useCallback } from 'react'

import useFilter from './useFilter'

const Filters = () => {
    const { filter, changeFilter } = useFilter()

    const handleChangeCityFilter = useCallback((cityId: string | undefined) => changeFilter({ cityId }), [changeFilter])

    return (
        <Grid container mt={1} columnSpacing={2} rowSpacing={2}>
            <Grid item>
                <CityFilter cityId={filter.cityId} onChange={handleChangeCityFilter} />
            </Grid>
        </Grid>
    )
}

export default Filters
