export const getFullName = ({
    firstName,
    lastName,
}: {
    firstName?: string | null
    lastName?: string | null
}): string | null => {
    const parts = [firstName, lastName].map((v) => v?.trim()).filter(Boolean)

    if (parts.length === 0) {
        return null
    }

    return parts.join(' ')
}
