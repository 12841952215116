import { useEffect, useState } from 'react'

function useForwardState<S>(value: S) {
    const [state, setState] = useState(value)

    useEffect(() => {
        setState(value)
    }, [value])

    return [state, setState] as const
}

export default useForwardState
