import { useUser } from 'hooks'
import React, { useEffect } from 'react'
import apiService from 'services/api-service'

import LoginForm from './LoginForm'

const Auth: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { loaded, logged, update, logout } = useUser()

    useEffect(() => apiService.onUnathorized(logout), [logout])

    if (!loaded) {
        return null
    }

    if (!logged) {
        return <LoginForm onLogin={update} />
    }

    return <React.Fragment>{children}</React.Fragment>
}

export default Auth
