import { LoadingButton } from '@mui/lab'
import { SxProps } from '@mui/material'
import React from 'react'

type PrimaryButtonProps = {
    variant?: 'contained' | 'outlined'
    size?: 'small' | 'medium' | 'large'
    loading?: boolean
    fullWidth?: boolean
    children: React.ReactNode
    onClick?: () => void
    sx?: SxProps
}

function PrimaryButton({
    size = 'medium',
    variant = 'contained',
    loading,
    fullWidth,
    onClick,
    sx,
    children,
}: PrimaryButtonProps) {
    return (
        <LoadingButton
            variant={variant}
            size={size}
            loading={loading}
            fullWidth={fullWidth}
            color="primary"
            onClick={onClick}
            sx={sx}
        >
            {children}
        </LoadingButton>
    )
}

export default PrimaryButton
