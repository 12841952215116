import { Box, Grid, Typography } from '@mui/material'
import CityName from 'app/components/CityName'
import { ReactComponent as CalendarSvg } from 'assets/icons/Calendar.svg'
import { ReactComponent as LocationSvg } from 'assets/icons/Location.svg'
import { ReactComponent as PhoneSvg } from 'assets/icons/Phone.svg'
import React from 'react'
import { dateHelper, formatPhone } from 'utils'

import Avatar from '../../components/Avatar'
import { Customer } from './types'

type CustomerProfileProps = {
    customer: Customer
}
function CustomerProfile({ customer }: CustomerProfileProps) {
    return (
        <Grid container justifyContent="space-between">
            <Grid item>
                <Grid container spacing={4}>
                    <Grid item>
                        <Avatar
                            srcUrl={customer.avatarUrl}
                            firstName={customer.firstname}
                            lastName={customer.lastname}
                            size="l"
                        />
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <Typography variant="h4" fontWeight={700}>
                                            {customer.lastname} {customer.firstname}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box>
                            <Typography variant="subtitle2" color="grey.600">
                                Клиент
                            </Typography>
                        </Box>
                        <Box>
                            <Grid container>
                                <Grid item>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <PhoneSvg />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle2" color="grey.600">
                                                {formatPhone(customer.phone)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ marginLeft: '70px' }}>
                                    {customer.cityId && (
                                        <Grid container>
                                            <Grid item sx={{ mr: '6px' }}>
                                                <LocationSvg />
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={(theme) => ({ color: theme.palette.grey['600'] })}
                                                >
                                                    <CityName cityId={customer.cityId} />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                        <Box>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <CalendarSvg />
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2" color="grey.600">
                                        {`На сервисе с ${dateHelper.formatDate(customer.createdAt, {
                                            format: 'DD.MM.YYYY',
                                        })}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CustomerProfile
