import { Box } from '@mui/material'
import PhotosList from 'app/components/PhotosList'
import { TitleBlock } from 'app/components/TitleBlock'
import React from 'react'

type WorkerPortfolioProps = {
    photoUrls: string[]
}

function WorkerPortfolio({ photoUrls }: WorkerPortfolioProps) {
    return (
        <Box>
            <TitleBlock title="Фотографии работ" count={photoUrls.length} variant="h5" px={0} mb={2} />
            <PhotosList photoUrls={photoUrls} />
        </Box>
    )
}

export default WorkerPortfolio
