import { Box, Dialog } from '@mui/material'
import React, { useState } from 'react'
import Carousel from 'react-material-ui-carousel'

const width = 1000
const height = 700

type PhotosListProps = {
    photoUrls: string[]
}

function PhotosList({ photoUrls }: PhotosListProps) {
    const [photoIndex, setPhotoIndex] = useState<number>()
    const opened = photoIndex !== undefined

    if (photoUrls.length === 0) {
        return null
    }

    return (
        <Box display="flex" flexWrap="wrap" gap={2}>
            {photoUrls.map((photoUrl, index) => (
                <Box
                    key={photoUrl}
                    onClick={() => setPhotoIndex(index)}
                    sx={{
                        width: '100px',
                        height: '100px',
                        background: `#faf9f7 url(${photoUrl}) center no-repeat`,
                        backgroundSize: 'cover',
                        borderRadius: 2,
                        cursor: 'pointer',
                    }}
                />
            ))}

            <Dialog open={opened} onClose={() => setPhotoIndex(undefined)} maxWidth={false}>
                <Box width={width} height={height}>
                    <Carousel
                        index={photoIndex}
                        height={height}
                        autoPlay={false}
                        indicators={false}
                        navButtonsAlwaysVisible
                        cycleNavigation
                    >
                        {photoUrls.map((photoUrl) => (
                            <Box
                                key={photoUrl}
                                sx={{
                                    height,
                                    width,
                                    background: `#faf9f7 url(${photoUrl}) center no-repeat`,
                                    backgroundSize: 'contain',
                                }}
                            />
                        ))}
                    </Carousel>
                </Box>
            </Dialog>
        </Box>
    )
}

export default PhotosList
