import { Box, Grid, Paper } from '@mui/material'
import Autocomplete from 'app/components/Autocomplete'
import Input, { PhoneInput } from 'app/components/Input'
import Select from 'app/components/Select'
import { TitleBlock } from 'app/components/TitleBlock'
import React from 'react'

import useNewEmployee from './useNewEmployee'

const NewEmployeePage: React.FC = () => {
    const { values, changeValues, needSelectCity, cityOptions, roleOptions, createEmployee } = useNewEmployee()

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        changeValues({ [name]: value })
    }

    const handleSelectRole = (role: string) => {
        changeValues({ role })
    }

    const handleSelectCities = (cityIds: string[]) => {
        changeValues({ cityIds })
    }

    return (
        <Paper
            sx={{
                bgcolor: 'white',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                py: 4,
            }}
            elevation={0}
        >
            <TitleBlock title="Добавление сотрудника" addText="Сохранить" handleCreate={createEmployee} />
            <Box pt={4} pl={4} pr={4} maxWidth={600}>
                <Grid container spacing={3} direction="column">
                    <Grid item>
                        <Input
                            label="Имя"
                            placeholder="Иван"
                            name="firstname"
                            value={values.firstname}
                            onChange={onChangeInput}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            label="Фамилия"
                            placeholder="Иванов"
                            name="lastname"
                            value={values.lastname}
                            onChange={onChangeInput}
                        />
                    </Grid>
                    <Grid item>
                        <PhoneInput name="phone" label="Телефон" value={values.phone} onChange={onChangeInput} />
                    </Grid>
                    <Grid item>
                        <Select value={values.role} label="Роль" options={roleOptions} onChange={handleSelectRole} />
                    </Grid>
                    {needSelectCity && (
                        <Grid item>
                            <Autocomplete
                                value={values.cityIds}
                                onChange={handleSelectCities}
                                label="Города"
                                options={cityOptions}
                                multiple
                            />
                        </Grid>
                    )}
                    <Grid item>
                        <Input
                            label="E-mail"
                            placeholder="i.ivanov@dbz.ru"
                            name="email"
                            value={values.email}
                            onChange={onChangeInput}
                            type="email"
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            type="password"
                            name="password"
                            label="Пароль"
                            placeholder="Буквы, цифры, символы"
                            value={values.password}
                            onChange={onChangeInput}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            type="password"
                            label="Пароль ещё раз"
                            placeholder="Буквы, цифры, символы"
                            name="repeatedPassword"
                            value={values.repeatedPassword}
                            onChange={onChangeInput}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )
}

export default NewEmployeePage
