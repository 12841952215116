import { colors, createTheme, Palette } from '@mui/material'

const defaultTheme = createTheme()

const palette: Palette = {
    ...defaultTheme.palette,
    primary: {
        light: '',
        contrastText: '#FFFFFF',
        main: '#4BB56F',
        dark: '#214A37',
    },
    secondary: {
        ...defaultTheme.palette.secondary,
        main: '#8a8a8e',
    },
    background: {
        ...defaultTheme.palette.background,
        default: '#faf9f7',
    },
    text: {
        primary: '#000000',
        secondary: '',
        disabled: '',
    },
    warning: {
        ...defaultTheme.palette.warning,
        main: '#FF9500',
    },
    grey: {
        ...defaultTheme.palette.grey,
        400: '#c5c5c7',
        600: '#8a8a8e',
    },
    divider: '#f2f2f7',
}

export const customTheme = createTheme({
    palette,
    components: {
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    width: '42px',
                    height: '32px',
                    borderColor: '#E5E5EA',
                    '&.Mui-selected': {
                        backgroundColor: 'black',
                        borderColor: 'black',
                        color: 'white',
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: 'black',
                        borderColor: 'black',
                        color: 'white',
                    },
                },
            },
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    marginLeft: '-4px',
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    backgroundColor: '#FAF9F7',
                    margin: '0 4px',
                    '&.Mui-selected': {
                        backgroundColor: 'black',
                        color: 'white',
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: 'black',
                        color: 'white',
                    },
                },
                ellipsis: {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    textTransform: 'none',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
        // MuiSelect: {
        //     styleOverrides: {
        //         icon: {
        //             fill: 'none',
        //         },
        //     },
        // },
        MuiFormControl: {
            variants: [
                {
                    props: { variant: 'standard' },
                    style: {
                        '& .MuiInput-root': {
                            '&:before': {
                                borderBottomColor: palette.divider,
                            },
                            '&:before, &:after': {
                                borderWidth: '1px !important',
                            },
                        },
                        '& .MuiInputLabel-root': {
                            transform: 'translate(0, 29px) scale(1)',
                        },
                        '& .MuiInputLabel-shrink': {
                            transform: 'translate(0, 1.5px) scale(0.75)',
                        },
                    },
                },
                {
                    props: { variant: 'filled' },
                    style: {
                        '& .MuiInputBase-root': {
                            borderRadius: '8px',
                            backgroundColor: '#FAF9F7',
                        },
                    },
                },
            ],
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    borderRadius: '8px',
                    boxShadow: 'none',
                    textTransform: 'initial',
                    fontSize: '15px',

                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                outlined: {
                    borderRadius: 0,
                    boxShadow: 'none',

                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                textSecondary: {
                    color: '#757575',
                },
            },
            variants: [
                {
                    props: { color: 'grey' },
                    style: {
                        backgroundColor: '#F5F3F3',

                        '&:hover': {
                            backgroundColor: '#E0E0E0',
                        },
                    },
                },
                {
                    props: { variant: 'checkout' },
                    style: {
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingTop: 14,
                        paddingRight: 20,
                        paddingBottom: 14,
                        paddingLeft: 20,
                        color: palette.primary.contrastText,
                        backgroundColor: palette.primary.main,

                        '&:hover': {
                            backgroundColor: palette.primary.dark,
                        },
                    },
                },
                {
                    props: { color: 'light' },
                    style: {
                        color: colors.common.white,
                        border: `1px solid ${colors.common.white}`,
                    },
                },
                {
                    props: { size: 'large' },
                    style: {
                        paddingTop: 18,
                        paddingBottom: 18,
                        paddingLeft: 42,
                        paddingRight: 42,
                    },
                },
                {
                    props: { variant: 'banner' },
                    style: {
                        paddingTop: 18,
                        paddingBottom: 18,
                        paddingLeft: 42,
                        paddingRight: 42,
                    },
                },
                {
                    props: { variant: 'banner', color: 'light' },
                    style: {
                        color: colors.common.white,
                        border: `1px solid ${colors.common.white}`,
                    },
                },
                {
                    props: { variant: 'banner', color: 'dark' },
                    style: {
                        color: colors.common.black,
                        border: `1px solid ${colors.common.black}`,
                    },
                },
                {
                    props: { variant: 'banner', color: 'dark' },
                    style: {
                        color: colors.common.black,
                        border: `1px solid ${colors.common.black}`,
                    },
                },
            ],
        },
        MuiContainer: {
            defaultProps: {
                maxWidth: false,
            },
        },
        MuiTypography: {
            variants: [
                {
                    props: {},
                    style: {
                        textDecoration: 'none',
                    },
                },
                {
                    props: { variant: 'body1' },
                    style: {
                        fontSize: '1.125rem',
                        lineHeight: 1.35,
                        textDecoration: 'none',
                    },
                },
                {
                    props: { variant: 'body2' },
                    style: {
                        lineHeight: 1.75,
                    },
                },
                {
                    props: { variant: 'body3' },
                    style: { fontSize: '0.75rem', lineHeight: 1.35 },
                },
            ],
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1920,
        },
    },
    typography: {
        // fontFamily: ['Gotham Pro'].join(','),
    },
})
