import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import { IconButton, Menu, MenuItem } from '@mui/material'
import React, { useCallback } from 'react'

export type MoreButtonOption = { title: string; onSelect: () => void }

type MoreButtonProps = {
    options: MoreButtonOption[]
    position: 'left' | 'right'
}

function MoreButton({ options, position }: MoreButtonProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget)
        },
        [setAnchorEl]
    )

    const handleClose = useCallback(() => {
        setAnchorEl(null)
    }, [setAnchorEl])

    const handleSelect = useCallback(
        (callback: () => void) => {
            callback()
            handleClose()
        },
        [handleClose]
    )

    return (
        <>
            <IconButton id="more-button" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="more-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: position,
                }}
                sx={(theme) => ({
                    '& .MuiPaper-root': {
                        borderRadius: 2,
                        boxShadow: '0 1px 10px rgb(0 0 0 / 10%)',
                    },
                    '& .MuiMenuItem-root.Mui-focusVisible': {
                        backgroundColor: 'transparent',
                    },
                    '& .MuiMenuItem-root.Mui-selected': {
                        backgroundColor: 'rgba(75, 181, 111, 0.08)',
                    },
                    '& .MuiMenuItem-root:hover': {
                        backgroundColor: theme.palette.background.default,
                    },
                })}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: position,
                }}
            >
                {options.map((option) => (
                    <MenuItem disableRipple key={option.title} onClick={() => handleSelect(option.onSelect)}>
                        {option.title}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default MoreButton
