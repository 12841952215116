import { useMemo, useState } from 'react'
import apiService from 'services/api-service'
import useSWR from 'swr'

import useFilter from './useFilter'

export type Tariff = {
    id: string
    name: string
    purchasesPriceText: string
    purchasedAt: string
    worker: {
        id: string
        firstname?: string
        lastname?: string
        avatarUrl?: string
        cityId?: string
    }
}

type Sort = { row: string; order: 'asc' | 'desc' }

const FIVE_MINUTES = 5 * 60 * 1000

const useFinance = () => {
    const { filter } = useFilter()
    const [limit, changeLimit] = useState<10 | 25 | 50>(50)
    const [page, changePage] = useState(1)
    const [sort, changeSort] = useState<Sort>()

    const { data, error } = useSWR(
        ['finance', limit, page, sort, filter],
        () => apiService.getTariffPurchasesAnalytics({ limit, page, sort, filter }),
        { focusThrottleInterval: FIVE_MINUTES, refreshInterval: FIVE_MINUTES }
    )

    const pagination = useMemo(
        () => (data ? { limit, page, total_pages: data.totalPages } : undefined),
        [data, limit, page]
    )

    return {
        data: {
            tariffs: data?.tariffs ?? [],
            totalCount: data?.totalCount ?? 0,
            purchaseTotalSumText: data?.purchasesTotalSumText,
            pagination,
            sort,
        },
        changeLimit,
        changeSort,
        changePage,
        loading: !data,
        error,
    }
}

export default useFinance
