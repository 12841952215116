import { useMemo } from 'react'
import apiService, { GetOrderResponsesResponse } from 'services/api-service'
import useSWR from 'swr'

import useOrder from '../useOrder'

export type OrderResponseItem = GetOrderResponsesResponse['items'][number] & {
    customer: {
        id: string
        firstName: string | null
        lastName: string | null
        avatarUrl: string | null
    }
}

const useOrderResponses = () => {
    const { order } = useOrder()
    if (!order) {
        throw Error()
    }

    const { data, error } = useSWR(['order-responses', order.id], () =>
        apiService.getOrderResponses({ orderId: order.id })
    )

    const responses = useMemo<OrderResponseItem[]>(
        () =>
            (data?.items ?? []).map((item) => ({
                ...item,
                customer: {
                    id: order.customer.id,
                    firstName: order.customer.firstname,
                    lastName: order.customer.lastname,
                    avatarUrl: order.customer.avatarUrl ?? null,
                },
            })),
        [data, order]
    )

    return {
        responses,
        error: error?.message,
        loaded: Boolean(data) || Boolean(error),
        totalCount: data?.totalCount ?? 0,
        totalPages: data?.totalPages ?? 0,
        page: data?.page ?? 0,
    }
}

export default useOrderResponses
