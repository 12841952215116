import { useRouteParams } from 'hooks'
import { useEffect, useState } from 'react'
import apiService, { Order } from 'services/api-service'

const useCustomerOrders = () => {
    const { customerId = '' } = useRouteParams()

    const [data, setData] = useState<{
        data?: {
            items: Order[]
            totalCount: number
        }
        loaded?: boolean
        error?: string
    }>({})

    useEffect(() => {
        apiService
            .getOrders({ filter: { customerId }, limit: 3 })
            .then((data) => {
                setData({ data, loaded: true })
            })
            .catch((error) => setData({ loaded: true, error: error.data.message }))
    }, [customerId])

    return {
        orders: data.data?.items ?? [],
        totalCount: data.data?.totalCount ?? 0,
        loaded: data.loaded,
        error: data.error,
    }
}

export default useCustomerOrders
