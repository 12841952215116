import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

type GetToOrdersPageLinkParams = { customerId?: string; workerId?: string; hideFilters?: boolean }
type GetToWorkerResponsesPageLinkParams = { workerId: string }

export function useRouter() {
    const history = useHistory()

    const getToOrdersPageLink = useCallback(({ customerId, workerId, hideFilters }: GetToOrdersPageLinkParams = {}) => {
        const query = [
            customerId ? `customerId=${customerId}` : '',
            workerId ? `workerId=${workerId}` : '',
            hideFilters ? `hideFilters=1` : '',
        ]
            .filter(Boolean)
            .join('&')

        return query ? `/orders/?${query}` : '/orders'
    }, [])
    const goToOrdersPage = useCallback(
        (params: GetToOrdersPageLinkParams = {}) => history.push(getToOrdersPageLink(params)),
        [history, getToOrdersPageLink]
    )
    const goToOrderPage = useCallback((orderId: string) => history.push(`/orders/${orderId}`), [history])

    const getToWorkerResponsesPageLink = useCallback(
        ({ workerId }: GetToWorkerResponsesPageLinkParams) => `/workers/${workerId}/responses`,
        []
    )
    const goToWorkerPage = useCallback(
        (workerId: string, { openNewTab = false }: { openNewTab?: boolean } = {}) => {
            const location = { pathname: `/workers/${workerId}` }
            if (openNewTab) {
                window.open(history.createHref(location), '_blank')
            } else {
                history.push(location)
            }
        },
        [history]
    )
    const goToWorkerResponsesPage = useCallback(
        (params: GetToWorkerResponsesPageLinkParams) => history.push(getToWorkerResponsesPageLink(params)),
        [history, getToWorkerResponsesPageLink]
    )
    const goToWorkerReviewsPage = useCallback(
        (workerId: string) => history.push(`/workers/${workerId}/reviews`),
        [history]
    )

    const goToCustomerPage = useCallback((customerId: string) => history.push(`/customers/${customerId}`), [history])
    const goToCustomerReviewsPage = useCallback(
        (customerId: string) => history.push(`/customers/${customerId}/reviews`),
        [history]
    )

    const goToNewEmployeePage = useCallback(() => history.push('/employees/new'), [history])
    const goToEmployeePage = useCallback((employeId: string) => history.push(`/employees/${employeId}`), [history])
    const goToEmployeesPage = useCallback(() => history.push('/employees'), [history])

    return {
        getToWorkerResponsesPageLink,
        goToWorkerResponsesPage,
        getToOrdersPageLink,
        goToOrdersPage,
        goToOrderPage,
        goToWorkerPage,
        goToNewEmployeePage,
        goToEmployeePage,
        goToEmployeesPage,
        goToWorkerReviewsPage,
        goToCustomerPage,
        goToCustomerReviewsPage,
    }
}
