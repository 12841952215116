import { EMPLOYEE_ROLE_NAMES, EmployeeRole } from 'app/constants'
import { useCities, useRouter, useToast, useUser } from 'hooks'
import { useCallback, useMemo, useState } from 'react'
import apiService from 'services/api-service'

const NEW_EMPLOYEE_ROLES_BY_USER_ROLE = {
    [EmployeeRole.DIRECTOR]: [
        EmployeeRole.DIRECTOR,
        EmployeeRole.MANAGER,
        EmployeeRole.CITY_DIRECTOR,
        EmployeeRole.CITY_MANAGER,
    ],
    [EmployeeRole.MANAGER]: [EmployeeRole.CITY_DIRECTOR, EmployeeRole.CITY_MANAGER],
    [EmployeeRole.CITY_DIRECTOR]: [EmployeeRole.CITY_DIRECTOR, EmployeeRole.CITY_MANAGER],
    [EmployeeRole.CITY_MANAGER]: [],
}

type Values = {
    firstname: string
    lastname: string
    phone: string
    role: string
    cityIds: string[]
    email: string
    password: string
    repeatedPassword: string
}

const useNewEmployee = () => {
    const toast = useToast()
    const router = useRouter()
    const { user, permissions } = useUser()
    const { cities, cityById } = useCities()
    const [values, setValues] = useState<Values>({
        firstname: '',
        lastname: '',
        phone: '',
        role: '',
        cityIds: [],
        email: '',
        password: '',
        repeatedPassword: '',
    })

    const cityOptions = useMemo(() => {
        if (!user) {
            return []
        }

        if (permissions.canManageAllCities) {
            return cities.map((city) => ({ value: city.id, title: city.name }))
        }

        if (permissions.canManageOwnCities) {
            return user.cityIds
                .map((cityId) => cityById[cityId])
                .filter(Boolean)
                .map((city) => ({ value: city.id, title: city.name }))
        }

        return []
    }, [user, permissions, cities, cityById])

    const roleOptions = useMemo(
        () =>
            user
                ? NEW_EMPLOYEE_ROLES_BY_USER_ROLE[user.role].map((role) => ({
                      value: role,
                      title: EMPLOYEE_ROLE_NAMES[role],
                  }))
                : [],
        [user]
    )

    const changeValues = useCallback(
        (newValues: Partial<Values>) => setValues((oldValue) => ({ ...oldValue, ...newValues })),
        []
    )

    const createEmployee = useCallback(async () => {
        try {
            await apiService.createEmployee(values)
            router.goToEmployeesPage()
        } catch (error: any) {
            toast.showError(error.message)
        }
    }, [values, toast, router])

    const needSelectCity = values.role === EmployeeRole.CITY_DIRECTOR || values.role === EmployeeRole.CITY_MANAGER

    return {
        values,
        changeValues,
        needSelectCity,
        cityOptions,
        roleOptions,
        createEmployee,
    }
}

export default useNewEmployee
