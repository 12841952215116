import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { Box, Collapse, Typography } from '@mui/material'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { TMenuItem } from './types'

interface MenuItemProps {
    item: TMenuItem
    onClick?: () => void
}

const MenuItem: React.FC<MenuItemProps> = ({ item, onClick }) => {
    const [open, setOpen] = useState<boolean>(true)
    const handleMenuClick = () => {
        if (item.submenus && item.submenus.length > 0) {
            setOpen(!open)
        }
        onClick?.()
    }

    return (
        <>
            <Box
                display="flex"
                mx={1.75}
                px={1.25}
                height="52px"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                    borderRadius: 2,
                    color: 'rgba(235, 235, 245, 0.6)',
                    '&:hover': {
                        color: 'white',
                    },
                }}
                activeStyle={{ backgroundColor: 'rgba(235, 235, 245, 0.05)', color: 'white' }}
                component={item.path ? NavLink : Box}
                to={item.path || ''}
                onClick={() => handleMenuClick()}
            >
                <Box display="flex" alignItems="center">
                    {item.icon}
                    <Typography variant="body1" sx={{ ml: item.icon ? 2 : 5 }}>
                        {item.title}
                    </Typography>
                </Box>

                {item.submenus && item.submenus.length > 0 && <>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</>}
            </Box>

            {item.submenus && item.submenus.length > 0 && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {item.submenus?.map((item, index) => (
                        <MenuItem key={index} item={item} />
                    ))}
                </Collapse>
            )}
        </>
    )
}

export default MenuItem
