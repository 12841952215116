import React from 'react'

type PageBlockProps = {
    loaded: boolean
    error?: string
    loader: React.ReactNode
    content: React.ReactNode
}
function ContentLoader({ loaded, error, loader, content }: PageBlockProps) {
    return (
        <>
            {!loaded && loader}
            {loaded && error && <>{error}</>}
            {loaded && !error && content}
        </>
    )
}

export default ContentLoader
