import { LoadingButton } from '@mui/lab'
import { Box, Button, Typography } from '@mui/material'
import ConfirmationWindow from 'app/components/ConfirmationWindow'
import Input from 'app/components/Input'
import { useToast, useToggle } from 'hooks'
import React, { useCallback, useRef, useState } from 'react'
import apiService from 'services/api-service'

type Values = {
    id?: string
    name?: string
    iconUrl?: string
    parentCategoryId?: string
}

type WorkCategoryFormProps = {
    initialValues?: Values
    onDone?: () => void
}

const useWorkCategoryForm = (initialValues?: Values, onDone?: () => void) => {
    const isNew = useRef(!initialValues?.id)
    const isSubcategory = Boolean(initialValues?.parentCategoryId)
    const [values, setValues] = useState(initialValues ?? {})
    const [iconFileName, setIconFileName] = useState<string>()
    const [submitting, setSubmitting] = useState(false)
    const toast = useToast()

    const showRemoveButton = !isNew.current
    const {
        opened: isVisibleRemoveConfirmation,
        open: showRemoveConfirmation,
        close: closeRemoveConfirmation,
    } = useToggle()
    const [removing, setRemoving] = useState(false)

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        setValues((values) => ({ ...values, [name]: value }))
    }, [])

    const handleLoadIcon = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            const { files } = event.target
            const file = files ? files[0] : null
            if (!file) {
                return
            }

            try {
                const { url, fileName } = await apiService.uploadWorkCategoryIcon(file)
                setValues((values) => ({ ...values, iconUrl: url }))
                setIconFileName(fileName)
            } catch (error: any) {
                toast.showError(error.message)
            }
        },
        [toast]
    )

    const handleSubmit = useCallback(async () => {
        setSubmitting(true)

        try {
            if (isNew.current) {
                await apiService.createWorkCategory({
                    name: values.name ?? '',
                    iconFileName: iconFileName ?? '-',
                    parentId: values.parentCategoryId,
                })
            } else {
                await apiService.changeWorkCategory(values.id ?? '-', {
                    name: values.name,
                    iconFileName: iconFileName,
                })
            }

            onDone?.()
        } catch (error: any) {
            toast.showError(error.message)
        } finally {
            setSubmitting(false)
        }
    }, [values, iconFileName, onDone, toast])

    const handleRemove = useCallback(async () => {
        const categoryId = initialValues?.id
        if (!categoryId) {
            return
        }

        try {
            setRemoving(true)
            await apiService.removeWorkCategory(categoryId)
            closeRemoveConfirmation()
        } catch (error: any) {
            toast.showError(error.message)
        } finally {
            setRemoving(false)
            onDone?.()
        }
    }, [toast, initialValues?.id, onDone, closeRemoveConfirmation])

    return {
        isNew: isNew.current,
        isSubcategory,
        values,
        handleChange,
        handleLoadIcon,
        handleSubmit,
        handleRemove,
        isVisibleRemoveConfirmation,
        showRemoveConfirmation,
        closeRemoveConfirmation,
        removing,
        submitting,
        showRemoveButton,
    }
}

function WorkCategoryForm({ initialValues, onDone }: WorkCategoryFormProps) {
    const {
        isNew,
        isSubcategory,
        values,
        handleChange,
        handleLoadIcon,
        handleSubmit,
        handleRemove,
        isVisibleRemoveConfirmation,
        showRemoveConfirmation,
        closeRemoveConfirmation,
        removing,
        submitting,
        showRemoveButton,
    } = useWorkCategoryForm(initialValues, onDone)

    const title = isNew
        ? `Создание ${!isSubcategory ? 'категории' : 'подкатегории'}`
        : `Изменение ${!isSubcategory ? 'категории' : 'подкатегории'}`
    const submitButtonText = isNew ? 'Создать' : 'Сохранить'
    const confirmationToRemoveTitle = `Вы уверены, что хотите удалить ${isSubcategory ? 'подкатегорию' : 'категорию'}?`

    const ref = useRef<HTMLInputElement>(null)
    const handleChangeIcon = useCallback(() => ref?.current?.click?.(), [ref])

    return (
        <Box>
            <Typography variant="h4" fontWeight={700}>
                {title}
            </Typography>
            <Box mt={3}>
                <Input
                    label="Название"
                    placeholder="Мастер на час"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    cleanable
                />
                <Box mt={3} display="flex" alignItems="center">
                    {values.iconUrl && (
                        <Box mr={2} maxWidth="64px" maxHeight="64px" component="img" src={values.iconUrl} />
                    )}
                    <Button size="medium" variant="text" onClick={handleChangeIcon}>
                        {values.iconUrl ? 'Изменить иконку' : 'Загрузить иконку'}
                    </Button>
                    <input
                        ref={ref}
                        accept="image/*"
                        id="icon-button-photo"
                        hidden
                        onChange={handleLoadIcon}
                        type="file"
                    />
                </Box>
                <Box display="flex" alignItems="center" mt={3}>
                    <LoadingButton
                        loading={submitting}
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        {submitButtonText}
                    </LoadingButton>
                    {showRemoveButton && (
                        <Button
                            size="large"
                            variant="text"
                            color="error"
                            sx={{ ml: 2 }}
                            onClick={showRemoveConfirmation}
                        >
                            Удалить
                        </Button>
                    )}
                </Box>
            </Box>
            <ConfirmationWindow
                title={confirmationToRemoveTitle}
                opened={isVisibleRemoveConfirmation}
                onClose={closeRemoveConfirmation}
                onConfirm={handleRemove}
                confirming={removing}
            />
        </Box>
    )
}

export default WorkCategoryForm
