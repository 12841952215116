export const masks = [
    '+247-####',
    '+376-###-###',
    '+971-5#-###-####',
    '+971-#-###-####',
    '+93-##-###-####',
    '+1(268)###-####',
    '+1(264)###-####',
    '+355(###)###-###',
    '+374-##-###-###',
    '+599-###-####',
    '+599-###-####',
    '+599-9###-####',
    '+244(###)###-###',
    '+672-1##-###',
    '+54(###)###-####',
    '+1(684)###-####',
    '+43(###)###-####',
    '+61-#-####-####',
    '+297-###-####',
    '+994-##-###-##-##',
    '+387-##-#####',
    '+387-##-####',
    '+1(246)###-####',
    '+880-##-###-###',
    '+32(###)###-###',
    '+226-##-##-####',
    '+359(###)###-###',
    '+973-####-####',
    '+257-##-##-####',
    '+229-##-##-####',
    '+1(441)###-####',
    '+673-###-####',
    '+591-#-###-####',
    '+55(##)####-####',
    '+55(##)7###-####',
    '+55(##)9####-####',
    '+1(242)###-####',
    '+975-17-###-###',
    '+975-#-###-###',
    '+267-##-###-###',
    '+375(##)###-##-##',
    '+501-###-####',
    '+243(###)###-###',
    '+236-##-##-####',
    '+242-##-###-####',
    '+41-##-###-####',
    '+225-##-###-###',
    '+682-##-###',
    '+56-#-####-####',
    '+237-####-####',
    '+86(###)####-####',
    '+86(###)####-###',
    '+86-##-#####-#####',
    '+57(###)###-####',
    '+506-####-####',
    '+53-#-###-####',
    '+238(###)##-##',
    '+599-###-####',
    '+357-##-###-###',
    '+420(###)###-###',
    '+49(####)###-####',
    '+49(###)###-####',
    '+49(###)##-####',
    '+49(###)##-###',
    '+49(###)##-##',
    '+49-###-###',
    '+253-##-##-##-##',
    '+45-##-##-##-##',
    '+1(767)###-####',
    '+1(809)###-####',
    '+1(829)###-####',
    '+1(849)###-####',
    '+213-##-###-####',
    '+593-##-###-####',
    '+593-#-###-####',
    '+372-####-####',
    '+372-###-####',
    '+20(###)###-####',
    '+291-#-###-###',
    '+34(###)###-###',
    '+251-##-###-####',
    '+358(###)###-##-##',
    '+679-##-#####',
    '+500-#####',
    '+691-###-####',
    '+298-###-###',
    '+262-#####-####',
    '+33(###)###-###',
    '+508-##-####',
    '+590(###)###-###',
    '+241-#-##-##-##',
    '+1(473)###-####',
    '+995(###)###-###',
    '+594-#####-####',
    '+233(###)###-###',
    '+350-###-#####',
    '+299-##-##-##',
    '+220(###)##-##',
    '+224-##-###-###',
    '+240-##-###-####',
    '+30(###)###-####',
    '+502-#-###-####',
    '+1(671)###-####',
    '+245-#-######',
    '+592-###-####',
    '+852-####-####',
    '+504-####-####',
    '+385-##-###-###',
    '+509-##-##-####',
    '+36(###)###-###',
    '+62(8##)###-####',
    '+62-##-###-##',
    '+62-##-###-###',
    '+62-##-###-####',
    '+62(8##)###-###',
    '+62(8##)###-##-###',
    '+353(###)###-###',
    '+972-5#-###-####',
    '+972-#-###-####',
    '+91(####)###-###',
    '+246-###-####',
    '+964(###)###-####',
    '+98(###)###-####',
    '+354-###-####',
    '+39(###)####-###',
    '+1(876)###-####',
    '+962-#-####-####',
    '+81-##-####-####',
    '+81(###)###-###',
    '+254-###-######',
    '+996(###)###-###',
    '+855-##-###-###',
    '+686-##-###',
    '+269-##-#####',
    '+1(869)###-####',
    '+850-191-###-####',
    '+850-##-###-###',
    '+850-###-####-###',
    '+850-###-###',
    '+850-####-####',
    '+850-####-#############',
    '+82-##-###-####',
    '+965-####-####',
    '+1(345)###-####',
    '+7 6## ###-##-##',
    '+7 7## ###-##-##',
    '+856(20##)###-###',
    '+856-##-###-###',
    '+961-##-###-###',
    '+961-#-###-###',
    '+1(758)###-####',
    '+423(###)###-####',
    '+94-##-###-####',
    '+231-##-###-###',
    '+266-#-###-####',
    '+370(###)##-###',
    '+352(###)###-###',
    '+371-##-###-###',
    '+218-##-###-###',
    '+218-21-###-####',
    '+212-##-####-###',
    '+377(###)###-###',
    '+377-##-###-###',
    '+373-####-####',
    '+382-##-###-###',
    '+261-##-##-#####',
    '+692-###-####',
    '+389-##-###-###',
    '+223-##-##-####',
    '+95-##-###-###',
    '+95-#-###-###',
    '+95-###-###',
    '+976-##-##-####',
    '+853-####-####',
    '+1(670)###-####',
    '+596(###)##-##-##',
    '+222-##-##-####',
    '+1(664)###-####',
    '+356-####-####',
    '+230-###-####',
    '+960-###-####',
    '+265-1-###-###',
    '+265-#-####-####',
    '+52(###)###-####',
    '+52-##-##-####',
    '+60-##-###-####',
    '+60(###)###-###',
    '+60-##-###-###',
    '+60-#-###-###',
    '+258-##-###-###',
    '+264-##-###-####',
    '+687-##-####',
    '+227-##-##-####',
    '+672-3##-###',
    '+234(###)###-####',
    '+234-##-###-###',
    '+234-##-###-##',
    '+234(###)###-####',
    '+505-####-####',
    '+31-##-###-####',
    '+47(###)##-###',
    '+977-##-###-###',
    '+674-###-####',
    '+683-####',
    '+64(###)###-###',
    '+64-##-###-###',
    '+64(###)###-####',
    '+968-##-###-###',
    '+507-###-####',
    '+51(###)###-###',
    '+689-##-##-##',
    '+675(###)##-###',
    '+63(###)###-####',
    '+92(###)###-####',
    '+48(###)###-###',
    '+970-##-###-####',
    '+351-##-###-####',
    '+680-###-####',
    '+595(###)###-###',
    '+974-####-####',
    '+262-#####-####',
    '+40-##-###-####',
    '+381-##-###-####',
    '+7 ### ###-##-##',
    '+250(###)###-###',
    '+966-5-####-####',
    '+966-#-###-####',
    '+677-###-####',
    '+677-#####',
    '+248-#-###-###',
    '+249-##-###-####',
    '+46-##-###-####',
    '+65-####-####',
    '+290-####',
    '+290-####',
    '+386-##-###-###',
    '+421(###)###-###',
    '+232-##-######',
    '+378-####-######',
    '+221-##-###-####',
    '+252-##-###-###',
    '+252-#-###-###',
    '+252-#-###-###',
    '+597-###-####',
    '+597-###-###',
    '+211-##-###-####',
    '+239-##-#####',
    '+503-##-##-####',
    '+1(721)###-####',
    '+963-##-####-###',
    '+268-##-##-####',
    '+1(649)###-####',
    '+235-##-##-##-##',
    '+228-##-###-###',
    '+66-##-###-####',
    '+66-##-###-###',
    '+992-##-###-####',
    '+690-####',
    '+670-###-####',
    '+670-77#-#####',
    '+670-78#-#####',
    '+993-#-###-####',
    '+216-##-###-###',
    '+676-#####',
    '+90(###)###-####',
    '+1(868)###-####',
    '+688-90####',
    '+688-2####',
    '+886-#-####-####',
    '+886-####-####',
    '+255-##-###-####',
    '+380(##)###-##-##',
    '+256(###)###-###',
    '+44-##-####-####',
    '+598-#-###-##-##',
    '+998-##-###-####',
    '+39-6-698-#####',
    '+1(784)###-####',
    '+58(###)###-####',
    '+1(284)###-####',
    '+1(340)###-####',
    '+84-##-####-###',
    '+84(###)####-###',
    '+678-##-#####',
    '+678-#####',
    '+681-##-####',
    '+685-##-####',
    '+967-###-###-###',
    '+967-#-###-###',
    '+967-##-###-###',
    '+27-##-###-####',
    '+260-##-###-####',
    '+263-#-######',
    '+1(###)###-####',
]
