import React, { useMemo } from 'react'
import InputMask from 'react-input-mask'

import Input, { InputProps } from '../Input'
import { getListOfMasks } from './get-list-of-masks'
import { getMatchedMask } from './get-matched-mask'

type PhoneInput = InputProps

function PhoneInput({ value, onChange, ...restProps }: PhoneInput) {
    const masks = useMemo(() => getListOfMasks(), [])
    const matchedMask = useMemo(() => getMatchedMask(value ?? '', masks) || '+999999999999999', [masks, value])

    return (
        <InputMask mask={matchedMask} maskPlaceholder="" value={value} onChange={onChange}>
            <Input {...restProps} placeholder="+7 999 000-00-00" type="tel" />
        </InputMask>
    )
}

export default PhoneInput
