import { Link } from '@mui/material'
import { useUrlQuery } from 'hooks'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import React from 'react'
import { NavLink as NavLinkRR } from 'react-router-dom'
import { url } from 'utils'

type NavLinkProps = {
    href: string
    children?: React.ReactNode
    activeByQuery?: boolean
}

// TODO: не доделаны стили
function NavLink({ href, children, activeByQuery }: NavLinkProps) {
    const { query } = useUrlQuery()
    return (
        <Link
            to={href}
            component={NavLinkRR}
            underline="none"
            color="gray"
            variant="h6"
            activeStyle={{ color: 'black' }}
            isActive={(match) => {
                if (!match || typeof href !== 'string') {
                    return false
                }

                const [path] = href.split('?')
                const search = url.parseQuery(href)
                let isActiveLink = match.url === path

                if (activeByQuery) {
                    isActiveLink = isActiveLink && isEqual(search, pick(query, Object.keys(search)))
                }

                return isActiveLink
            }}
        >
            {children}
        </Link>
    )
}

export default NavLink
