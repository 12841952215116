import { alpha, Chip } from '@mui/material'
import { ORDER_STATE_COLORS, ORDERS_STATE_NAMES, OrderState } from 'app/constants'
import React from 'react'

type OrderStatusProps = {
    status: OrderState
}

function OrderStatus({ status }: OrderStatusProps) {
    return (
        <Chip
            label={ORDERS_STATE_NAMES[status]}
            variant="outlined"
            sx={() => {
                const color = ORDER_STATE_COLORS[status]
                return {
                    padding: '7px 8px 7px 10px',
                    color: color,
                    borderColor: color,
                    background: alpha(color, 0.12),
                    borderRadius: '8px',
                    svg: {
                        fill: color,
                    },
                }
            }}
        />
    )
}

export default OrderStatus
