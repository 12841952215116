import GoogleMapReact from 'google-map-react'
import React from 'react'

type GoogleMapProps = {
    center: {
        lat: number
        lon: number
    }
    children?: React.ReactNode
    zoom?: number
}

function GoogleMap({ center, children, zoom = 10 }: GoogleMapProps) {
    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string }}
            defaultCenter={{ lat: center.lat, lng: center.lon }}
            defaultZoom={zoom}
        >
            {children}
        </GoogleMapReact>
    )
}

export default GoogleMap
