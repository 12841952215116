import { Box, Typography } from '@mui/material'
import ReasonPicker from 'app/components/ReasonPicker'
import Switch from 'app/components/Switch'
import { useCustomer } from 'app/modules/Customer'
import React, { useCallback, useState } from 'react'

import useCustomerBlockingReasons from './useCustomerBlockingReasons'

function BlockingToggler() {
    const { customer, block, unblock, blockingLoading } = useCustomer()
    const [showReasons, setShowReasons] = useState(false)
    const { reasons, loaded: reasonsLoaded } = useCustomerBlockingReasons()
    const blocked = Boolean(customer?.blocking)

    const onToggle = useCallback(async () => {
        if (blocked) {
            await unblock()
        } else {
            setShowReasons(true)
        }
    }, [setShowReasons, unblock, blocked])

    const handleSelectReason = useCallback(
        async (reason: { type: number; comment?: string }) => {
            await block(reason)
            setShowReasons(false)
        },
        [block, setShowReasons]
    )

    return (
        <Box display="flex">
            <Typography
                mr={1}
                textAlign="center"
                whiteSpace="pre"
                lineHeight="1.2"
                variant="subtitle2"
                color="grey.600"
            >
                {blocked ? 'Нет доступа к\nразмещению заказов' : 'Есть доступ к\nразмещению заказов'}
            </Typography>
            <Switch checked={!blocked} disabled={blockingLoading} onToggle={onToggle} />
            {!blocked && showReasons && (
                <ReasonPicker
                    title="Укажите причину блокировки"
                    buttonText="Заблокировать"
                    reasons={reasons}
                    reasonsLoaded={reasonsLoaded}
                    buttonLoading={blockingLoading}
                    onSelect={handleSelectReason}
                    onClose={() => setShowReasons(false)}
                />
            )}
        </Box>
    )
}

export default BlockingToggler
