import { Box, Typography } from '@mui/material'
import GoogleMap, { Marker } from 'app/components/GoogleMap'
import React from 'react'

type OrderLocationProps = {
    location: {
        address: string
        lat: number
        lon: number
    }
}

function OrderLocation({ location }: OrderLocationProps) {
    return (
        <>
            <Typography variant="subtitle1">{location.address}</Typography>
            <Box
                mt="16px"
                sx={() => ({
                    height: '503px',
                    borderRadius: '20px',
                    background: '#ccc',
                    overflow: 'hidden',
                })}
            >
                <GoogleMap center={{ lat: location.lat, lon: location.lon }} zoom={13}>
                    <Marker text={location.address} lat={location.lat} lng={location.lon} />
                </GoogleMap>
            </Box>
        </>
    )
}

export default OrderLocation
