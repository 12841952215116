import { Typography } from '@mui/material'
import Chat from 'app/components/Chat'
import React, { useMemo } from 'react'
import { names } from 'utils'

type OrderResponseChatProps = {
    channel: string
    orderSubcategoryName?: string
    customer: {
        id: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
    }
    worker: {
        id: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
    }
}

function OrderResponseChat({ channel, orderSubcategoryName, worker, customer }: OrderResponseChatProps) {
    const workerName = names.getFullName({ firstName: worker.firstName, lastName: worker.lastName })
    const customerName = names.getFullName({ firstName: customer.firstName, lastName: customer.lastName })

    const title = (
        <>
            <Typography fontSize={24}>Чат</Typography>
            {orderSubcategoryName && <Typography fontSize={16}>{`Заказ: ${orderSubcategoryName}`}</Typography>}
            {workerName && <Typography fontSize={16}>{`Мастер: ${workerName}`}</Typography>}
            {customerName && <Typography fontSize={16}>{`Клиент: ${customerName}`}</Typography>}
        </>
    )

    const users = useMemo(
        () => [
            {
                id: worker.id,
                name: 'Мастер',
                avatarUrl: worker.avatarUrl,
            },
            {
                id: customer.id,
                name: 'Клиент',
                avatarUrl: customer.avatarUrl,
            },
        ],
        [worker, customer]
    )

    return <Chat title={title} channel={channel} users={users} />
}

export default OrderResponseChat
