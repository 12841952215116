import { useCities, useUser } from 'hooks'
import React, { useMemo } from 'react'

import Autocomplete from '../Autocomplete'

type CityFilterProps = {
    cityId?: string
    onChange: (cityId?: string) => void
    label?: string
    placeholder?: string
}
function CityFilter({ cityId, onChange, label = 'Город', placeholder = 'Все города' }: CityFilterProps) {
    const { cities, cityById } = useCities()
    const { user, permissions } = useUser()

    const cityOptions = useMemo(() => {
        if (!user) {
            return []
        }

        if (permissions.canManageAllCities) {
            return cities.map((city) => ({ value: city.id, title: city.name }))
        }

        if (permissions.canManageOwnCities) {
            return user.cityIds
                .map((cityId) => cityById[cityId])
                .filter(Boolean)
                .map((city) => ({ value: city.id, title: city.name }))
        }

        return []
    }, [user, permissions, cities, cityById])

    if (cityOptions.length <= 1) {
        return null
    }

    return (
        <Autocomplete
            value={cityId}
            onChange={onChange}
            label={label}
            placeholder={placeholder}
            options={cityOptions}
        />
    )
}

export default CityFilter
