import { LoadingButton } from '@mui/lab'
import { Box, Grid } from '@mui/material'
import Input from 'app/components/Input'
import { GreenLogo } from 'app/components/Logo/GreenLogo'
import React from 'react'

import useLogin from './useLogin'

type LoginFormProps = {
    onLogin: () => void
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin }) => {
    const { values, errors, changeValues, submit, submitting } = useLogin({ onLogin })

    return (
        <Box
            sx={{
                display: 'flex',
                position: 'fixed',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    bgcolor: 'white',
                    p: 8.75,
                    width: '480px',
                    borderRadius: 4,
                    boxShadow: '0 1px 10px rgb(0 0 0 / 10%)',
                }}
            >
                <Box
                    noValidate
                    component="form"
                    onSubmit={(e: React.FormEvent) => {
                        e.preventDefault()
                        submit()
                    }}
                >
                    <Grid container rowSpacing={3} direction="column">
                        <Grid item margin="0 auto 30px auto" p={0}>
                            <GreenLogo />
                        </Grid>
                        <Grid item>
                            <Input
                                label="Email"
                                name="email"
                                placeholder="i.ivanov@dbz.ru"
                                value={values.email}
                                error={!!errors.email}
                                onChange={changeValues}
                            />
                        </Grid>
                        <Grid item>
                            <Input
                                name="password"
                                label="Пароль"
                                type="password"
                                placeholder="********"
                                value={values.password}
                                error={!!errors.password}
                                onChange={changeValues}
                            />
                        </Grid>
                        <Grid item mt={2}>
                            <LoadingButton
                                loading={submitting}
                                fullWidth
                                size="large"
                                color="primary"
                                variant="contained"
                                onClick={() => submit()}
                            >
                                Войти
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}

export default LoginForm
