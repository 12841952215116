import React from 'react'

function ChatIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14 19C17.771 19 19.657 19 20.828 17.828C22 16.657 22 14.771 22 11C22 7.229 22 5.343 20.828 4.172C19.657 3 17.771 3 14 3H10C6.229 3 4.343 3 3.172 4.172C2 5.343 2 7.229 2 11C2 14.771 2 16.657 3.172 17.828C3.825 18.482 4.7 18.771 6 18.898"
                stroke="#4BB56F"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.0012 11V11.01M8.00122 11V11.01M16.0012 11V11.01M14.0012 19C12.7652 19 11.4032 19.5 10.1602 20.145C8.16222 21.182 7.16322 21.701 6.67122 21.37C6.17922 21.04 6.27222 20.015 6.45922 17.966L6.50122 17.5"
                stroke="#4BB56F"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    )
}

export default ChatIcon
