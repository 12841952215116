import { Logout as LogoutIcon } from '@mui/icons-material'
import { Box, Divider, Paper } from '@mui/material'
import ConfirmationWindow from 'app/components/ConfirmationWindow'
import { WhiteLogo } from 'app/components/Logo/WhiteLogo'
import { ReactComponent as CatalogSVG } from 'assets/icons/Catalog.svg'
import { ReactComponent as FinanceSVG } from 'assets/icons/Finance.svg'
import { ReactComponent as OrdersSVG } from 'assets/icons/Orders.svg'
import { ReactComponent as UsersSVG } from 'assets/icons/Users.svg'
import { useToggle, useUser } from 'hooks'
import React from 'react'

import MenuItem from './MenuItem'
import { TMenuItem } from './types'

const menuItems: TMenuItem[] = [
    {
        icon: <OrdersSVG />,
        title: 'Заказы',
        path: '/orders',
    },
    {
        icon: <UsersSVG />,
        title: 'Клиенты',
        submenus: [
            {
                title: 'Активные',
                path: '/customers',
            },
            {
                title: 'Заблокированные',
                path: '/blocked-customers',
            },
        ],
    },
    {
        icon: <UsersSVG />,
        title: 'Мастера',
        submenus: [
            {
                title: 'Активные',
                path: '/workers',
            },
            {
                title: 'Заблокированные',
                path: '/blocked-workers',
            },
        ],
    },
    {
        icon: <UsersSVG />,
        title: 'Сотрудники',
        path: '/employees',
    },
    {
        icon: <CatalogSVG />,
        title: 'Каталог',
        path: '/catalog',
    },
    {
        icon: <FinanceSVG />,
        title: 'Финансы',
        path: '/finance',
    },
]

const Sidebar: React.FC = () => {
    const { logout } = useUser()
    const { opened, open, close } = useToggle(false)

    return (
        <Paper
            sx={{
                width: '280px',
                minWidth: '280px',
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                bgcolor: '#22272A',
            }}
            elevation={0}
            square={true}
        >
            <Box>
                <WhiteLogo />
                <Divider sx={{ m: 0, bgcolor: 'white', opacity: 0.05 }} />
                <Box
                    sx={{
                        mt: 2.5,
                        overflow: 'auto',
                    }}
                >
                    {menuItems.map((item, index) => (
                        <MenuItem key={index} item={item} />
                    ))}
                </Box>
            </Box>

            <Box sx={{ flex: 1, marginY: 2 }} />

            <Box>
                <MenuItem
                    item={{
                        icon: <LogoutIcon />,
                        title: 'Выйти',
                    }}
                    onClick={open}
                />

                <ConfirmationWindow
                    title="Вы уверены, что хотите выйти?"
                    opened={opened}
                    onClose={close}
                    onConfirm={logout}
                />
            </Box>

            <Box sx={{ fontSize: 14, color: 'rgba(235, 235, 245, 0.6)', mt: 1, ml: 3, pb: 1 }}>
                {`Версия: ${process.env.REACT_APP_VERSION}`}
            </Box>
        </Paper>
    )
}

export default Sidebar
