import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import * as React from 'react'

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    () => ({
        '&:last-child': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    })
)

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(() => ({
    padding: 0,
    '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(270deg)',
    },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
}))

export interface AccordionItem {
    title: string | JSX.Element
    data: string | JSX.Element
    count?: number
}

export const BasicAccordion: React.FC<AccordionItem> = ({ title, data, count }: AccordionItem) => {
    const [expanded, setExpanded] = React.useState<boolean>(false)

    const handleChange = () => {
        setExpanded((prevState) => !prevState)
    }

    return (
        <Accordion expanded={expanded} onChange={handleChange}>
            <AccordionSummary
                style={{
                    borderBottom: !expanded ? '1px solid rgba(0, 0, 0, .125)' : 'none',
                }}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography variant="body1" fontWeight={400}>
                    {title}
                </Typography>
                {!!count && (
                    <Typography fontWeight={400} color="grey.400" ml={1}>
                        {count.toLocaleString()}
                    </Typography>
                )}
            </AccordionSummary>
            <AccordionDetails>{data}</AccordionDetails>
        </Accordion>
    )
}
