import { useUrlQuery } from 'hooks'
import { useCallback, useMemo } from 'react'

type Filter = {
    cityId?: string
}

type Query = {
    cityId?: string
}

function useFilter() {
    const { query, changeQuery } = useUrlQuery<Query>()

    const currentFilter: Filter = useMemo(() => ({ ...query }), [query])

    const changeFilter = useCallback(
        (newFilter: Filter) => {
            const nextFilter = { ...currentFilter, ...newFilter }
            const nextQuery = { ...nextFilter }
            changeQuery(nextQuery)
        },
        [currentFilter, changeQuery]
    )

    const resetFilter = useCallback(() => changeQuery({}), [changeQuery])

    return {
        filter: currentFilter,
        changeFilter,
        resetFilter,
    }
}

export default useFilter
