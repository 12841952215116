import { useMemo } from 'react'
import apiService from 'services/api-service'
import useImmutableSWR from 'swr/immutable'

import { WorkCategory } from './types'

const useWorkCategories = () => {
    const { data, error, mutate: update } = useImmutableSWR('categories', apiService.getWorkCategories)

    const categories: WorkCategory[] = useMemo(
        () =>
            (data ?? []).map((category) => ({
                id: category.id,
                name: category.name,
                iconUrl: category.iconUrl,
                position: category.position,
                subcategories: category.subcategories.map((subcategory) => ({
                    id: subcategory.id,
                    name: subcategory.name,
                    iconUrl: subcategory.iconUrl,
                    position: subcategory.position,
                    parentCategoryId: subcategory.parentCategoryId,
                    works: subcategory.works.map((work) => ({
                        id: work.id,
                        name: work.name,
                        iconUrl: subcategory.iconUrl,
                        avgPrice: work.avgPrice,
                        priceUnit: work.priceUnit,
                        position: work.position,
                        keywords: work.keywords,
                        subcategoryId: work.subcategoryId,
                        online: work.online,
                    })),
                })),
            })),
        [data]
    )

    return {
        categories,
        update,
        loaded: Boolean(data) || Boolean(error),
        error,
    }
}

export default useWorkCategories
