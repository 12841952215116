import { Link as MaterialLink } from '@mui/material'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

type LinkProps = {
    href: string
    children: React.ReactNode
    underline: 'none' | 'hover' | 'always'
}

function Link({ href, children, underline }: LinkProps) {
    return (
        <MaterialLink component={RouterLink} to={href} underline={underline}>
            {children}
        </MaterialLink>
    )
}

export default Link
