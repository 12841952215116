import { useCallback, useState } from 'react'

function useToggle(initialState: boolean | (() => boolean) = false) {
    const [opened, setOpened] = useState(initialState)

    const open = useCallback(() => setOpened(true), [setOpened])
    const close = useCallback(() => setOpened(false), [setOpened])
    const toggle = useCallback(() => setOpened((opened) => !opened), [setOpened])

    return {
        opened,
        open,
        close,
        toggle,
    }
}

export default useToggle
