import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, IconButton } from '@mui/material'
import Profile from 'app/components/Profile'
import { useRouter } from 'hooks'
import React from 'react'

type OrderWorkerProps = {
    worker?: {
        id: string
        phone: string
        avatarUrl?: string | null
        firstname?: string
        lastname?: string
    }
}

function OrderWorker({ worker }: OrderWorkerProps) {
    const router = useRouter()

    if (!worker) {
        return <>Мастер ещё не назначен</>
    }

    return (
        <Box display="flex" alignItems="center">
            <Profile
                phone={worker.phone}
                photoUrl={worker.avatarUrl}
                firstName={worker.firstname}
                lastName={worker.lastname}
            />
            <IconButton sx={{ ml: 1 }} onClick={() => router.goToWorkerPage(worker.id)}>
                <ChevronRightIcon />
            </IconButton>
        </Box>
    )
}

export default OrderWorker
