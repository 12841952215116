import { useMemo } from 'react'
import apiService, { GetOrderViewsDetailsResponse } from 'services/api-service'
import useSWR from 'swr'

import useOrder from '../useOrder'

export type OrderViewsItem = GetOrderViewsDetailsResponse['items'][number] & {
    customer: {
        id: string
        firstName: string | null
        lastName: string | null
        avatarUrl: string | null
    }
}

const useOrderWorkerViewsDetails = () => {
    const { order } = useOrder()
    if (!order) {
        throw Error()
    }

    const { data, error } = useSWR(['order-worker-views', order.id], () =>
        apiService.getOrderWorkerViewsDetails({ orderId: order.id })
    )

    const workerViews = useMemo<OrderViewsItem[]>(
        () =>
            (data?.items ?? []).map((item) => ({
                ...item,
                customer: {
                    id: order.customer.id,
                    firstName: order.customer.firstname,
                    lastName: order.customer.lastname,
                    avatarUrl: order.customer.avatarUrl ?? null,
                },
            })),
        [data, order]
    )

    return {
        workerViews: workerViews,
        error: error?.message,
        loaded: Boolean(data) || Boolean(error),
    }
}

export default useOrderWorkerViewsDetails
