import { Box } from '@mui/material'
import Modal from 'app/components/Modal'
import Profile from 'app/components/Profile'
import Table from 'app/components/Table'
import { OrderResponseChat } from 'app/modules/OrderResponses'
import { useRouter } from 'hooks'
import React, { useCallback } from 'react'
import { TTableRowData } from 'types/ITable'
import { dateHelper } from 'utils'

import useOrderWorkerViewsDetails, { OrderViewsItem } from './useOrderWorkerViewsDetails'

const columns: TTableRowData<OrderViewsItem>[] = [
    {
        name: 'worker',
        title: 'Мастер',
        xs: 4,
        element: ({ worker }) => (
            <Profile photoUrl={worker.avatarUrl} firstName={worker.firstName} lastName={worker.lastName} />
        ),
    },
    {
        name: 'viewedAt',
        title: 'Дата просмотра',
        xs: 3.5,
        element: (data) => dateHelper.formatDate(data.viewedAt),
    },
    {
        name: 'respondedAt',
        title: 'Дата отклика',
        xs: 3.5,
        element: (data) => (data.respondedAt ? dateHelper.formatDate(data.respondedAt) : '–'),
    },
    {
        name: 'chat',
        title: 'Чат',
        xs: 1,
        alignTitle: 'center',
        element: (data) =>
            data.chatId ? (
                <Box display="flex" justifyContent="center" width="100%" onClick={(e) => e.stopPropagation()}>
                    <OrderResponseChat
                        channel={data.chatId}
                        customer={{
                            id: data.customer.id,
                            firstName: data.customer.firstName,
                            lastName: data.customer.lastName,
                            avatarUrl: data.customer.avatarUrl,
                        }}
                        worker={{
                            id: data.worker.id,
                            firstName: data.worker.firstName,
                            lastName: data.worker.lastName,
                            avatarUrl: data.worker.avatarUrl,
                        }}
                    />
                </Box>
            ) : (
                '–'
            ),
    },
]

type OrderWorkerViewsDetailsModalProps = {
    onClose: () => void
}

function OrderWorkerViewsDetailsModal({ onClose }: OrderWorkerViewsDetailsModalProps) {
    const { workerViews, loaded } = useOrderWorkerViewsDetails()

    const router = useRouter()
    const handleClick = useCallback(
        (item: OrderViewsItem) => router.goToWorkerPage(item.worker.id, { openNewTab: true }),
        [router]
    )

    return (
        <Modal title="Мастера, просмотревшие заказ" opened onClose={onClose}>
            <Box width={600}>
                <Table
                    px={0}
                    rowPx={0}
                    isLoading={!loaded}
                    items={workerViews}
                    rows={columns}
                    handleClickRow={handleClick}
                />
            </Box>
        </Modal>
    )
}

export default OrderWorkerViewsDetailsModal
