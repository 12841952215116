import { Box, Typography } from '@mui/material'
import { MoreButton, PrimaryButton } from 'app/components/Button'
import ConfirmationWindow from 'app/components/ConfirmationWindow'
import ContentLoader from 'app/components/ContentLoader'
import DottedLines from 'app/components/DottedLines'
import { Skeleton } from 'app/components/Loaders'
import Modal from 'app/components/Modal'
import { TitleBlock } from 'app/components/TitleBlock'
import { useToggle } from 'hooks'
import React, { useCallback } from 'react'
import { dateHelper } from 'utils'

import NewWorkerTariffActivation from './NewWorkerTariffActivation'
import useWorkerTariff from './useWorkerTariff'
import WorkerTariffHistory from './WorkerTariffHistory'

type WorkerTariff = {
    id: string
    typeTitle: string
    availableResponses: number
    totalResponses: number
    price: {
        value: number
        currencySymbol: string
    }
    activatedAt: Date
    expiresAt: Date
    expiredAt?: Date
}

function WorkerTariff() {
    const { tariff, loaded, error, updateTariff, deactivateTariff, deactivating } = useWorkerTariff()
    const { opened: openedTariffsHistory, open: openTariffsHistory, close: closeTariffsHistory } = useToggle()
    const { opened: openedTariffActivation, open: openTariffActivation, close: closeTariffActivation } = useToggle()
    const {
        opened: openedTariffDeactivationConfirmation,
        open: openTariffDeactivationConfirmation,
        close: closeTariffDeactivationConfirmation,
    } = useToggle()

    const handleTaiffActivated = useCallback(async () => {
        await updateTariff()
        closeTariffActivation()
    }, [updateTariff, closeTariffActivation])

    const handleDeactivateTariff = useCallback(async () => {
        await deactivateTariff()
        closeTariffDeactivationConfirmation()
    }, [deactivateTariff, closeTariffDeactivationConfirmation])

    const tariffComponent = (
        <DottedLines
            data={[
                {
                    title: 'Тип тарифа',
                    value: tariff?.typeText ?? '',
                },
                {
                    title: 'Доступные отклики',
                    value: `${tariff?.availableResponsesCount} из ${tariff?.totalResponsesCount}`,
                },
                {
                    title: 'Стоимость',
                    value: `${tariff?.purchasePrice.value} ${tariff?.purchasePrice.currencySymbol}`,
                },
                {
                    title: 'Активирован в',
                    value: dateHelper.formatDate(tariff?.activatedAt ?? ''),
                },
                {
                    title: 'Действует до',
                    value: dateHelper.formatDate(tariff?.expiresAt ?? ''),
                },
            ]}
        />
    )

    const notFoundTariffComponent = (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography variant="body1">Нет активного тарифа</Typography>
            <PrimaryButton
                variant="outlined"
                sx={{
                    mt: 1,
                }}
                onClick={openTariffActivation}
            >
                Активировать новый тариф
            </PrimaryButton>
        </Box>
    )

    return (
        <Box>
            <TitleBlock
                title="Тариф"
                variant="h5"
                px={0}
                mb={2}
                rightElement={
                    <MoreButton
                        options={[
                            { title: 'Активировать новый тариф', onSelect: openTariffActivation },
                            { title: 'Показать историю', onSelect: openTariffsHistory },
                            { title: 'Отключить тариф', onSelect: openTariffDeactivationConfirmation },
                        ]}
                        position="right"
                    />
                }
            />

            <ContentLoader
                loaded={loaded}
                error={error}
                loader={<Skeleton>{tariffComponent}</Skeleton>}
                content={tariff ? tariffComponent : notFoundTariffComponent}
            />

            {openedTariffActivation && (
                <Modal opened title="Активировать тариф" onClose={closeTariffActivation}>
                    <NewWorkerTariffActivation hasActiveTariff={Boolean(tariff)} onActivated={handleTaiffActivated} />
                </Modal>
            )}

            {openedTariffsHistory && (
                <Modal opened title="История тарифов" onClose={closeTariffsHistory}>
                    <WorkerTariffHistory />
                </Modal>
            )}

            <ConfirmationWindow
                opened={openedTariffDeactivationConfirmation}
                onConfirm={handleDeactivateTariff}
                onClose={closeTariffDeactivationConfirmation}
                title="Вы уверены, что хотите отключить тариф?"
                confirming={deactivating}
            />
        </Box>
    )
}

export default WorkerTariff
