import { Box, Grid } from '@mui/material'
import React from 'react'

const DottedLines = ({ data }: { data: { title: string; value: string }[] }) => {
    return (
        <Box>
            {data.map((item, index) => (
                <Grid
                    key={index}
                    container
                    sx={(theme) => ({
                        position: 'relative',
                        borderBottom: `1px dashed ${theme.palette.grey[400]}`,
                        height: '24px',
                        marginBottom: '12px',
                    })}
                >
                    <Grid item sx={{ position: 'absolute', background: '#FFF', top: '1px', left: 0 }}>
                        {item.title}
                    </Grid>
                    <Grid item sx={{ position: 'absolute', background: '#FFF', top: '1px', right: 0 }}>
                        {item.value}
                    </Grid>
                </Grid>
            ))}
        </Box>
    )
}

export default DottedLines
