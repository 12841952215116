import { Box, Typography } from '@mui/material'
import { TitleBlock } from 'app/components/TitleBlock'
import React from 'react'
import { dateHelper } from 'utils'

type WorkerBlockingInfoProps = {
    blocking: {
        reasonTitle: string
        reasonComment?: string | undefined
        blockedAt: string
    }
}

function WorkerBlockingInfo({ blocking }: WorkerBlockingInfoProps) {
    let text = blocking.reasonTitle
    if (blocking.reasonComment) {
        text += `: ${blocking.reasonComment}`
    }

    return (
        <Box>
            <TitleBlock title="Причина блокировки" variant="h5" px={0} mb={2} />
            <Box display="flex" flexDirection="column">
                <Typography variant="subtitle1">{text}</Typography>
                <Typography variant="subtitle1" color="grey.600">
                    {dateHelper.formatDate(blocking.blockedAt)}
                </Typography>
            </Box>
        </Box>
    )
}

export default WorkerBlockingInfo
