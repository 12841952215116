import {
    AddBoxOutlined as AddBoxOutlinedIcon,
    DeleteOutlineRounded as DeleteOutlineRoundedIcon,
} from '@mui/icons-material'
import { Box, Button, CircularProgress, Grid, Paper } from '@mui/material'
import Autocomplete from 'app/components/Autocomplete'
import ConfirmationWindow from 'app/components/ConfirmationWindow'
import Input, { PhoneInput } from 'app/components/Input'
import Select from 'app/components/Select'
import { TitleBlock } from 'app/components/TitleBlock'
import React, { useState } from 'react'

import { Employee } from './types'
import useChangeEmployee from './useChangeEmployee'
import useEmployee from './useEmployee'

type EmployeePageProps = {
    employee: Employee
}

const EmployeePage: React.FC<EmployeePageProps> = ({ employee }) => {
    const [showRemoveEmployeeConfirmation, setShowRemoveEmployeeConfirmation] = useState(false)
    const { values, changeValues, needSelectCity, cityOptions, roleOptions, saveEmployee, removeEmployee } =
        useChangeEmployee(employee.id, employee)

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        changeValues({ [name]: value })
    }

    const handleSelectRole = (role: string) => {
        changeValues({ role })
    }

    const handleSelectCities = (cityIds: string[]) => {
        changeValues({ cityIds })
    }

    return (
        <Paper
            sx={{
                bgcolor: 'white',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                py: 4,
            }}
            elevation={0}
        >
            <TitleBlock title="Сотрудник" />
            <Box pt={4} pl={4} pr={4} maxWidth={600}>
                <Grid container spacing={3} direction="column">
                    <Grid item>
                        <Input
                            label="Имя"
                            placeholder="Иван"
                            name="firstname"
                            value={values.firstname}
                            onChange={onChangeInput}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            label="Фамилия"
                            placeholder="Иванов"
                            name="lastname"
                            value={values.lastname}
                            onChange={onChangeInput}
                        />
                    </Grid>
                    <Grid item>
                        <PhoneInput name="phone" label="Телефон" value={values.phone} onChange={onChangeInput} />
                    </Grid>
                    <Grid item>
                        <Select value={values.role} label="Роль" options={roleOptions} onChange={handleSelectRole} />
                    </Grid>
                    {needSelectCity && (
                        <Grid item>
                            <Autocomplete
                                value={values.cityIds}
                                onChange={handleSelectCities}
                                label="Города"
                                options={cityOptions}
                                multiple
                            />
                        </Grid>
                    )}
                    <Grid item>
                        <Input
                            label="E-mail"
                            placeholder="i.ivanov@dbz.ru"
                            name="email"
                            value={values.email}
                            onChange={onChangeInput}
                            type="email"
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            type="password"
                            name="password"
                            label="Пароль"
                            placeholder="Буквы, цифры, символы"
                            value={values.password}
                            onChange={onChangeInput}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            type="password"
                            label="Пароль ещё раз"
                            placeholder="Буквы, цифры, символы"
                            name="repeatedPassword"
                            value={values.repeatedPassword}
                            onChange={onChangeInput}
                        />
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddBoxOutlinedIcon />}
                        onClick={saveEmployee}
                    >
                        Сохранить
                    </Button>
                    <Button
                        variant="contained"
                        color="grey"
                        startIcon={<DeleteOutlineRoundedIcon />}
                        onClick={() => setShowRemoveEmployeeConfirmation(true)}
                        sx={{
                            ml: 3,
                        }}
                    >
                        Удалить
                    </Button>
                </Box>
            </Box>

            <ConfirmationWindow
                opened={showRemoveEmployeeConfirmation}
                onConfirm={removeEmployee}
                onClose={() => setShowRemoveEmployeeConfirmation(false)}
                title="Вы уверены, что хотите удалить сотрудника?"
            />
        </Paper>
    )
}

const EmployeePageContainer: React.FC = () => {
    const { employee, error, loaded } = useEmployee()

    if (error) {
        return <>{error}</>
    }

    if (!loaded || !employee) {
        return <CircularProgress />
    }

    return <EmployeePage employee={employee} />
}

export default EmployeePageContainer
