import { Button, Typography } from '@mui/material'
import { ReactComponent as EyeSvg } from 'assets/icons/Eye.svg'
import { useToggle } from 'hooks'
import React from 'react'

import OrderWorkerViewsDetailsModal from './OrderWorkerViewsDetailsModal'

type OrderWorkerViewsProps = {
    viewsCount: number
}
function OrderWorkerViews({ viewsCount }: OrderWorkerViewsProps) {
    const modal = useToggle()

    return (
        <>
            <Button onClick={modal.open} variant="text">
                <Typography
                    variant="subtitle2"
                    color="grey.600"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                    }}
                >
                    <EyeSvg /> {viewsCount}
                </Typography>
            </Button>

            {modal.opened && <OrderWorkerViewsDetailsModal onClose={modal.close} />}
        </>
    )
}

export default OrderWorkerViews
