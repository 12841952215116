import { Box, Paper, Typography } from '@mui/material'
import { ReactComponent as CalendarSvg } from 'assets/icons/Calendar.svg'
import React from 'react'
import { dateHelper } from 'utils'

type OrderPageLayoutProps = {
    orderNumber: string
    publishedAt: Date
    viewsCount: React.ReactElement
    status: React.ReactElement
    task: React.ReactElement
    dateTime: React.ReactElement
    price: React.ReactElement
    customer: React.ReactElement
    worker?: React.ReactElement
    location: React.ReactElement
    actions: React.ReactElement
    cancellationReason?: React.ReactElement
    orderResponses: React.ReactElement
}

function OrderPageLayout({
    orderNumber,
    viewsCount,
    status,
    publishedAt,
    task,
    customer,
    worker,
    location,
    price,
    dateTime,
    actions,
    cancellationReason,
    orderResponses,
}: OrderPageLayoutProps) {
    return (
        <Paper
            sx={{
                bgcolor: 'white',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                p: 4,
                borderRadius: '10px',
            }}
            elevation={0}
        >
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h4" fontWeight={700}>
                    Заказ #{orderNumber}
                </Typography>
                <Box ml={2}>{viewsCount}</Box>
                <Box ml={5}>{status}</Box>
                <Box ml="auto">{actions}</Box>
            </Box>

            <Box
                mt={1}
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '5px',
                }}
            >
                <CalendarSvg />
                <Typography variant="subtitle2" color="secondary.main">
                    {`Опубликован ${dateHelper.formatDate(publishedAt, { format: 'DD.MM.YYYY' })}`}
                </Typography>
            </Box>

            {cancellationReason && (
                <Box mt={4}>
                    <Typography variant="h5" fontWeight={600} mb="12px">
                        Причина отмены
                    </Typography>
                    {cancellationReason}
                </Box>
            )}

            <Box mt={4}>
                <Typography variant="h5" fontWeight={600} mb="12px">
                    Задача
                </Typography>
                {task}
            </Box>

            <Box mt={4}>
                <Typography variant="h5" fontWeight={600} mb="12px">
                    Дата и время
                </Typography>
                {dateTime}
            </Box>

            <Box mt={4}>
                <Typography variant="h5" fontWeight={600} mb="12px">
                    Стоимость
                </Typography>
                {price}
            </Box>

            <Box mt={4}>
                <Typography variant="h5" fontWeight={600} mb="12px">
                    Клиент
                </Typography>
                {customer}
            </Box>

            {worker && (
                <Box mt={4}>
                    <Typography variant="h5" fontWeight={600} mb="12px">
                        Мастер
                    </Typography>
                    {worker}
                </Box>
            )}

            <Box mt={4}>
                <Typography variant="h5" fontWeight={600} mb="12px">
                    Место
                </Typography>
                {location}
            </Box>

            <Box mt={4}>
                <Typography variant="h5" fontWeight={600} mb="12px">
                    Отклики
                </Typography>
                {orderResponses}
            </Box>
        </Paper>
    )
}

export default OrderPageLayout
