import { useRef } from 'react'

function usePrevValue<T>(value: T, next?: (prevValue: T) => T): T {
    const prev = useRef(value)

    const prevValue = prev.current
    prev.current = next ? next(prevValue) : value

    return prevValue
}

export default usePrevValue
