import { useDebounce } from 'hooks'
import { useMemo, useState } from 'react'
import apiService from 'services/api-service'
import useSWR from 'swr'

import useFilter from './useFilter'

export type ActiveWorker = {
    id: string
    phone: string
    firstname?: string
    lastname?: string
    avatarUrl?: string
    cityId?: string
    createdAt: string
}

type Sort = { row: string; order: 'asc' | 'desc' }

const FIVE_MINUTES = 5 * 60 * 1000

const useActiveWorkers = () => {
    const { filter } = useFilter()
    const [limit, changeLimit] = useState<10 | 25 | 50>(50)
    const [page, changePage] = useState(1)
    const [sort, changeSort] = useState<Sort>({ row: 'createdAt', order: 'desc' })
    const debouncedFilter = useDebounce(filter, 1000)

    const { data, error } = useSWR(
        ['active-workers', limit, page, sort, debouncedFilter],
        () => apiService.getWorkers({ limit, page, sort, filter: debouncedFilter }),
        { focusThrottleInterval: FIVE_MINUTES, refreshInterval: FIVE_MINUTES }
    )

    const pagination = useMemo(
        () => (data ? { limit, page, total_pages: data.totalPages } : undefined),
        [data, limit, page]
    )

    return {
        data: {
            workers: data?.items ?? [],
            totalCount: data?.totalCount ?? 0,
            pagination,
            sort,
        },
        changeLimit,
        changeSort,
        changePage,
        loading: !data,
        error,
    }
}

export default useActiveWorkers
