import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, Button, Grid, Typography } from '@mui/material'
import Table from 'app/components/Table'
import { TitleBlock } from 'app/components/TitleBlock'
import { ORDERS_STATE_NAMES } from 'app/constants'
import { useRouter } from 'hooks'
import React, { useCallback, useMemo } from 'react'
import { Order } from 'services/api-service'
import { TTableRowData } from 'types/ITable'
import { dateHelper } from 'utils'

type OrderItem = {
    id: string
    number: string
    date: string
    name: string
    category: string
    customer: {
        name: string
        phone: string
    }
    status: string
}

const CustomerOrdersField: TTableRowData[] = [
    {
        name: 'number',
        title: 'Номер',
        xs: 3,
        element: (order: OrderItem) => <>{`#${order.number}`}</>,
    },
    {
        name: 'task',
        title: 'Задача',
        xs: 3,
        element: (order: OrderItem) => (
            <>
                <Box flexDirection={'column'}>
                    <Typography variant="subtitle2">{order.name}</Typography>
                    <Typography variant="subtitle2">{order.category}</Typography>
                </Box>
            </>
        ),
    },
    {
        name: 'date',
        title: 'Дата',
        xs: 3,
        element: (order: OrderItem) => (
            <>
                <Box display={'flex'} flexDirection={'column'}>
                    <Typography fontSize={16} variant="subtitle2" color="grey.600">
                        {dateHelper.formatDate(order.date)}
                    </Typography>
                    <Grid container>
                        <Grid item>
                            <Typography fontSize={13} variant="subtitle2" color="grey.600">
                                #{order.number}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </>
        ),
    },
    {
        name: 'status',
        title: 'Статус',
        xs: 3,
        element: (order: OrderItem) => (
            <>
                <Typography variant="subtitle2" color="grey.600">
                    {order.status}
                </Typography>
            </>
        ),
    },
]

const getCustomerOrdersTableData = (orders?: Order[]): OrderItem[] => {
    return (
        orders?.map((order) => {
            return {
                id: order.id,
                status: ORDERS_STATE_NAMES[order.state],
                number: order.number,
                date: order.publishedAt,
                name: order.works?.[0]?.name ?? '-',
                category: order.works?.[0]?.category?.name ?? '-',
                customer: {
                    name: order.customer.firstname,
                    phone: order.customer.phone,
                },
            }
        }) ?? []
    )
}

type CustomerOrdersProps = {
    customerId: string
    orders: Order[]
    totalCount: number
}
function CustomerOrders({ customerId, orders, totalCount }: CustomerOrdersProps) {
    const ordersTableData = useMemo(() => getCustomerOrdersTableData(orders), [orders])

    const router = useRouter()

    const handleClickRow = useCallback((order: OrderItem) => router.goToOrderPage(order.id), [router])

    return (
        <Box>
            <TitleBlock
                title="Заказы"
                variant="h5"
                px={0}
                count={totalCount}
                rightElement={
                    totalCount > 0 && (
                        <Button
                            onClick={() => router.goToOrdersPage({ customerId, hideFilters: true })}
                            variant="text"
                            color="secondary"
                            endIcon={<ChevronRightIcon />}
                        >
                            Показать все
                        </Button>
                    )
                }
            />
            <Box mt="26px">
                <Table px={0} items={ordersTableData} rows={CustomerOrdersField} handleClickRow={handleClickRow} />
            </Box>
        </Box>
    )
}

export default CustomerOrders
