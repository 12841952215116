import { Avatar, Box, Paper, Typography } from '@mui/material'
import CityName from 'app/components/CityName'
import Table from 'app/components/Table'
import { TitleBlock } from 'app/components/TitleBlock'
import { useRouter } from 'hooks'
import React, { useCallback, useMemo } from 'react'
import { TTableRowData } from 'types/ITable'
import { dateHelper } from 'utils'

import Filters from './Filters'
import useFinance, { Tariff } from './useFinance'

function FinancePage() {
    const { data, loading, changeLimit, changePage, changeSort } = useFinance()
    const tableRows = useMemo(() => getTableRows(), [])

    const router = useRouter()
    const handleClickByRow = useCallback((tariff: Tariff) => router.goToWorkerPage(tariff.worker.id), [router])

    return (
        <Paper
            sx={{
                bgcolor: 'white',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                py: 4,
            }}
            elevation={0}
        >
            <TitleBlock px={4} title="Финансы" subtitle={data.purchaseTotalSumText} />
            <Box px={4} mt={4}>
                <Filters />
            </Box>
            <Box mt={4}>
                <Table
                    items={data.tariffs}
                    rows={tableRows}
                    order={data.sort}
                    pagination={data.pagination}
                    isLoading={loading}
                    handleOrderChange={changeSort}
                    handleLimitChange={changeLimit}
                    handlePageChange={changePage}
                    handleClickRow={handleClickByRow}
                />
            </Box>
        </Paper>
    )
}

function renderWorkerNameColumn() {
    return {
        title: 'Мастер',
        name: 'workerName',
        isSort: true,
        xs: 3,
        element: ({ worker }: Tariff) => {
            let fullName = worker.firstname ?? ''
            if (worker.lastname) {
                fullName += fullName !== '' ? ` ${worker.lastname}` : worker.lastname
            }
            if (!fullName) {
                fullName = 'Без имени'
            }

            const avatarPlaceholer = fullName
                .split(' ')
                .map((c) => c[0].toUpperCase())
                .slice(0, 2)

            return (
                <>
                    <Avatar src={worker.avatarUrl} alt={fullName} sx={{ width: 36, height: 36 }}>
                        {avatarPlaceholer}
                    </Avatar>
                    <Box ml={2}>
                        <Typography variant="body2">{fullName}</Typography>
                    </Box>
                </>
            )
        },
    }
}

function renderTariffNameColumn() {
    return {
        title: 'Тариф',
        name: 'tariff',
        xs: 2,
        element: ({ name }: Tariff) => (
            <>
                <Typography variant="body2" color="grey.600">
                    {name}
                </Typography>
            </>
        ),
    }
}

function renderPurchaseSumColumn() {
    return {
        title: 'Сумма',
        name: 'purchaseSum',
        isSort: true,
        xs: 2,
        element: ({ purchasesPriceText }: Tariff) => (
            <>
                <Typography variant="body2" color="grey.600">
                    {purchasesPriceText}
                </Typography>
            </>
        ),
    }
}

function renderCityColumn() {
    return {
        title: 'Город',
        name: 'city',
        xs: 2,
        element: ({ worker }: Tariff) => (
            <>
                <Typography variant="body2" color="grey.600">
                    {worker.cityId ? <CityName cityId={worker.cityId} /> : '-'}
                </Typography>
            </>
        ),
    }
}

function renderPurchasedAtColumn() {
    return {
        title: 'Дата оплаты',
        name: 'purchasedAt',
        isSort: true,
        xs: 3,
        element: ({ purchasedAt }: Tariff) => (
            <Typography variant="body2" color="grey.600">
                {dateHelper.formatDate(purchasedAt)}
            </Typography>
        ),
    }
}

function getTableRows(): TTableRowData[] {
    return [
        renderWorkerNameColumn(),
        renderTariffNameColumn(),
        renderPurchaseSumColumn(),
        renderCityColumn(),
        renderPurchasedAtColumn(),
    ]
}

export default FinancePage
