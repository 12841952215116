import { Box, Paper, Typography } from '@mui/material'
import Sidebar from 'app/components/Sidebar'
import { useUser } from 'hooks'
import React from 'react'

const Header = () => {
    const { user } = useUser()
    return (
        <Paper
            sx={{
                bgcolor: 'white',
                height: '68px',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexShrink: 0,
                px: 3,
            }}
            elevation={0}
            square={true}
        >
            <Box width="100%" display="flex" justifyContent="flex-end">
                {user && (
                    <Box textAlign="right">
                        <Typography fontSize={14} component="div">
                            {user.firstname && user.lastname && `${user.firstname} ${user.lastname}`}
                        </Typography>
                        <Typography fontSize={14} component="div" color="grey.600">
                            {user.email}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Paper>
    )
}

type PageLayoutProps = {
    children: React.ReactNode
}

function PageLayout({ children }: PageLayoutProps) {
    return (
        <Box display="flex" height="100vh">
            <Sidebar />
            <Box display={'flex'} flexDirection={'column'} flex={'1 1 auto'} maxHeight="100vh" overflow={'auto'}>
                <Header />
                <Box
                    sx={{
                        p: 3,
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    )
}

export default PageLayout
