import { Box, Grid, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'

import { StarSvg } from '../../../assets/icons/Star'

export interface FeedbackProps {
    photoUrl?: string
    name: string
    date: string
    text: string
    rating: number
    id: string
}

export const Feedback: React.FC<FeedbackProps> = ({ name, date, text, rating, photoUrl }: FeedbackProps) => {
    return (
        <Box
            p={2}
            sx={{
                border: '1px solid #E5E5EA',
                borderRadius: '14px',
                minHeight: '94px',
            }}
        >
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Grid container>
                        {photoUrl && (
                            <Grid item sx={{ marginRight: '10px' }}>
                                <img style={{ width: '36px', height: '36px', borderRadius: '100px' }} src={photoUrl} />
                            </Grid>
                        )}
                        <Grid item>
                            <Typography fontSize={16} fontWeight={600}>
                                {name}
                            </Typography>
                            <Grid container spacing="3px">
                                {Array(rating)
                                    .fill('', 0, rating)
                                    .map((item, index) => (
                                        <Grid item sx={{ lineHeight: 1 }} key={index}>
                                            <StarSvg width={10} height={10} />
                                        </Grid>
                                    ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography fontSize={16} variant="subtitle2" color="grey.600">
                        {moment(date).format('D MMMM')}
                    </Typography>
                </Grid>
            </Grid>
            <Box mt="10px">
                <Typography fontSize={16} variant="body1" color="grey.600">
                    {text}
                </Typography>
            </Box>
        </Box>
    )
}
