import { Box, Paper, Typography } from '@mui/material'
import Table from 'app/components/Table'
import { TitleBlock } from 'app/components/TitleBlock'
import { ORDER_STATE_COLORS, ORDERS_STATE_NAMES } from 'app/constants'
import { useRouter } from 'hooks'
import React, { useCallback, useMemo } from 'react'
import { TTableRowData } from 'types/ITable'
import { dateHelper, formatPhone, plularize } from 'utils'

import Filters from './Filters'
import useOrders, { Order } from './useOrders'

const OrdersPage: React.FC = () => {
    const { data, loading, showFilters, changeLimit, changeSort, changePage } = useOrders()

    const router = useRouter()
    const handleClickByRow = useCallback((order: Order) => router.goToOrderPage(order.id), [router])

    const tableRows = useMemo(() => getTableRows(), [])

    return (
        <>
            <Paper
                sx={{
                    bgcolor: 'white',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    py: 4,
                }}
                elevation={0}
            >
                <TitleBlock px={4} title="Заказы" count={data.totalCount} />
                {showFilters && (
                    <Box px={4} mt={4}>
                        <Filters />
                    </Box>
                )}
                <Box mt={4}>
                    <Table
                        items={data.orders}
                        rows={tableRows}
                        order={data.sort}
                        pagination={data.pagination}
                        isLoading={loading}
                        handleOrderChange={changeSort}
                        handleLimitChange={changeLimit}
                        handlePageChange={changePage}
                        handleClickRow={handleClickByRow}
                    />
                </Box>
            </Paper>
        </>
    )
}

function renderOrderColumn() {
    return {
        title: 'Заказ',
        name: 'publishedAt',
        isSort: true,
        xs: 3,
        element: (order: Order) => (
            <>
                <Box display="flex" flexDirection="column">
                    <Typography variant="body2" fontWeight={600}>
                        <Box component="span">{`# ${order.number}`}</Box>
                        <Box component="span" color="grey.600">
                            {' • '}
                        </Box>
                        <Box component="span" sx={{ color: ORDER_STATE_COLORS[order.state] }}>
                            {ORDERS_STATE_NAMES[order.state]}
                        </Box>
                    </Typography>
                    {order.cancellationReason && (
                        <Box sx={{ fontSize: 12, color: ORDER_STATE_COLORS[order.state] }}>
                            {order.cancellationReason.title}
                        </Box>
                    )}
                    <Typography mt={1} variant="body3" color="grey.600">
                        {dateHelper.formatDate(order.publishedAt)}
                    </Typography>

                    <Typography mt={1} variant="body3" color="grey.600">
                        {order.location.address}
                    </Typography>
                </Box>
            </>
        ),
    }
}

function renderWorksColumn() {
    return {
        title: 'Задача',
        name: 'comment',
        isSort: false,
        xs: 3,
        headerXs: 2,
        element: (order: Order) => {
            const showMaxWorksCount = 3
            const showMaxCommentLength = 47
            const works = order.works.slice(0, showMaxWorksCount)
            const moreOrdersCount = order.works.length - works.length
            const moreOrdersText = moreOrdersCount
                ? `И ещё ${moreOrdersCount} ${plularize(moreOrdersCount, ['работа', 'работы', 'работ'])}...`
                : undefined

            let comment = order.comment ?? ''
            if (comment.length > showMaxCommentLength) {
                comment = comment.slice(0, showMaxCommentLength) + '...'
            }

            return (
                <Box width="100%">
                    {works.map((item, index) => (
                        <Typography
                            key={index}
                            variant="body2"
                            sx={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}
                        >
                            {item.name}
                        </Typography>
                    ))}
                    {moreOrdersText && <Typography variant="body2">{moreOrdersText}</Typography>}

                    {comment && (
                        <Typography variant="body3" color="grey.600">
                            {comment}
                        </Typography>
                    )}
                </Box>
            )
        },
    }
}

function renderPricesColumn() {
    return {
        title: 'Цена клиента / мастера',
        alignTitle: 'right',
        name: 'comment',
        isSort: false,
        xs: 2,
        headerXs: 3,
        element: (order: Order) => {
            const customerPrice = order.customerPrice
                ? `${order.customerPrice.value} ${order.customerPrice.currencySymbol}`
                : '-'
            const workerPrice = `${order.totalPrice.value} ${order.totalPrice.currencySymbol}`

            return (
                <Box width="100%">
                    <Typography variant="body2">
                        {customerPrice}
                        <Box component="span" color="grey.600">
                            {` / ${workerPrice}`}
                        </Box>
                    </Typography>
                </Box>
            )
        },
    }
}

function renderCustomerColumn() {
    return {
        title: 'Клиент',
        name: 'ownerId',
        isSort: false,
        xs: 2,
        element: ({ customer }: Order) => (
            <>
                <Box>
                    <Typography variant="body2" fontWeight={500} color="grey.600">
                        {formatPhone(customer.phone)}
                    </Typography>

                    {customer.firstname && customer.lastname && (
                        <Typography variant="body3" color="grey.600">
                            {`${customer.firstname} ${customer.lastname}`}
                        </Typography>
                    )}
                </Box>
            </>
        ),
    }
}

function renderWorkerColumn() {
    return {
        title: 'Мастер',
        name: 'state',
        isSort: false,
        xs: 2,
        element: ({ worker, viewsCount, responsesCount }: Order) => (
            <Box>
                {worker && (
                    <>
                        <Typography variant="body2" fontWeight={600} color="grey.600">
                            {formatPhone(worker.phone)}
                        </Typography>
                        {worker.firstname && worker.lastname && (
                            <Typography variant="body3" color="grey.600">
                                {`${worker.firstname} ${worker.lastname}`}
                            </Typography>
                        )}
                    </>
                )}
                {!worker && (
                    <>
                        <Typography variant="body2" color="grey.600">
                            {`${responsesCount} ${plularize(responsesCount, ['отклик', 'отклика', 'откликов'])}`}
                        </Typography>
                        <Typography variant="body2" color="grey.600">
                            {`${viewsCount} ${plularize(viewsCount, ['просмотр', 'просмотра', 'просмотров'])}`}
                        </Typography>
                    </>
                )}
            </Box>
        ),
    }
}

function getTableRows(): TTableRowData[] {
    return [
        renderOrderColumn(),
        renderWorksColumn(),
        renderPricesColumn(),
        renderCustomerColumn(),
        renderWorkerColumn(),
    ]
}

export default OrdersPage
