import { Box, Typography } from '@mui/material'
import React from 'react'
import { formatPhone } from 'utils'

import Avatar from '../Avatar'

type ProfileProps = {
    photoUrl?: string | null
    firstName?: string | null
    lastName?: string | null
    phone?: string | null
}

function Profile({ photoUrl, firstName, lastName, phone }: ProfileProps) {
    const fullName = [firstName, lastName].filter(Boolean).join(' ') || 'Без имени'
    return (
        <Box display="flex" alignItems="center">
            <Avatar srcUrl={photoUrl} firstName={firstName} lastName={lastName} size="s" />
            <Box display="flex" flexDirection="column" ml={2}>
                <Typography variant="body2">{fullName}</Typography>
                {phone && (
                    <Typography variant="subtitle2" color="grey.600">
                        {formatPhone(phone)}
                    </Typography>
                )}
            </Box>
        </Box>
    )
}

export default Profile
