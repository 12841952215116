import { Box, Paper } from '@mui/material'
import Breadcrumbs from 'app/components/Breadcrumbs'
import MultilineTextEllipsis from 'app/components/MultilineTextEllipsis'
import Table from 'app/components/Table'
import { TitleBlock } from 'app/components/TitleBlock'
import Tooltip from 'app/components/Tooltip'
import { OrderResponseChat } from 'app/modules/OrderResponses'
import { useWorker } from 'app/modules/Worker'
import { useRouter } from 'hooks'
import React, { useCallback } from 'react'
import { TTableRowData } from 'types/ITable'
import { dateHelper, names } from 'utils'

import useWorkerResponses, { WorkerResponseItem } from './useWorkerResponses'

const columns: TTableRowData<WorkerResponseItem>[] = [
    {
        name: 'order',
        title: 'Заказ',
        xs: 2,
        element: (data) => data.order.subcategoryName,
    },
    {
        name: 'createdAt',
        title: 'Дата отклика',
        xs: 2,
        element: (data) => dateHelper.formatDate(data.createdAt),
    },
    {
        name: 'price',
        title: 'Стоимость',
        xs: 2,
        element: (data) => `${data.priceFromWorker.value} ${data.priceFromWorker.currencySymbol}`,
    },
    {
        name: 'status',
        title: 'Статус',
        xs: 2,
        element: (data) => <Box color={data.status === 'rejected' ? '#f34848' : undefined}>{data.statusText}</Box>,
    },
    {
        name: 'comment',
        title: 'Комментарий',
        xs: 3,
        element: (data) =>
            data.comment ? (
                <Tooltip title={data.comment}>
                    <MultilineTextEllipsis lines={2}>{data.comment}</MultilineTextEllipsis>
                </Tooltip>
            ) : (
                <>–</>
            ),
    },
    {
        name: 'chat',
        title: 'Чат',
        xs: 1,
        alignTitle: 'center',
        element: (data) => (
            <Box display="flex" justifyContent="center" width="100%" onClick={(e) => e.stopPropagation()}>
                <OrderResponseChat
                    channel={data.chatId}
                    customer={{
                        id: data.order.customer.id,
                        firstName: data.order.customer.firstName,
                        lastName: data.order.customer.lastName,
                        avatarUrl: data.order.customer.avatarUrl,
                    }}
                    worker={{
                        id: data.worker.id,
                        firstName: data.worker.firstName,
                        lastName: data.worker.lastName,
                        avatarUrl: data.worker.avatarUrl,
                    }}
                />
            </Box>
        ),
    },
]

function OrderResponsesPage() {
    const { worker } = useWorker()
    const { responses, loaded, totalCount, pagination, changePage, changeLimit } = useWorkerResponses()

    const router = useRouter()
    const handleClickByRow = useCallback((data: WorkerResponseItem) => router.goToOrderPage(data.order.id), [router])

    const breadcrumbsLinks = worker
        ? [
              {
                  link: '/workers',
                  label: 'Мастера',
              },
              {
                  link: `/workers/${worker.id}`,
                  label: names.getFullName({ firstName: worker.firstname, lastName: worker.lastname }) ?? worker.id,
              },
              {
                  link: '',
                  label: 'Все отзывы',
              },
          ]
        : []

    return (
        <>
            <Paper
                sx={{
                    bgcolor: 'white',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 4,
                }}
                elevation={0}
            >
                <Breadcrumbs items={breadcrumbsLinks} />
                <TitleBlock mt={2} px={0} title="Отклики" count={totalCount} />
                <Box mt={4}>
                    <Table
                        px={0}
                        rowPx={0}
                        items={responses}
                        rows={columns}
                        // order={data.sort}
                        pagination={pagination}
                        isLoading={!loaded}
                        // handleOrderChange={changeSort}
                        handleLimitChange={changeLimit}
                        handlePageChange={changePage}
                        handleClickRow={handleClickByRow}
                    />
                </Box>
            </Paper>
        </>
    )
}

export default OrderResponsesPage
