import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { MoreButton } from 'app/components/Button'
import Table from 'app/components/Table'
import { TitleBlock } from 'app/components/TitleBlock'
import { ReactComponent as EditIconSVG } from 'assets/icons/edit_icon.svg'
import React, { useCallback, useMemo } from 'react'
import apiService from 'services/api-service'
import { TTableRowData } from 'types/ITable'

import { WorkSubcategory } from './types'
import useSortList from './useSortList'

type WorkSubcategoriesListProps = {
    loaded: boolean
    editable: boolean
    subcategories: WorkSubcategory[]
    onNewSubcategory: () => void
    onEditSubcategory: (subcategory: WorkSubcategory) => void
    onSelectSubcategory: (subcategory: WorkSubcategory) => void
    onPositionChanged: () => void
}

function WorkSubcategoriesList({
    subcategories: initialSubcategories,
    onNewSubcategory,
    onEditSubcategory,
    onSelectSubcategory,
    loaded,
    editable,
    onPositionChanged,
}: WorkSubcategoriesListProps) {
    const {
        sortedValues: subcategories,
        startSort,
        sortEnabled,
        changeSort,
        saveSort,
        cancelSort,
        sorting,
    } = useSortList(initialSubcategories, {
        saveHandler: (subcategories) => {
            const sortedCategoryIds = subcategories.map((subcategory) => subcategory.id)
            return apiService.updateWorkCategoryPositions({ sortedCategoryIds })
        },
        onSaved: onPositionChanged,
    })

    const showEditIcon = editable && !sortEnabled
    const clickableRow = !sortEnabled
    const showMoreButton = editable && !sortEnabled
    const showSortActions = sortEnabled

    const tableRows: TTableRowData[] = useMemo(
        () => [
            {
                title: 'Категория',
                name: 'name',
                xs: 9,
                element: (subcategory: WorkSubcategory) => (
                    <>
                        <Box
                            alt={subcategory.name}
                            src={subcategory.iconUrl}
                            component="img"
                            width="28px"
                            height="28px"
                            ml={2}
                        />
                        <Typography variant="body2" fontWeight={600} ml={2}>
                            {subcategory.name}
                        </Typography>
                    </>
                ),
            },
            {
                title: 'Услуги',
                name: 'worksCount',
                xs: 3,
                element: (subcategory: WorkSubcategory) => (
                    <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                        <Typography variant="body2" color="grey.600">
                            {subcategory.works.length}
                        </Typography>

                        {showEditIcon && (
                            <IconButton
                                onClick={(e) => {
                                    e.preventDefault()
                                    onEditSubcategory(subcategory)
                                }}
                            >
                                <EditIconSVG />
                            </IconButton>
                        )}
                    </Box>
                ),
            },
        ],
        [onEditSubcategory]
    )

    const rightElement = (
        <>
            {showMoreButton && (
                <MoreButton
                    options={[
                        { title: 'Создать подкатегорию', onSelect: onNewSubcategory },
                        { title: 'Изменить порядок', onSelect: startSort },
                    ]}
                    position="right"
                />
            )}
            {showSortActions && (
                <>
                    <Button variant="contained" color="grey" onClick={cancelSort} disabled={sorting}>
                        Отменить
                    </Button>
                    <LoadingButton
                        loading={sorting}
                        variant="contained"
                        color="primary"
                        sx={{
                            ml: 1,
                        }}
                        onClick={saveSort}
                    >
                        Сохранить
                    </LoadingButton>
                </>
            )}
        </>
    )

    const handleClickRow = useCallback(
        (subcategory: WorkSubcategory) => onSelectSubcategory(subcategory),
        [onSelectSubcategory]
    )

    return (
        <Box>
            <TitleBlock title="Подкатегории" rightElement={rightElement} />
            <Box mt={4}>
                <Table
                    items={subcategories}
                    rows={tableRows}
                    isLoading={!loaded}
                    handleClickRow={clickableRow ? handleClickRow : undefined}
                    isDraggable={sortEnabled}
                    onPositionChange={sortEnabled ? changeSort : undefined}
                />
            </Box>
        </Box>
    )
}

export default WorkSubcategoriesList
