import mapKeys from 'lodash/mapKeys'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'

export type QueryObj = Partial<{ [key: string]: string | string[] | number | number[] }>

function stringifyQuery(query: QueryObj) {
    let data = pickBy(query, (value) => value !== undefined && value !== null && value !== '')
    data = pickBy(data, (value) => !Array.isArray(value) || value.length !== 0)
    data = mapKeys(data, (value, key) => (Array.isArray(value) ? `${key}[]` : key))
    data = mapValues(data, (value) => (Array.isArray(value) ? value.join(',') : value))

    const result = new window.URLSearchParams(data as any).toString()
    return result
        .replace(/%5B%5D/g, '[]') // %5B%5D - []
        .replace(/%2C/g, ',') // %2C - ,
}

function parseQuery<T extends QueryObj>(string: string) {
    const search = string.includes('?') ? string.split('?')[1] : string
    const urlSearchParams = new window.URLSearchParams(search)

    let query = [...urlSearchParams.entries()].reduce((res, [key, value]) => ({ ...res, [key]: value }), {})
    query = mapValues(query, (value: string, key) => (key.slice(-2) === '[]' ? value.split(',') : value))
    query = mapKeys(query, (_, key) => (key.slice(-2) === '[]' ? key.slice(0, -2) : key))

    return query as T
}

function parseUrl<Q extends QueryObj>(data: string): { path: string; query: Q } {
    const [path = '', search = ''] = data.split('?')
    return {
        path,
        query: parseQuery<Q>(search),
    }
}

export default {
    stringifyQuery,
    parseQuery,
    parseUrl,
}
