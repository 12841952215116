import { Close as CloseIcon } from '@mui/icons-material'
import { Box, Breadcrumbs, Drawer, IconButton, Paper, Typography } from '@mui/material'
import { useUser } from 'hooks'
import React, { useCallback, useMemo, useState } from 'react'

import { Work, WorkCategory, WorkSubcategory } from './types'
import useWorkCategories from './useWorkCategories'
import WorkCategoriesList from './WorkCategoriesList'
import WorkCategoryForm from './WorkCategoryForm'
import WorkForm from './WorkForm'
import WorksList from './WorksList'
import WorkSubcategoriesList from './WorkSubcategoriesList'

function CatalogPage() {
    const { permissions } = useUser()
    const { categories, loaded, update } = useWorkCategories()
    const [viewListState, setViewListState] = useState<
        | { type: 'show_categories' }
        | { type: 'show_subcategories'; category: WorkCategory }
        | { type: 'show_works'; category: WorkCategory; subcategory: WorkSubcategory }
    >({ type: 'show_categories' })
    const [viewDrawerState, setViewDrawerState] = useState<
        | { type: 'create_category' }
        | { type: 'edit_category'; category: WorkCategory }
        | { type: 'create_subcategory'; category: WorkCategory }
        | { type: 'edit_subcategory'; subcategory: WorkSubcategory }
        | { type: 'create_work'; subcategory: WorkSubcategory }
        | { type: 'edit_work'; work: Work }
    >()

    const subcategories = useMemo(() => {
        if ('category' in viewListState) {
            return categories
                .filter((category) => category.id === viewListState.category.id)
                .map((category) => category.subcategories)
                .flat()
        }

        return []
    }, [categories, viewListState])

    const works = useMemo(() => {
        if ('subcategory' in viewListState) {
            return subcategories
                .filter((subcategory) => subcategory.id === viewListState.subcategory.id)
                .map((subcategory) => subcategory.works)
                .flat()
        }

        return []
    }, [categories, viewListState])

    const handleDone = useCallback(async () => {
        await update()
        setViewDrawerState(undefined)
    }, [update, setViewDrawerState])

    return (
        <Paper
            sx={{
                bgcolor: 'white',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                py: 4,
            }}
            elevation={0}
        >
            <Breadcrumbs
                aria-label="breadcrumb"
                sx={{ px: 4, mb: 1.5, '& .MuiBreadcrumbs-separator': { display: 'list-item' } }}
                separator={
                    <Typography component="span" variant="body3">
                        /
                    </Typography>
                }
            >
                {viewListState.type === 'show_categories' && (
                    <Typography key="1" component="span" variant="body3">
                        Каталог
                    </Typography>
                )}
                {viewListState.type === 'show_subcategories' && [
                    <Typography
                        key="1"
                        color="primary"
                        variant="body3"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setViewListState({ type: 'show_categories' })}
                    >
                        Каталог
                    </Typography>,
                    <Typography key="2" component="span" variant="body3">
                        {viewListState.category.name}
                    </Typography>,
                ]}
                {viewListState.type === 'show_works' && [
                    <Typography
                        key="1"
                        color="primary"
                        variant="body3"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setViewListState({ type: 'show_categories' })}
                    >
                        Каталог
                    </Typography>,
                    <Typography
                        key="2"
                        color="primary"
                        variant="body3"
                        sx={{ cursor: 'pointer' }}
                        onClick={() =>
                            setViewListState({ type: 'show_subcategories', category: viewListState.category })
                        }
                    >
                        {viewListState.category.name}
                    </Typography>,
                    <Typography key="3" component="span" variant="body3">
                        {viewListState.subcategory.name}
                    </Typography>,
                ]}
            </Breadcrumbs>

            {viewListState.type === 'show_categories' && (
                <WorkCategoriesList
                    loaded={loaded}
                    editable={permissions.canEditCatalog}
                    categories={categories}
                    onNewCategory={() => {
                        setViewDrawerState({ type: 'create_category' })
                    }}
                    onEditCategory={(category) => {
                        setViewDrawerState({ type: 'edit_category', category })
                    }}
                    onSelectCategory={(category) => {
                        setViewListState({ type: 'show_subcategories', category })
                    }}
                    onPositionChanged={update}
                />
            )}
            {viewListState.type === 'show_subcategories' && (
                <WorkSubcategoriesList
                    loaded={loaded}
                    editable={permissions.canEditCatalog}
                    subcategories={subcategories}
                    onNewSubcategory={() => {
                        setViewDrawerState({ type: 'create_subcategory', category: viewListState.category })
                    }}
                    onEditSubcategory={(subcategory) => {
                        setViewDrawerState({ type: 'edit_subcategory', subcategory })
                    }}
                    onSelectSubcategory={(subcategory) => {
                        setViewListState({ type: 'show_works', category: viewListState.category, subcategory })
                    }}
                    onPositionChanged={update}
                />
            )}
            {viewListState.type === 'show_works' && (
                <WorksList
                    loaded={loaded}
                    editable={permissions.canEditCatalog}
                    works={works}
                    onNewWork={() => {
                        setViewDrawerState({ type: 'create_work', subcategory: viewListState.subcategory })
                    }}
                    onEditWork={(work) => {
                        setViewDrawerState({ type: 'edit_work', work })
                    }}
                    onPositionChanged={update}
                />
            )}

            <Drawer anchor="right" open={Boolean(viewDrawerState)} onClose={() => setViewDrawerState(undefined)}>
                <Box position="relative" padding={5} width={500}>
                    <Box position="absolute" top={8} right={8}>
                        <IconButton onClick={() => setViewDrawerState(undefined)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    {viewDrawerState?.type === 'create_category' && <WorkCategoryForm onDone={handleDone} />}
                    {viewDrawerState?.type === 'edit_category' && (
                        <WorkCategoryForm initialValues={viewDrawerState.category} onDone={handleDone} />
                    )}

                    {viewDrawerState?.type === 'create_subcategory' && (
                        <WorkCategoryForm
                            initialValues={{ parentCategoryId: viewDrawerState.category.id }}
                            onDone={handleDone}
                        />
                    )}
                    {viewDrawerState?.type === 'edit_subcategory' && (
                        <WorkCategoryForm initialValues={viewDrawerState.subcategory} onDone={handleDone} />
                    )}

                    {viewDrawerState?.type === 'create_work' && (
                        <WorkForm
                            onDone={handleDone}
                            initialValues={{ subcategoryId: viewDrawerState.subcategory.id }}
                        />
                    )}
                    {viewDrawerState?.type === 'edit_work' && (
                        <WorkForm onDone={handleDone} initialValues={viewDrawerState.work} />
                    )}
                </Box>
            </Drawer>
        </Paper>
    )
}

export default CatalogPage
