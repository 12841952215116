import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import url, { QueryObj } from 'utils/url'

function useUrlQuery<T extends QueryObj>() {
    const history = useHistory()
    const location = useLocation()

    const query = useMemo(() => url.parseQuery<T>(location.search), [location.search])

    const changeQuery = useCallback(
        (query: string | QueryObj) => {
            const search = typeof query === 'string' ? query : url.stringifyQuery(query)
            history.push({ search })
        },
        [history]
    )

    return {
        query,
        changeQuery,
    }
}

export default useUrlQuery
