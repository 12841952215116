import ReasonPicker from 'app/components/ReasonPicker'
import React from 'react'

import useOrder from './useOrder'
import useOrderCancellationReasons from './useOrderCancellationReasons'

type OrderCancellationProps = {
    onClose: () => void
}
function OrderCancellation({ onClose }: OrderCancellationProps) {
    const { order, cancel, cancelling } = useOrder()
    if (order === undefined) {
        throw new Error()
    }

    const { reasons, loaded } = useOrderCancellationReasons(order.id)
    return (
        <ReasonPicker
            title="Укажите причину отмены"
            buttonText="Отменить"
            reasons={reasons}
            reasonsLoaded={loaded}
            buttonLoading={cancelling}
            onSelect={cancel}
            onClose={onClose}
        />
    )
}

export default OrderCancellation
