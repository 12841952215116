import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import apiService from 'services/api-service'
import * as yup from 'yup'

const validationSchema = yup.object({
    email: yup.string().required().email(),
    password: yup.string().required(),
})

type UseLoginProps = {
    onLogin: () => void
}

const useLogin = ({ onLogin }: UseLoginProps) => {
    const formik = useFormik({
        validationSchema,
        initialValues: {
            email: '',
            password: '',
        },
        validateOnBlur: false,
        validateOnChange: false,
        enableReinitialize: true,
        onSubmit: async (values) => {
            apiService
                .login(values)
                .then(onLogin)
                .catch((error: any) => toast.error(error.data.message, { type: 'error' }))
        },
    })

    return {
        values: formik.values,
        errors: formik.errors,
        changeValues: formik.handleChange,
        submit: formik.handleSubmit,
        submitting: formik.isSubmitting,
    }
}

export default useLogin
