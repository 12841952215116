import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, IconButton } from '@mui/material'
import Profile from 'app/components/Profile'
import { useRouter } from 'hooks'
import React from 'react'

type OrderCustomerProps = {
    customer: {
        id: string
        phone: string
        avatarUrl?: string | null
        firstname?: string
        lastname?: string
    }
}

function OrderCustomer({ customer }: OrderCustomerProps) {
    const router = useRouter()
    return (
        <Box display="flex" alignItems="center">
            <Profile
                phone={customer.phone}
                photoUrl={customer.avatarUrl}
                firstName={customer.firstname}
                lastName={customer.lastname}
            />
            <IconButton sx={{ ml: 1 }} onClick={() => router.goToCustomerPage(customer.id)}>
                <ChevronRightIcon />
            </IconButton>
        </Box>
    )
}

export default OrderCustomer
