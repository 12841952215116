import { Clear as ClearIcon, FilterList as FilterListIcon } from '@mui/icons-material'
import { Box, Grid, IconButton } from '@mui/material'
import Autocomplete from 'app/components/Autocomplete'
import { CityFilter, DateRangeFilter } from 'app/components/Filters'
import Input, { PhoneInput } from 'app/components/Input'
import { ORDER_STATES_FOR_FILTER, ORDERS_STATE_NAMES } from 'app/constants'
import { useToggle } from 'hooks'
import React, { useCallback, useMemo } from 'react'

import useFilter from './useFilter'

type StatusFilterProps = {
    status?: string
    onChange: (status?: string) => void
}
const StatusFilter = ({ status, onChange }: StatusFilterProps) => {
    const options = useMemo(
        () =>
            ORDER_STATES_FOR_FILTER.map((status) => ({
                value: status,
                title: ORDERS_STATE_NAMES[status],
            })),
        []
    )

    return (
        <Autocomplete value={status} onChange={onChange} label="Статус" placeholder="Все статусы" options={options} />
    )
}

const Filters = () => {
    const { filter, changeFilter, resetFilter, isEmpty } = useFilter()
    const hasFilter = useMemo(
        () => Object.entries(filter).some(([key, value]) => key !== 'number' && value !== undefined),
        [filter]
    )
    const { opened, toggle } = useToggle(hasFilter)

    const handleChangeFilter = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => changeFilter({ [e.target.name]: e.target.value }),
        [changeFilter]
    )
    const handleChangeStatusFilter = useCallback(
        (status: string | undefined) => changeFilter({ status }),
        [changeFilter]
    )
    const handleChangeCityFilter = useCallback((cityId: string | undefined) => changeFilter({ cityId }), [changeFilter])
    const handleChangeCreatedIntervalFilter = useCallback(
        (createdInterval?: [Date, Date] | undefined) => changeFilter({ createdInterval }),
        [changeFilter]
    )

    return (
        <Box>
            <Box display="flex" alignItems="center">
                <Input
                    name="number"
                    value={filter.number}
                    onChange={handleChangeFilter}
                    width={250}
                    label="Номер заказа"
                    placeholder="1234567"
                    cleanable
                />
                <IconButton sx={{ ml: 1 }} onClick={toggle}>
                    <FilterListIcon
                        sx={(theme) => ({
                            fill: hasFilter ? theme.palette.primary.main : theme.palette.secondary.main,
                        })}
                    />
                </IconButton>
            </Box>
            {opened && (
                <Grid container mt={1} columnSpacing={2} rowSpacing={2} alignItems="center">
                    <Grid item>
                        <PhoneInput
                            name="customerPhone"
                            value={filter.customerPhone}
                            onChange={handleChangeFilter}
                            label="Телефон клиента"
                            cleanable
                        />
                    </Grid>
                    <Grid item>
                        <PhoneInput
                            name="workerPhone"
                            value={filter.workerPhone}
                            onChange={handleChangeFilter}
                            label="Телефон мастера"
                            cleanable
                        />
                    </Grid>
                    <Grid item>
                        <StatusFilter status={filter.status} onChange={handleChangeStatusFilter} />
                    </Grid>
                    <Grid item>
                        <CityFilter cityId={filter.cityId} onChange={handleChangeCityFilter} />
                    </Grid>
                    <Grid item>
                        <DateRangeFilter
                            dates={filter.createdInterval}
                            onChange={handleChangeCreatedIntervalFilter}
                            label="Дата создания"
                            placeholder="Все даты"
                        />
                    </Grid>
                    {!isEmpty && (
                        <Grid item>
                            <IconButton onClick={resetFilter} size="medium">
                                <ClearIcon fontSize="medium" />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            )}
        </Box>
    )
}

export default Filters
