import { Box, IconButton } from '@mui/material'
import { Chat as PubnubChat, MessageList } from '@pubnub/react-chat-components'
import Modal from 'app/components/Modal'
import { useToggle } from 'hooks'
import PubNub from 'pubnub'
import { PubNubProvider } from 'pubnub-react'
import React, { useMemo } from 'react'

import css from './Chat.module.css'
import ChatIcon from './ChatIcon'

const pubnub = new PubNub({
    subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY ?? '',
    userId: 'adminka_user',
})

type OrderResponseChat = {
    title: string | React.ReactElement
    channel: string
    users: Array<{
        id: string
        name: string
        avatarUrl?: string | null
    }>
}

function Chat({ title, channel, users }: OrderResponseChat) {
    const { opened, open, close } = useToggle()

    const pubnubUsers = useMemo(
        () =>
            users.map((u) => ({
                id: u.id,
                name: u.name,
                eTag: u.id,
                updated: '',
                profileUrl: u.avatarUrl ?? undefined,
            })),
        [users]
    )

    return (
        <>
            <IconButton
                sx={{
                    height: '3rem',
                    width: '3rem',
                }}
                onClick={open}
            >
                <ChatIcon />
            </IconButton>

            <Modal title={title} opened={opened} onClose={close}>
                <Box width={500} height={600} className={css.root}>
                    <PubNubProvider client={pubnub}>
                        <PubnubChat users={pubnubUsers} currentChannel={channel}>
                            <MessageList
                                fetchMessages={25}
                                // Не показываем системные сообщения, так как они бывает дублируются для клиента и мастера, но разными текстами,
                                // поэтому чтобы тут не было каши из системных сообщений мы их просто не покаываем
                                messageRenderer={() => <></>}
                                filter={(data) => data.meta?.appearance === 'system'}
                            />
                        </PubnubChat>
                    </PubNubProvider>
                </Box>
            </Modal>
        </>
    )
}

export default Chat
