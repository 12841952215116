import { Box } from '@mui/material'
import Accordion from 'app/components/Accordion'
import DottedLines from 'app/components/DottedLines'
import { TitleBlock } from 'app/components/TitleBlock'
import React from 'react'

type WorkAccordionItem = {
    [key: string]: {
        name: string
        data: {
            name: string
            price?: string
        }[]
    }
}

type WorkItem = {
    categoryId: string
    categoryIdName: string
    price: {
        currencySymbol: string
        value?: string
    }
    value: null
    priceUnit: string
    workId: string
    workName: string
}

const getMasterPriceListAccordionData = (priceList: WorkItem[]) => {
    const data: WorkAccordionItem = {}
    priceList.forEach((item: WorkItem) => {
        const workItem = {
            name: item.workName,
            price:
                item.price.currencySymbol && item.price.value && `от ${item.price.value} ${item.price.currencySymbol}`,
        }

        if (data[item.categoryId]) {
            data[item.categoryId].data.push(workItem)
        } else {
            data[item.categoryId] = {
                name: item.categoryIdName,
                data: [workItem],
            }
        }
    })
    return data
}

type WorkerPriceListProps = {
    works: WorkItem[]
}

function WorkerPriceList({ works }: WorkerPriceListProps) {
    const priceListTableData = getMasterPriceListAccordionData(works)

    return (
        <Box>
            <TitleBlock title="Услуги и цены" variant="h5" px={0} mb={2} />
            {Object.values(priceListTableData).map((item, index) => (
                <Accordion
                    title={item.name}
                    data={<DottedLines data={item.data.map((d) => ({ title: d.name, value: d.price ?? '–' }))} />}
                    key={index}
                    count={item.data.length}
                />
            ))}
        </Box>
    )
}

export default WorkerPriceList
