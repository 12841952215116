import { Box, Typography } from '@mui/material'
import React from 'react'

type Price = {
    value: number
    currencySymbol: string
}

type OrderPriceProps = {
    totalPrice: Price
    customerPrice?: Price
}

function OrderPrice({ totalPrice, customerPrice }: OrderPriceProps) {
    return (
        <Box display="flex" flexDirection="column">
            <Typography variant="subtitle1">{`${totalPrice.value} ${totalPrice.currencySymbol} (мастер)`}</Typography>
            <Typography variant="subtitle1">
                {customerPrice
                    ? `${customerPrice.value} ${customerPrice.currencySymbol} (клиент)`
                    : 'не указана (клиент)'}
            </Typography>
        </Box>
    )
}

export default OrderPrice
