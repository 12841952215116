import { Clear as ClearIcon } from '@mui/icons-material'
import { IconButton, TextField } from '@mui/material'
import { useForwardRef } from 'hooks'
import React, { forwardRef, useCallback } from 'react'

export type InputProps = {
    id?: string
    value?: string
    label?: string
    name?: string
    type?: 'text' | 'tel' | 'password' | 'email'
    placeholder?: string
    required?: boolean
    disabled?: boolean
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    onClick?: React.MouseEventHandler<HTMLInputElement>
    onFocus?: React.FocusEventHandler<HTMLInputElement>
    onBlur?: React.FocusEventHandler<HTMLInputElement>
    onMouseDown?: React.MouseEventHandler<HTMLInputElement>
    onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>
    cleanable?: boolean
    onClear?: () => void
    className?: string
    startAdornment?: React.ReactNode
    endAdornment?: React.ReactNode
    width?: number
    maxWidth?: number
    minWidth?: number
    error?: boolean
}

function Input(
    {
        id,
        value = '',
        onChange,
        label,
        placeholder,
        name,
        disabled,
        type = 'text',
        onClick,
        onFocus,
        onBlur,
        cleanable,
        onKeyUp,
        onClear,
        onMouseDown,
        className,
        startAdornment,
        endAdornment,
        width,
        maxWidth,
        minWidth = 200,
        error,
    }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement>
) {
    const hiddenLabel = !label
    const paddingBottom = hiddenLabel ? 2.125 : 1.25
    const paddingTop = hiddenLabel ? 2.25 : 3.125
    const inputRef = useForwardRef<HTMLInputElement>(ref)
    const hasClearButton = cleanable || Boolean(onClear)
    const showClearButton = hasClearButton && Boolean(value)

    const handleClear = useCallback(
        (event: React.MouseEvent) => {
            event.stopPropagation()

            if (onClear) {
                onClear()
                return
            }

            if (inputRef.current) {
                Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set?.call(
                    inputRef.current,
                    ''
                )
                inputRef.current.dispatchEvent(new Event('change', { bubbles: true }))
            }
        },
        [onClear, onChange]
    )

    const end = [
        hasClearButton && (
            <IconButton onClick={handleClear} size="small" sx={{ visibility: showClearButton ? 'visible' : 'hidden' }}>
                <ClearIcon fontSize="small" />
            </IconButton>
        ),
        endAdornment,
    ].filter(Boolean)

    return (
        <TextField
            id={id}
            inputRef={inputRef}
            fullWidth
            placeholder={placeholder}
            variant="filled"
            name={name}
            value={value}
            type={type}
            label={label}
            disabled={disabled}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onMouseDown={onMouseDown}
            onKeyUp={onKeyUp}
            className={className}
            InputProps={{
                disableUnderline: true,
                startAdornment,
                endAdornment: end,
            }}
            error={error}
            onClick={onClick}
            hiddenLabel={hiddenLabel}
            sx={(theme) => ({
                '&.MuiTextField-root': {
                    width,
                    maxWidth,
                    minWidth,
                },
                '& .MuiInputLabel-root': {
                    color: theme.palette.secondary.main,
                    transform: 'translate(12px, 18px) scale(1)',
                    '&.Mui-focused': {
                        transform: 'translate(12px, 7px) scale(0.75)',
                    },
                    '&.MuiFormLabel-filled': {
                        transform: 'translate(12px, 7px) scale(0.75)',
                    },
                    '&.MuiInputLabel-shrink': {
                        transform: 'translate(12px, 7px) scale(0.75)',
                    },
                },
                '& .MuiFilledInput-root': {
                    flexWrap: 'wrap',
                    backgroundColor: theme.palette.background.default,
                    borderRadius: 2,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: '#eae9e8',
                    transition: 'border-color ease 0.3s',
                    '&:hover': {
                        filter: 'brightness(99%)',
                        backgroundColor: theme.palette.background.default,
                    },
                    '&.Mui-focused': {
                        borderColor: theme.palette.primary.main,
                    },
                },
                '& .MuiFilledInput-input': {
                    pt: paddingTop,
                    pb: paddingBottom,
                    flexGrow: 1,
                    width: 0,
                    minWidth: 30,
                },
            })}
        />
    )
}
export default forwardRef(Input)
