import { useCities } from 'hooks'
import React from 'react'

type CityNameProps = {
    cityId: string
}

function CityName({ cityId }: CityNameProps) {
    const { cityById } = useCities()
    return <>{cityById[cityId]?.name ?? '-'}</>
}

export default CityName
