import { Box, Grid, Paper } from '@mui/material'
import { PageLoader } from 'app/components/Loaders'
import { useWorker } from 'app/modules/Worker'
import { useRouter } from 'hooks'
import React from 'react'

import BlockingToggler from './BlockingToggler'
import useWorkerReviews from './useWorkerReviews'
import WorkerBlockingInfo from './WorkerBlockingInfo'
import WorkerOrders from './WorkerOrders'
import WorkerPortfolio from './WorkerPortfolio'
import WorkerPriceList from './WorkerPriceList'
import WorkerProfile from './WorkerProfile'
import WorkerResponses from './WorkerResponses'
import WorkerReviews from './WorkerReviews'
import WorkerTariff from './WorkerTariff'

type BlockProps = {
    children: React.ReactNode
}
const Block = ({ children }: BlockProps) => (
    <Grid item xs={12}>
        <Paper
            sx={{
                bgcolor: 'white',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                p: 4,
                position: 'relative',
            }}
            elevation={0}
        >
            {children}
        </Paper>
    </Grid>
)

type WorkerPageLayoutProps = {
    profile: React.ReactNode
    blockingToggler: React.ReactNode
    blockingInfo?: React.ReactNode
    orders: React.ReactNode
    responses: React.ReactNode
    tariff: React.ReactNode
    reviews: React.ReactNode
    priceList?: React.ReactNode
    portfolio?: React.ReactNode
}
const WorkerPageLayout = ({
    profile,
    blockingToggler,
    blockingInfo,
    orders,
    responses,
    tariff,
    reviews,
    priceList,
    portfolio,
}: WorkerPageLayoutProps) => {
    return (
        <Grid container rowSpacing={1.5}>
            <Block>
                {profile}
                <Box position="absolute" top={16} right={16}>
                    {blockingToggler}
                </Box>
            </Block>
            {blockingInfo && <Block>{blockingInfo}</Block>}
            <Block>{tariff}</Block>
            <Block>{responses}</Block>
            <Block>{orders}</Block>
            {priceList && <Block>{priceList}</Block>}
            {portfolio && <Block>{portfolio}</Block>}
            {reviews && <Block>{reviews}</Block>}
        </Grid>
    )
}

function WorkerPage() {
    const workerData = useWorker()
    const reivewsData = useWorkerReviews()
    const router = useRouter()

    if (!workerData.loaded || !workerData.worker || !reivewsData.loaded) {
        return <PageLoader />
    }

    if (workerData.error) {
        return <PageLoader error={workerData.error} />
    }

    const { worker } = workerData
    const onHandleShowAllReviews = () => router.goToWorkerReviewsPage(worker.id)
    const workerPhotoUrls = worker.portfolioPhotos.map((v) => v.url)

    return (
        <WorkerPageLayout
            profile={<WorkerProfile worker={worker} />}
            blockingToggler={<BlockingToggler />}
            blockingInfo={
                workerData.worker.blocking ? <WorkerBlockingInfo blocking={workerData.worker.blocking} /> : undefined
            }
            orders={<WorkerOrders />}
            responses={<WorkerResponses />}
            tariff={<WorkerTariff />}
            priceList={worker.priceList.length > 0 ? <WorkerPriceList works={worker.priceList} /> : undefined}
            portfolio={workerPhotoUrls.length > 0 ? <WorkerPortfolio photoUrls={workerPhotoUrls} /> : undefined}
            reviews={
                reivewsData.reviews.length > 0 ? (
                    <WorkerReviews
                        reviews={reivewsData.reviews}
                        totalCount={reivewsData.totalCount}
                        onShowAllReviews={onHandleShowAllReviews}
                    />
                ) : undefined
            }
        />
    )
}

export default WorkerPage
