import { keyBy } from 'lodash'
import { useMemo } from 'react'
import apiService from 'services/api-service'
import useSWRImmutable from 'swr/immutable'

function useCities() {
    const { data, error } = useSWRImmutable('cities', apiService.getCities)

    const cities = data ?? []
    const cityById = useMemo(() => keyBy(cities, 'id'), [cities])

    return {
        cities,
        cityById,
        loaded: Boolean(data) || Boolean(error),
    }
}

export default useCities
