import { useUrlQuery } from 'hooks'
import { useCallback, useMemo } from 'react'
import { dateHelper } from 'utils'

type Filter = {
    phone?: string
    cityId?: string
    createdInterval?: [Date, Date]
}

type Query = {
    phone?: string
    cityId?: string
    createdInterval?: string
}

function useFilter() {
    const { query, changeQuery } = useUrlQuery<Query>()

    const currentFilter: Filter = useMemo(
        () => ({
            ...query,
            createdInterval:
                query.createdInterval !== undefined ? dateHelper.period.parse(query.createdInterval) : undefined,
        }),
        [query]
    )

    const changeFilter = useCallback(
        (newFilter: Filter) => {
            const nextFilter = { ...currentFilter, ...newFilter }
            const nextQuery = {
                ...nextFilter,
                phone: nextFilter.phone?.trim()?.replace(/\D/g, '') || undefined,
                createdInterval: nextFilter.createdInterval && dateHelper.period.stringify(nextFilter.createdInterval),
            }
            changeQuery(nextQuery)
        },
        [currentFilter, changeQuery]
    )

    const resetFilter = useCallback(() => changeQuery({}), [changeQuery])

    const isEmpty = useMemo(() => Object.values(currentFilter).every((value) => value === undefined), [currentFilter])

    return {
        filter: currentFilter,
        isEmpty,
        changeFilter,
        resetFilter,
    }
}

export default useFilter
