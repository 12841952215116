import { useToast, useToggle } from 'hooks'
import { useCallback, useLayoutEffect, useState } from 'react'

function useSortList<T>(
    initialValues: T[],
    { saveHandler, onSaved }: { saveHandler: (values: T[]) => Promise<void>; onSaved: () => void }
) {
    const [values, setValues] = useState(initialValues)
    const { opened: sortEnabled, open: startSort, close: finishSort } = useToggle()
    const [sorting, setSorting] = useState(false)
    const toast = useToast()

    useLayoutEffect(() => {
        setValues(initialValues)
    }, [initialValues])

    const changeSort = useCallback((dragIndex: number, hoverIndex: number) => {
        setValues((prevValues) => {
            const values = [...prevValues]
            values.splice(dragIndex, 1)
            values.splice(hoverIndex, 0, prevValues[dragIndex])
            return values
        })
    }, [])

    const saveSort = useCallback(async () => {
        try {
            setSorting(true)
            await saveHandler(values)
            finishSort()
            onSaved()
        } catch (error: any) {
            toast.showError(error.message)
        } finally {
            setSorting(false)
        }
    }, [values, saveHandler, finishSort, setSorting, onSaved, toast])

    const cancelSort = useCallback(() => {
        setValues(initialValues)
        finishSort()
    }, [initialValues, setValues, finishSort])

    return { sortedValues: values, startSort, sortEnabled, changeSort, saveSort, cancelSort, sorting }
}

export default useSortList
