import { useRouteParams } from 'hooks'
import { useEffect, useState } from 'react'
import apiService, { GetWorkerReviews } from 'services/api-service'

const useWorkerReviews = () => {
    const { workerId = '' } = useRouteParams()
    const [data, setData] = useState<{
        data?: GetWorkerReviews
        loaded?: boolean
        error?: string
    }>({})

    useEffect(() => {
        apiService
            .getWorkerReviews({ workerId, limit: 3 })
            .then((data) => {
                setData({ data, loaded: true })
            })
            .catch((error) => setData({ loaded: true, error: error.data.message }))
    }, [workerId])

    return {
        reviews: data.data?.items ?? [],
        totalCount: data.data?.totalCount ?? 0,
        loaded: data.loaded,
        error: data.error,
    }
}

export default useWorkerReviews
