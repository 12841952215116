import { Box, Paper, Typography } from '@mui/material'
import CityName from 'app/components/CityName'
import Profile from 'app/components/Profile'
import Table from 'app/components/Table'
import { TitleBlock } from 'app/components/TitleBlock'
import { useRouter } from 'hooks'
import React, { useCallback } from 'react'
import { TTableRowData } from 'types/ITable'
import { dateHelper, formatPhone } from 'utils'

import Filters from './Filters'
import useBlockedWorkers, { BlockedWorker } from './useBlockedWorkers'

const columns = renderColumns([
    renderNameColumn,
    renderPhoneColumn,
    renderCityColumn,
    renderCreatedAtColumn,
    renderBlockingColumn,
])

const BlockedWorkersPage: React.FC = () => {
    const { data, loading, changeLimit, changeSort, changePage } = useBlockedWorkers()

    const router = useRouter()
    const handleClickByRow = useCallback((worker: BlockedWorker) => router.goToWorkerPage(worker.id), [router])

    return (
        <Paper
            sx={{
                bgcolor: 'white',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                py: 4,
            }}
            elevation={0}
        >
            <TitleBlock px={4} title="Заблокированные мастера" count={data.totalCount} />
            <Box px={4} mt={4}>
                <Filters />
            </Box>
            <Box mt={4}>
                <Table
                    items={data.workers}
                    rows={columns}
                    order={data.sort}
                    pagination={data.pagination}
                    isLoading={loading}
                    handleOrderChange={changeSort}
                    handleLimitChange={changeLimit}
                    handlePageChange={changePage}
                    handleClickRow={handleClickByRow}
                />
            </Box>
        </Paper>
    )
}

function renderNameColumn(xs: number) {
    return {
        title: 'Имя',
        name: 'name',
        isSort: true,
        xs,
        element: ({ firstname, lastname, avatarUrl }: BlockedWorker) => (
            <Profile photoUrl={avatarUrl} firstName={firstname} lastName={lastname} />
        ),
    }
}

function renderPhoneColumn(xs: number) {
    return {
        title: 'Телефон',
        name: 'phone',
        xs,
        element: ({ phone }: BlockedWorker) => (
            <>
                <Typography variant="body2" color="grey.600">
                    {formatPhone(phone)}
                </Typography>
            </>
        ),
    }
}

function renderCityColumn(xs: number) {
    return {
        title: 'Город',
        name: 'cityName',
        xs,
        element: ({ cityId }: BlockedWorker) => (
            <>
                <Typography variant="body2" color="grey.600">
                    {cityId ? <CityName cityId={cityId} /> : '-'}
                </Typography>
            </>
        ),
    }
}

function renderCreatedAtColumn(xs: number) {
    return {
        title: 'Дата регистрации',
        name: 'createdAt',
        isSort: true,
        xs,
        element: ({ createdAt }: BlockedWorker) => (
            <Typography variant="body2" color="grey.600">
                {dateHelper.formatDate(createdAt)}
            </Typography>
        ),
    }
}

function renderBlockingColumn(xs: number) {
    return {
        title: 'Блокировка',
        name: 'blocking',
        xs,
        element: ({ blocking }: BlockedWorker) => (
            <Box>
                <Typography variant="body2" color="grey.600">
                    {blocking?.reasonTitle}
                </Typography>
                <Typography variant="body2" color="grey.600">
                    {dateHelper.formatDate(blocking.blockedAt)}
                </Typography>
            </Box>
        ),
    }
}

function renderColumns(renders: Array<(xs: number) => TTableRowData>): TTableRowData[] {
    if (renders.length === 0) {
        return []
    }

    const maxColumnsCount = 12
    const maxColumnSize = 2.5
    const columnSize = Math.min(maxColumnsCount / renders.length, maxColumnSize)
    return renders.map((r) => r(columnSize))
}

export default BlockedWorkersPage
