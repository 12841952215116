import axios, { AxiosRequestConfig } from 'axios'
import { stringify } from 'qs'
import { dateHelper } from 'utils'

import * as t from './types'

type Callback = () => void

const authTokenStorage = {
    key: 'dbz_token',
    save(token: string) {
        localStorage.setItem(this.key, token)
    },
    get(): string | null {
        return localStorage.getItem(this.key) ?? null
    },
    clear() {
        localStorage.removeItem(this.key)
    },
}

const unauthorizedSubscribersStorage = {
    subscribers: [] as Callback[],
    add(cb: Callback) {
        this.subscribers.push(cb)
        return () => {
            this.subscribers = this.subscribers.filter((s) => s !== cb)
        }
    },
    notify() {
        this.subscribers.map((cb) => cb())
    },
}

const request = async <T = any>(path: string, config?: AxiosRequestConfig): Promise<T> => {
    const host = process.env.REACT_APP_API_HOST
    return axios(`${host}${path}`, {
        ...config,
        headers: {
            ...config?.headers,
            Authorization: `Bearer ${authTokenStorage.get()}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...config?.headers,
        },
        paramsSerializer: (params) => {
            return stringify(params, { arrayFormat: 'brackets' })
        },
    })
        .then(async (response) => response.data.data)
        .catch((error) => {
            if (error.response.status === 401) {
                unauthorizedSubscribersStorage.notify()
            }

            throw error.response.data
        })
}

const apiService = {
    async login(data: t.LoginRequest): Promise<t.LoginResponse> {
        const { token } = await request('/auth', { method: 'POST', data })
        authTokenStorage.save(token)
        return {
            token,
        }
    },
    async getCurrentUser(): Promise<t.GetCurrentUserResponse> {
        return request('/auth/user_info')
    },
    async logout() {
        authTokenStorage.clear()
    },
    async getCities(): Promise<t.GetCitiesResponse> {
        return request('/cities')
    },
    async getWorkers(data: t.GetWorkersRequest): Promise<t.GetWorkersResponse> {
        const params: any = {}
        if (data.limit) {
            params.limit = data.limit
        }
        if (data.page) {
            params.page = data.page
        }
        if (data.sort?.row === 'name') {
            params.sortByName = data.sort.order
        }
        if (data.sort?.row === 'createdAt') {
            params.sortByCreatedAt = data.sort.order
        }
        if (data.filter?.phone) {
            params.phone = data.filter.phone
        }
        if (data.filter?.cityId) {
            params.cityIds = [data.filter.cityId]
        }
        if (data.filter?.createdInterval?.[0]) {
            params.createdFrom = data.filter.createdInterval?.[0].toISOString()
        }
        if (data.filter?.createdInterval?.[1]) {
            params.createdTo = data.filter.createdInterval?.[1].toISOString()
        }
        if (data.blocked) {
            params.blocked = '1'
        }
        return request('/workers', { params })
    },
    async getWorker(data: { workerId: string }): Promise<t.GetWorkerResponse> {
        return request(`/workers/${data.workerId}/profile`)
    },
    async getWorkerReviews(data: { workerId: string; limit?: number }): Promise<t.GetWorkerReviews> {
        const params: any = {}
        if (data.limit) {
            params.limit = data.limit
        }
        return request(`/workers/${data.workerId}/reviews`, { params })
    },
    async getWorkerBlockingReasons(): Promise<t.GetWorkerBlockingReasonsResponse> {
        return request('/workers/block_reasons')
    },
    async blockWorker(data: { workerId: string; reason: { type: number; comment?: string } }): Promise<boolean> {
        return request(`/workers/${data.workerId}/block`, {
            method: 'POST',
            data: {
                reason: data.reason,
            },
        })
    },
    async unblockWorker(data: { workerId: string }): Promise<boolean> {
        return request(`/workers/${data.workerId}/unblock`, { method: 'POST' })
    },
    async getOrders(data: t.GetOrdersRequest): Promise<t.GetOrdersResponse> {
        const params: any = {}
        if (data.limit) {
            params.limit = data.limit
        }
        if (data.page) {
            params.page = data.page
        }
        if (data.sort?.row === 'publishedAt') {
            params.sortByPublishedAt = data.sort.order
        }
        if (data.filter?.number) {
            params.number = data.filter.number
        }
        if (data.filter?.customerId) {
            params.customerId = data.filter.customerId
        }
        if (data.filter?.customerPhone) {
            params.customerPhone = data.filter.customerPhone
        }
        if (data.filter?.workerId) {
            params.workerId = data.filter.workerId
        }
        if (data.filter?.workerPhone) {
            params.workerPhone = data.filter.workerPhone
        }
        if (data.filter?.status) {
            params.states = [data.filter.status]
        }
        if (data.filter?.cityId) {
            params.cityIds = [data.filter.cityId]
        }
        if (data.filter?.createdInterval?.[0]) {
            params.publishedFrom = data.filter.createdInterval?.[0].toISOString()
        }
        if (data.filter?.createdInterval?.[1]) {
            params.publishedTo = data.filter.createdInterval?.[1].toISOString()
        }
        return request('/orders', { params })
    },
    async getOrder(orderId: string): Promise<t.GetOrderResponse> {
        return request(`/orders/${orderId}`)
    },
    async getWorkerResponses({
        workerId,
        page,
        limit,
    }: t.GetWorkerResponsesRequest): Promise<t.GetWorkerResponsesResponse> {
        return request<t.GetWorkerResponsesResponse>(`/workers/${workerId}/responses`, {
            params: {
                page,
                limit,
            },
        })
    },
    async getOrderResponses({
        orderId,
        page,
        limit,
    }: t.GetOrderResponsesRequest): Promise<t.GetOrderResponsesResponse> {
        return request<t.GetOrderResponsesResponse>(`/orders/${orderId}/responses`, { params: { page, limit } })
    },
    async getOrderWorkerViewsDetails({
        orderId,
    }: t.GetOrderViewsDetailsRequest): Promise<t.GetOrderViewsDetailsResponse> {
        return request<t.GetOrderViewsDetailsResponse>(`/orders/${orderId}/worker-views`)
    },
    async getOrderCancellationReasons(orderId: string): Promise<t.GetOrderCancellationReasonsResponse> {
        return request(`/orders/${orderId}/cancellation_reasons`)
    },
    async cancelOrder(data: t.GetOrderCancellationReasonsRequest): Promise<void> {
        await request(`/orders/${data.orderId}/cancel`, {
            method: 'POST',
            data: {
                reason: {
                    type: data.type,
                    comment: data.comment?.trim() || undefined,
                },
            },
        })
    },
    async createEmployee(data: t.CreateEmployeeRequest): Promise<void> {
        return request('/employees', {
            method: 'POST',
            data,
        })
    },
    async changeEmployee(employeeId: string, data: t.ChangeEmployeeRequest): Promise<void> {
        return request(`/employees/${employeeId}`, {
            method: 'PATCH',
            data,
        })
    },
    async removeEmployee(data: { employeeId: string }) {
        await request(`/employees/${data.employeeId}`, {
            method: 'DELETE',
        })
    },
    async getEmployees(data: t.GetEmployeesRequest): Promise<t.GetEmployeesResponse> {
        const params: any = {}
        if (data.limit) {
            params.limit = data.limit
        }
        if (data.page) {
            params.page = data.page
        }
        if (data.sort?.row === 'name') {
            params.sortByName = data.sort.order
        }
        if (data.sort?.row === 'createdAt') {
            params.sortByCreatedAt = data.sort.order
        }
        if (data.filter?.phone) {
            params.phone = data.filter.phone
        }
        if (data.filter?.cityId) {
            params.cityIds = [data.filter.cityId]
        }
        return request('/employees', { params })
    },
    async getEmployee(data: { employeeId: string }): Promise<t.GetEmployeeResponse> {
        return request(`/employees/${data.employeeId}`)
    },
    async getCustomers(data: t.GetCustomersRequest): Promise<t.GetCustomersResponse> {
        const params: any = {}
        if (data.limit) {
            params.limit = data.limit
        }
        if (data.page) {
            params.page = data.page
        }
        if (data.sort?.row === 'name') {
            params.sortByName = data.sort.order
        }
        if (data.sort?.row === 'createdAt') {
            params.sortByCreatedAt = data.sort.order
        }
        if (data.filter?.phone) {
            params.phone = data.filter.phone
        }
        if (data.filter?.cityId) {
            params.cityIds = [data.filter.cityId]
        }
        if (data.filter?.createdInterval?.[0]) {
            params.createdFrom = data.filter.createdInterval?.[0].toISOString()
        }
        if (data.filter?.createdInterval?.[1]) {
            params.createdTo = data.filter.createdInterval?.[1].toISOString()
        }
        if (data.filter?.status) {
            params.status = data.filter.status
        }
        if (data.blocked) {
            params.blocked = '1'
        }
        return request('/customers', { params })
    },
    async getCustomer(data: { customerId: string }): Promise<t.GetCustomerResponse> {
        return request(`/customers/${data.customerId}/profile`)
    },
    async getCustomerBlockingReasons(): Promise<t.GetCustomerBlockingReasonsResponse> {
        return request('/customers/block_reasons')
    },
    async blockCustomer(data: { customerId: string; reason: { type: number; comment?: string } }): Promise<boolean> {
        return request(`/customers/${data.customerId}/block`, {
            method: 'POST',
            data: {
                reason: data.reason,
            },
        })
    },
    async unblockCustomer(data: { customerId: string }): Promise<boolean> {
        return request(`/customers/${data.customerId}/unblock`, { method: 'POST' })
    },
    async getCustomerReviews(data: { customerId: string; limit?: number }): Promise<t.GetCustomerReviews> {
        const params: any = {}
        if (data.limit) {
            params.limit = data.limit
        }
        return request(`/customers/${data.customerId}/reviews`, { params })
    },
    async getWorkCategories(): Promise<t.GetWorkCategoriesResponse> {
        return request('/work_categories')
    },
    async updateWorkCategoryPositions({ sortedCategoryIds }: t.UpdateWorkCategoryPositionRequest) {
        const data = {
            items: sortedCategoryIds.map((categoryId, position) => ({ categoryId, position })),
        }
        await request('/work_categories/positions', { method: 'PATCH', data })
    },
    async updateWorkPositions({ sortedWorkIds }: t.UpdateWorkPositionRequest) {
        const data = {
            items: sortedWorkIds.map((workId, position) => ({ workId, position })),
        }
        await request('/works/positions', { method: 'PATCH', data })
    },
    async createWorkCategory(data: t.CreateWorkCategoryRequest) {
        await request('/work_categories', { method: 'POST', data })
    },
    async changeWorkCategory(categoryId: string, data: t.ChangeWorkCategoryRequest) {
        await request(`/work_categories/${categoryId}`, { method: 'PATCH', data })
    },
    async removeWorkCategory(categoryId: string) {
        await request(`/work_categories/${categoryId}`, { method: 'DELETE' })
    },
    async uploadWorkCategoryIcon(iconData: File): Promise<t.UploadWorkCategoryIconResponse> {
        const data = new FormData()
        data.append('file', iconData)
        return request('/work_categories/icon', { method: 'POST', data })
    },
    async createWork(data: t.CreateWorkRequest) {
        await request('/works', { method: 'POST', data })
    },
    async changeWork(workId: string, data: t.ChangeWorkRequest) {
        await request(`/works/${workId}`, { method: 'PATCH', data })
    },
    async removeWork(workId: string) {
        await request(`/works/${workId}`, { method: 'DELETE' })
    },
    async getTariffPurchasesAnalytics(
        data: t.GetTariffPurchasesAnalyticsRequest
    ): Promise<t.GetTariffPurchasesAnalyticsResponse> {
        const params: any = {}
        if (data.limit) {
            params.limit = data.limit
        }
        if (data.page) {
            params.page = data.page
        }
        if (data.sort?.row === 'workerName') {
            params.sortByWorkerName = data.sort.order
        }
        if (data.sort?.row === 'purchaseSum') {
            params.sortByPurchaseSum = data.sort.order
        }
        if (data.sort?.row === 'purchasedAt') {
            params.sortByPurchasedAt = data.sort.order
        }
        if (data.filter?.dateInterval?.[0]) {
            params.dateFrom = data.filter.dateInterval?.[0].toISOString()
        }
        if (data.filter?.dateInterval?.[1]) {
            params.dateTo = data.filter.dateInterval?.[1].toISOString()
        }
        if (data.filter?.cityId) {
            params.cityIds = [data.filter.cityId]
        }
        return request('/analytics/tariff_purchases', { params })
    },
    async activateWorkerTariff(data: t.ActivateWorkerTariffRequest): Promise<void> {
        await request(`/worker-tariff/activate`, {
            method: 'POST',
            params: {
                workerId: data.workerId,
            },
            data: {
                responsesCount: data.responsesCount,
                expirationPeriodDays: data.expirationPeriodDays,
            },
        })
    },
    async deactivateWorkerTariff(workerId: string): Promise<void> {
        await request(`/worker-tariff/deactivate`, {
            method: 'POST',
            params: { workerId },
        })
    },
    async getCurrentWorkerTariff(workerId: string): Promise<t.GetCurrentWorkerTariffResponse> {
        const tariff = await request(`/worker-tariff/current`, { method: 'GET', params: { workerId } })
        if (!tariff) {
            return null
        }

        return {
            id: tariff.id,
            typeText: tariff.typeText,
            availableResponsesCount: tariff.availableResponsesCount,
            totalResponsesCount: tariff.totalResponsesCount,
            purchasePrice: {
                value: tariff.purchasePrice.value,
                currencySymbol: tariff.purchasePrice.currencySymbol,
            },
            activatedAt: dateHelper.from(tariff.activatedAt),
            expiresAt: dateHelper.from(tariff.expiresAt),
        }
    },
    async getWorkerTariffsHistory(workerId: string): Promise<t.GetWorkerTariffHistoryResponse> {
        const resp = await request(`/worker-tariff/history`, { method: 'GET', params: { workerId } })
        return resp.items.map((t: any) => ({
            id: t.id,
            typeText: t.typeText,
            responsesCount: t.responsesCount,
            purchasePrice: {
                value: t.purchasePrice.value,
                currencySymbol: t.purchasePrice.currencySymbol,
            },
            activatedAt: dateHelper.from(t.activatedAt),
            finishedAt: dateHelper.from(t.finishedAt),
        }))
    },
    onUnathorized(cb: Callback) {
        return unauthorizedSubscribersStorage.add(cb)
    },
}

export default apiService
