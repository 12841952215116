import { useRouteParams } from 'hooks'
import { useCallback, useEffect, useState } from 'react'
import apiService from 'services/api-service'

type Tariff = {
    id: string
    typeText: string
    responsesCount: number
    purchasePrice: {
        value: number
        currencySymbol: string
    }
    activatedAt: Date
    finishedAt: Date
}

const useWorkerTariffsHistory = () => {
    const { workerId = '' } = useRouteParams()
    const [data, setData] = useState<{
        tariffs: Tariff[]
        loaded: boolean
        error?: string
    }>({ tariffs: [], loaded: false })

    const loadTariffs = useCallback(async () => {
        try {
            const tariffs = await apiService.getWorkerTariffsHistory(workerId)
            setData({ loaded: true, tariffs, error: undefined })
        } catch (error: any) {
            setData({ loaded: true, error: error.message, tariffs: [] })
        }
    }, [workerId])

    useEffect(() => {
        loadTariffs()
    }, [loadTariffs])

    return {
        tariffs: data.tariffs,
        loaded: data.loaded,
        error: data.error,
    }
}

export default useWorkerTariffsHistory
