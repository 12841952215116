import { Box } from '@mui/material'
import { ReactComponent as LogoSVG } from 'assets/Logo.svg'
import React from 'react'

export const WhiteLogo: React.FC = () => {
    return (
        <Box p="16px 24px 14px">
            <LogoSVG />
        </Box>
    )
}
