import { useRef } from 'react'

function useForwardRef<T extends HTMLElement>(inputRef: React.Ref<T> = null) {
    const ref = useRef<T>(null)

    if (inputRef === null) {
        return ref
    }

    if (typeof inputRef === 'function') {
        inputRef(ref.current)
        return ref
    }

    return inputRef
}

export default useForwardRef
