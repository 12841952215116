import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { MoreButton } from 'app/components/Button'
import Table from 'app/components/Table'
import { TitleBlock } from 'app/components/TitleBlock'
import { ReactComponent as EditIconSVG } from 'assets/icons/edit_icon.svg'
import React, { useCallback, useMemo } from 'react'
import apiService from 'services/api-service'
import { TTableRowData } from 'types/ITable'

import { WorkCategory } from './types'
import useSortList from './useSortList'

type WorkCategoriesListProps = {
    categories: WorkCategory[]
    loaded: boolean
    editable: boolean
    onNewCategory: () => void
    onEditCategory: (category: WorkCategory) => void
    onSelectCategory: (category: WorkCategory) => void
    onPositionChanged: () => void
}

function WorkCategoriesList({
    categories: initialCategories,
    loaded,
    onSelectCategory,
    onNewCategory,
    onEditCategory,
    onPositionChanged,
    editable,
}: WorkCategoriesListProps) {
    const {
        sortedValues: categories,
        startSort,
        sortEnabled,
        changeSort,
        saveSort,
        cancelSort,
        sorting,
    } = useSortList(initialCategories, {
        saveHandler: (categories) => {
            const sortedCategoryIds = categories.map((category) => category.id)
            return apiService.updateWorkCategoryPositions({ sortedCategoryIds })
        },
        onSaved: onPositionChanged,
    })

    const showEditIcon = editable && !sortEnabled
    const clickableRow = !sortEnabled
    const showMoreButton = editable && !sortEnabled
    const showSortActions = sortEnabled

    const tableRows: TTableRowData[] = useMemo(
        () => [
            {
                title: 'Категория',
                name: 'name',
                xs: 9,
                element: (category: WorkCategory) => (
                    <>
                        <Box
                            alt={category.name}
                            src={category.iconUrl}
                            component="img"
                            width="28px"
                            height="28px"
                            ml={2}
                        />
                        <Typography variant="body2" fontWeight={600} ml={2}>
                            {category.name}
                        </Typography>
                    </>
                ),
            },
            {
                title: 'Подкатегории',
                name: 'subcategoriesCount',
                xs: 3,
                element: (category: WorkCategory) => (
                    <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                        <Typography variant="body2" color="grey.600">
                            {category.subcategories?.length ?? 0}
                        </Typography>

                        {showEditIcon && (
                            <IconButton
                                onClick={(e) => {
                                    e.preventDefault()
                                    onEditCategory(category)
                                }}
                            >
                                <EditIconSVG />
                            </IconButton>
                        )}
                    </Box>
                ),
            },
        ],
        [onEditCategory, showEditIcon]
    )

    const handleClickRow = useCallback((category: WorkCategory) => onSelectCategory(category), [onSelectCategory])

    const rightElement = (
        <>
            {showMoreButton && (
                <MoreButton
                    options={[
                        { title: 'Создать категорию', onSelect: onNewCategory },
                        { title: 'Изменить порядок', onSelect: startSort },
                    ]}
                    position="right"
                />
            )}
            {showSortActions && (
                <>
                    <Button variant="contained" color="grey" onClick={cancelSort} disabled={sorting}>
                        Отменить
                    </Button>
                    <LoadingButton
                        loading={sorting}
                        variant="contained"
                        color="primary"
                        sx={{
                            ml: 1,
                        }}
                        onClick={saveSort}
                    >
                        Сохранить
                    </LoadingButton>
                </>
            )}
        </>
    )

    return (
        <Box>
            <TitleBlock title="Категории" rightElement={rightElement} />
            <Box mt={4}>
                <Table
                    isLoading={!loaded}
                    items={categories}
                    rows={tableRows}
                    handleClickRow={clickableRow ? handleClickRow : undefined}
                    isDraggable={sortEnabled}
                    onPositionChange={sortEnabled ? changeSort : undefined}
                />
            </Box>
        </Box>
    )
}

export default WorkCategoriesList
