import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Skeleton } from 'app/components/Loaders'
import Table from 'app/components/Table'
import { TitleBlock } from 'app/components/TitleBlock'
import { ORDERS_STATE_NAMES } from 'app/constants'
import { useWorker } from 'app/modules/Worker'
import { useRouter } from 'hooks'
import React from 'react'
import { Order } from 'services/api-service'
import { TTableRowData } from 'types/ITable'
import { dateHelper, formatPhone } from 'utils'

import useWorkerOrders from './useWorkerOrders'

type OrderItem = {
    id: string
    status: string
    number: string
    date: string
    name: string
    category: string
    customer: {
        name: string
        phone: string
    }
}

const MasterOrdersField: TTableRowData[] = [
    {
        name: 'date',
        title: 'Дата',
        xs: 2,
        element: (order: OrderItem) => (
            <Box display={'flex'} flexDirection={'column'}>
                <Typography fontSize={16} variant="subtitle2" color="grey.600">
                    {dateHelper.formatDate(order.date)}
                </Typography>
                <Grid container>
                    <Grid item>
                        <Typography fontSize={13} variant="subtitle2" color="grey.600">
                            #{order.number}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        ),
    },
    {
        name: 'name',
        title: 'Название',
        xs: 6,
        element: (order: OrderItem) => (
            <>
                <Box flexDirection={'column'}>
                    <Typography variant="subtitle2" fontWeight={600}>
                        {order.name}
                    </Typography>
                    <Typography variant="subtitle2" color="grey.600">
                        {order.category}
                    </Typography>
                </Box>
            </>
        ),
    },
    {
        name: 'client',
        title: 'Клиент',
        xs: 2,
        element: (order: OrderItem) => (
            <>
                <Box flexDirection={'column'}>
                    <Typography variant="subtitle2">{order.customer.name}</Typography>
                    <Typography variant="subtitle2" color="grey.600">
                        {formatPhone(order.customer.phone)}
                    </Typography>
                </Box>
            </>
        ),
    },
    {
        name: 'status',
        title: 'Статус',
        xs: 2,
        element: (order: OrderItem) => (
            <>
                <Typography variant="subtitle2" color="grey.600">
                    {order.status}
                </Typography>
            </>
        ),
    },
]

const getMasterOrdersTableData = (orders: Order[]): OrderItem[] => {
    return orders.map((order) => {
        return {
            id: order.id,
            number: order.number,
            date: order.publishedAt,
            name: order.works[0]?.name ?? '-',
            category: order.works[0]?.category?.name ?? '-',
            customer: {
                name: order.customer.firstname,
                phone: order.customer.phone,
            },
            status: ORDERS_STATE_NAMES[order.state],
        }
    })
}

function WorkerOrders() {
    const { orders, totalCount, loaded, error } = useWorkerOrders()
    const { worker } = useWorker()
    const router = useRouter()

    const ordersTableData = getMasterOrdersTableData(orders)

    return (
        <Box>
            <TitleBlock
                title="История заказов"
                variant="h5"
                px={0}
                mb={2}
                count={loaded ? totalCount : undefined}
                rightElement={
                    loaded &&
                    worker && (
                        <Button
                            onClick={() => router.goToOrdersPage({ workerId: worker?.id ?? '', hideFilters: true })}
                            variant="text"
                            color="secondary"
                            endIcon={<ChevronRightIcon />}
                        >
                            Показать все
                        </Button>
                    )
                }
            />

            {!loaded && <Skeleton height={180} />}
            {loaded && error && <Box>{error}</Box>}
            {loaded && !error && orders.length === 0 && <Box>Пока что тут пусто</Box>}
            {loaded && !error && orders.length !== 0 && (
                <Table px={0} items={ordersTableData} rows={MasterOrdersField} hideHeader />
            )}
        </Box>
    )
}

export default WorkerOrders
