import { Box, Typography } from '@mui/material'
import PhotosList from 'app/components/PhotosList'
import { ReactComponent as CheckSVG } from 'assets/icons/Check.svg'
import { ReactComponent as CommentSVG } from 'assets/icons/Comment.svg'
import uniqBy from 'lodash/uniqBy'
import React, { useMemo } from 'react'

type Work = {
    id: string
    name: string
    category: {
        id: string
        name: string
        iconUrl: string
    }
    price: {
        value: number
        currencySymbol: string
    }
    amount: {
        value: number
        unit: string
    }
}

type OrderTaskProps = {
    works: Work[]
    photoUrls: string[]
    comment?: string
}

function OrderTask({ works, photoUrls, comment }: OrderTaskProps) {
    const categories = useMemo(
        () =>
            uniqBy(
                works.map((work) => work.category),
                'id'
            ),
        works
    )

    const worksByCategoryId = useMemo(() => {
        const worksByCategoryId: { [categoryId: string]: Work[] } = {}
        works.forEach((work) => {
            if (!worksByCategoryId[work.category.id]) {
                worksByCategoryId[work.category.id] = []
            }

            worksByCategoryId[work.category.id].push(work)
        })
        return worksByCategoryId
    }, [works])

    return (
        <>
            {categories.map((category) => (
                <Box key={category.id}>
                    <Box display="flex" alignItems="flex-start">
                        <Box pt="7px">
                            <img src={category.iconUrl} alt={category.name} width={30} />
                        </Box>
                        <Box ml={2} flexGrow={1}>
                            <Box
                                sx={(theme) => ({
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                    pb: 1,
                                })}
                            >
                                <Typography variant="subtitle1" fontWeight={500}>
                                    {category.name}
                                </Typography>
                            </Box>
                            {worksByCategoryId[category.id].map((work) => (
                                <Box key={work.id} display="flex" alignItems="center" mt={1}>
                                    <CheckSVG />
                                    <Typography variant="subtitle1" flexGrow={1} ml={0.5}>
                                        {work.name}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        color="secondary.main"
                                        sx={(theme) => ({
                                            borderBottom: `1px solid ${theme.palette.secondary.main}`,
                                            lineHeight: '1.3',
                                        })}
                                    >
                                        {`≈ ${work.price.value} ${work.price.currencySymbol} / ${work.amount.value} ${work.amount.unit}`}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            ))}

            {comment && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mt: 2,
                    }}
                >
                    <Box minWidth="24px" display="flex">
                        <CommentSVG />
                    </Box>
                    <Typography ml={1} variant="subtitle1">
                        {comment}
                    </Typography>
                </Box>
            )}

            {photoUrls.length !== 0 && (
                <Box mt={2}>
                    <PhotosList photoUrls={photoUrls} />
                </Box>
            )}
        </>
    )
}

export default OrderTask
