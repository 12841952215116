import moment from 'moment'

type Format = 'DD.MM.YYYY HH:mm' | 'DD.MM.YYYY' | 'MMMM YYYY' | 'HH:mm'

type Options = {
    format?: Format
}

export const DAYS_IN_WEEK = 7
export const SHORT_WEEK_NAMES = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']

export const formatDate = (date: Date | string, options: Options = {}) => {
    const format = options.format ?? 'DD.MM.YYYY HH:mm'
    return moment(date).format(format)
}

export const from = (value: any) => moment(value).toDate()

export const getToday = () => new Date()

export const addYears = (date: Date, years: number) => moment(date).add(years, 'years').toDate()
export const addMonths = (date: Date, months: number) => moment(date).add(months, 'months').toDate()
export const addWeeks = (date: Date, weeks: number) => moment(date).add(weeks, 'weeks').toDate()
export const addDays = (date: Date, days: number) => moment(date).add(days, 'days').toDate()

export const startOfYear = (date: Date) => moment(date).startOf('years').toDate()
export const endOfYear = (date: Date) => moment(date).endOf('years').toDate()
export const startOfMonth = (date: Date) => moment(date).startOf('months').toDate()
export const endOfMonth = (date: Date) => moment(date).endOf('months').toDate()
export const startOfWeek = (date: Date) => moment(date).startOf('isoWeeks').toDate()
export const endOfWeek = (date: Date) => moment(date).endOf('isoWeeks').toDate()
export const startOfDay = (date: Date) => moment(date).startOf('day').toDate()
export const endOfDay = (date: Date) => moment(date).endOf('day').toDate()

export const getWeekDay = (date: Date, weekStartsOn = 1) => {
    const day = date.getDay()
    return (day - weekStartsOn + DAYS_IN_WEEK) % DAYS_IN_WEEK
}

export const getDaysInMonth = (date: Date) => moment(date).daysInMonth()

export const isEqual = (date1: Date, date2: Date) => moment(date1).isSame(date2)
export const isSameDay = (date1: Date, date2: Date) => moment(date1).isSame(date2, 'days')

export const isBefore = (date1: Date, date2: Date) => moment(date1).isBefore(date2)
export const isAfter = (date1: Date, date2: Date) => moment(date1).isAfter(date2)

export const compareAsc = (date1: Date, date2: Date) => {
    const diff = date1.getTime() - date2.getTime()
    if (diff < 0) {
        return -1
    } else if (diff > 0) {
        return 1
    } else {
        return diff
    }
}

export type DatePeriod = [Date, Date]
type NullableDatePeriod = [Date | null | undefined, Date | null | undefined]
export const period = {
    getDay(shift = 0): DatePeriod {
        const today = getToday()
        const day = addDays(today, shift)
        return [startOfDay(day), endOfDay(day)]
    },
    getToday(): DatePeriod {
        return this.getDay()
    },
    getYesterday(): DatePeriod {
        return this.getDay(-1)
    },
    getWeek(shift = 0): DatePeriod {
        const today = getToday()
        const day = addWeeks(today, shift)
        return [startOfWeek(day), endOfWeek(day)]
    },
    getMonth(shift = 0): DatePeriod {
        const today = getToday()
        const day = addMonths(today, shift)
        return [startOfMonth(day), endOfMonth(day)]
    },
    getYear(shift = 0): DatePeriod {
        const today = getToday()
        const day = addYears(today, shift)
        return [startOfYear(day), endOfYear(day)]
    },
    isEqual([leftStart, leftEnd]: NullableDatePeriod, [rightStart, rightEnd]: NullableDatePeriod): boolean {
        return (
            (leftStart && rightStart ? isEqual(leftStart, rightStart) : leftStart === rightStart) &&
            (leftEnd && rightEnd ? isEqual(leftEnd, rightEnd) : leftEnd === rightEnd)
        )
    },
    isToday(period: NullableDatePeriod): boolean {
        return this.isEqual(period, this.getToday())
    },
    isYesterday(period: NullableDatePeriod): boolean {
        return this.isEqual(period, this.getYesterday())
    },
    isWeek(period: NullableDatePeriod, shift?: number): boolean {
        return this.isEqual(period, this.getWeek(shift))
    },
    isMonth(period: NullableDatePeriod, shift?: number): boolean {
        return this.isEqual(period, this.getMonth(shift))
    },
    isYear(period: NullableDatePeriod, shift?: number): boolean {
        return this.isEqual(period, this.getYear(shift))
    },
    isWithin(date: Date, period: NullableDatePeriod): boolean {
        const [start, end] = period

        if (!start || !end) return false
        if (isAfter(start, end)) return false

        return moment(date).isBetween(start, end)
    },
    parse(period: string): DatePeriod {
        const [start, end] = period.split(':')
        const startDate = moment(start, 'YYYY-MM-DD').toDate()
        const endDate = moment(end, 'YYYY-MM-DD').toDate()
        return [startDate && startOfDay(startDate), endDate && endOfDay(endDate)]
    },
    stringify(period: DatePeriod): string | undefined {
        const stringifiedPeriod = period.map((p) => (p !== null ? moment(p).format('YYYY-MM-DD') : ''))
        return stringifiedPeriod.filter(Boolean).length !== 0 ? stringifiedPeriod.join(':') : undefined
    },
}
