import { LoadingButton } from '@mui/lab'
import { Box, Grid } from '@mui/material'
import { Textarea } from 'app/components/Input'
import { CircleLoader } from 'app/components/Loaders'
import React, { useCallback, useState } from 'react'

import Modal from '../Modal'

type ReasonPickerProps = {
    reasons: {
        type: number
        title: string
    }[]
    reasonsLoaded: boolean
    buttonLoading?: boolean
    title: string
    buttonText: string
    onSelect: (reason: { type: number; comment?: string }) => void
    onClose: () => void
}
function ReasonPicker({
    reasons,
    reasonsLoaded,
    buttonLoading,
    onSelect,
    onClose,
    title,
    buttonText,
}: ReasonPickerProps) {
    const [reasonType, setReasonType] = useState<number>()
    const [reasonComment, setReasonComment] = useState<string>()

    const disabled = reasonType === undefined

    const handleChangeComment = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setReasonComment(e.target.value)
    }, [])

    const handleSelect = useCallback(() => {
        if (reasonType !== undefined) {
            onSelect({ type: reasonType, comment: reasonComment })
        }
    }, [onSelect, reasonType, reasonComment])

    const loaderComponent = (
        <Box
            sx={{
                py: 5,
                px: 8,
            }}
        >
            <CircleLoader />
        </Box>
    )

    const contentComponent = (
        <Box>
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" rowSpacing={1.5}>
                {reasons.map((reason) => (
                    <Grid key={reason.type} item>
                        <Box
                            sx={(theme) => ({
                                borderRadius: 1.2,
                                px: 2,
                                py: 1.7,
                                border: `1px solid ${
                                    reasonType === reason.type ? theme.palette.primary.main : '#eae9e8'
                                }`,
                                backgroundColor: theme.palette.background.default,
                                cursor: 'pointer',
                            })}
                            onClick={() => setReasonType(reason.type)}
                        >
                            {reason.title}
                        </Box>
                    </Grid>
                ))}
            </Grid>

            <Box mt={3}>
                <Textarea
                    placeholder="Комментарий"
                    value={reasonComment}
                    onChange={handleChangeComment}
                    maxRows={4}
                    minRows={4}
                />
            </Box>

            <LoadingButton
                loading={buttonLoading}
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                    height: 50,
                    mt: 5,
                }}
                onClick={handleSelect}
                disabled={disabled}
            >
                {buttonText}
            </LoadingButton>
        </Box>
    )

    return (
        <Modal opened title={title} onClose={onClose}>
            {reasonsLoaded ? contentComponent : loaderComponent}
        </Modal>
    )
}

export default ReasonPicker
