import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Auth from '../modules/Auth/components/Auth'
import CatalogPage from './CatalogPage'
import CustomerPage from './CustomerPage'
import CustomerReviewsPage from './CustomerReviewsPage'
import { ActiveCustomersPage, BlockedCustomersPage } from './CustomersPage'
import EmployeePage from './EmployeePage'
import EmployeesPage from './EmployeesPage'
import FinancePage from './FinancePage'
import NewEmployeePage from './NewEmployeePage'
import OrderPage from './OrderPage'
import OrdersPage from './OrdersPage'
import PageLayout from './PageLayout'
import WorkerPage from './WorkerPage'
import WorkerResponsesPage from './WorkerResponsesPage'
import WorkerReviewsPage from './WorkerReviewsPage'
import { ActiveWorkersPage, BlockedWorkersPage } from './WorkersPage'

export const Pages: React.FC = () => (
    <Auth>
        <PageLayout>
            <Switch>
                <Route exact path="/orders/:orderId" component={OrderPage} />
                <Route exact path="/orders" component={OrdersPage} />

                <Route exact path="/workers/:workerId/reviews" component={WorkerReviewsPage} />
                <Route exact path="/workers/:workerId/responses" component={WorkerResponsesPage} />
                <Route exact path="/workers/:workerId" component={WorkerPage} />
                <Route exact path="/workers" component={ActiveWorkersPage} />
                <Route exact path="/blocked-workers" component={BlockedWorkersPage} />

                <Route exact path="/customers/:customerId" component={CustomerPage} />
                <Route exact path="/customers/:customerId/reviews" component={CustomerReviewsPage} />
                <Route exact path="/customers" component={ActiveCustomersPage} />
                <Route exact path="/blocked-customers" component={BlockedCustomersPage} />

                <Route exact path="/employees/new" component={NewEmployeePage} />
                <Route exact path="/employees/:employeeId" component={EmployeePage} />
                <Route exact path="/employees" component={EmployeesPage} />

                <Route exact path="/catalog" component={CatalogPage} />

                <Route exact path="/finance" component={FinancePage} />

                <Redirect to="/orders" />
            </Switch>
        </PageLayout>
    </Auth>
)
