import { Box } from '@mui/material'
import ContentLoader from 'app/components/ContentLoader'
import { Skeleton } from 'app/components/Loaders'
import MultilineTextEllipsis from 'app/components/MultilineTextEllipsis'
import Profile from 'app/components/Profile'
import Table from 'app/components/Table'
import Tooltip from 'app/components/Tooltip'
import { OrderResponseChat } from 'app/modules/OrderResponses'
import { useRouter } from 'hooks'
import React, { useCallback } from 'react'
import { TTableRowData } from 'types/ITable'
import { dateHelper } from 'utils'

import useOrderResponses, { OrderResponseItem } from './useOrderResponses'

const columns: TTableRowData<OrderResponseItem>[] = [
    {
        name: 'worker',
        title: 'Мастер',
        xs: 3,
        element: ({ worker }: OrderResponseItem) => (
            <Profile photoUrl={worker.avatarUrl} firstName={worker.firstName} lastName={worker.lastName} />
        ),
    },
    {
        name: 'createdAt',
        title: 'Дата отклика',
        xs: 2,
        element: (data) => dateHelper.formatDate(data.createdAt),
    },
    {
        name: 'price',
        title: 'Стоимость',
        xs: 1.5,
        element: (data) => `${data.priceFromWorker.value} ${data.priceFromWorker.currencySymbol}`,
    },
    {
        name: 'status',
        title: 'Статус',
        xs: 1.5,
        element: (data) => <Box color={data.status === 'rejected' ? '#f34848' : undefined}>{data.statusText}</Box>,
    },
    {
        name: 'comment',
        title: 'Комментарий',
        xs: 3,
        element: (data) =>
            data.comment ? (
                <Tooltip title={data.comment}>
                    <MultilineTextEllipsis lines={2}>{data.comment}</MultilineTextEllipsis>
                </Tooltip>
            ) : (
                <>–</>
            ),
    },
    {
        name: 'chat',
        title: 'Чат',
        xs: 1,
        alignTitle: 'center',
        element: (data) => (
            <Box display="flex" justifyContent="center" width="100%" onClick={(e) => e.stopPropagation()}>
                <OrderResponseChat
                    channel={data.chatId}
                    customer={{
                        id: data.customer.id,
                        firstName: data.customer.firstName,
                        lastName: data.customer.lastName,
                        avatarUrl: data.customer.avatarUrl,
                    }}
                    worker={{
                        id: data.worker.id,
                        firstName: data.worker.firstName,
                        lastName: data.worker.lastName,
                        avatarUrl: data.worker.avatarUrl,
                    }}
                />
            </Box>
        ),
    },
]

function OrderResponses() {
    const { responses, loaded, error } = useOrderResponses()

    const router = useRouter()
    const handleClick = useCallback(
        (response: OrderResponseItem) => router.goToWorkerPage(response.worker.id),
        [router]
    )

    return (
        <ContentLoader
            loaded={loaded}
            error={error}
            loader={<Skeleton height={180} />}
            content={
                responses.length !== 0 ? (
                    <Table px={0} rowPx={0} items={responses} rows={columns} handleClickRow={handleClick} />
                ) : (
                    <>Пока что тут пусто</>
                )
            }
        />
    )
}

export default OrderResponses
