import { TextField } from '@mui/material'
import { useForwardRef } from 'hooks'
import React, { forwardRef } from 'react'

export type TextareaProps = {
    id?: string
    value?: string
    name?: string
    placeholder?: string
    required?: boolean
    disabled?: boolean
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    onClick?: React.MouseEventHandler<HTMLInputElement>
    onFocus?: React.FocusEventHandler<HTMLInputElement>
    onBlur?: React.FocusEventHandler<HTMLInputElement>
    onMouseDown?: React.MouseEventHandler<HTMLInputElement>
    onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>
    className?: string
    width?: number
    maxWidth?: number
    minWidth?: number
    error?: boolean
    maxRows?: number
    minRows?: number
}

function Textarea(
    {
        id,
        value = '',
        onChange,
        placeholder,
        name,
        disabled,
        onClick,
        onFocus,
        onBlur,
        onKeyUp,
        onMouseDown,
        className,
        width,
        maxWidth,
        minWidth,
        error,
        maxRows = 4,
        minRows = 4,
    }: TextareaProps,
    ref: React.ForwardedRef<HTMLInputElement>
) {
    const inputRef = useForwardRef<HTMLInputElement>(ref)

    return (
        <TextField
            id={id}
            inputRef={inputRef}
            fullWidth
            placeholder={placeholder}
            variant="filled"
            name={name}
            value={value}
            hiddenLabel
            disabled={disabled}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onMouseDown={onMouseDown}
            onKeyUp={onKeyUp}
            className={className}
            InputProps={{
                disableUnderline: true,
            }}
            error={error}
            onClick={onClick}
            multiline
            maxRows={maxRows}
            minRows={minRows}
            sx={(theme) => ({
                '&.MuiTextField-root': {
                    width,
                    maxWidth,
                    minWidth,
                },
                '& .MuiFilledInput-root': {
                    flexWrap: 'wrap',
                    backgroundColor: theme.palette.background.default,
                    borderRadius: 2,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: '#eae9e8',
                    transition: 'border-color ease 0.3s',
                    '&:hover': {
                        filter: 'brightness(99%)',
                        backgroundColor: theme.palette.background.default,
                    },
                    '&.Mui-focused': {
                        borderColor: theme.palette.primary.main,
                    },
                },
                '& .MuiFilledInput-input': {
                    // pt: paddingTop,
                    // pb: paddingBottom,
                    flexGrow: 1,
                    width: 0,
                    minWidth: 30,
                },
            })}
        />
    )
}
export default forwardRef(Textarea)
