import { Box, Typography } from '@mui/material'
import React from 'react'
import { dateHelper } from 'utils'

type OrderDateTime = {
    datetime: {
        dates?: Date[]
        fromTime?: Date
        toTime?: Date
        canChangeDates: boolean
    }
}

function OrderDateTime({ datetime: { dates = [], fromTime, toTime, canChangeDates } }: OrderDateTime) {
    const dateText = dates.map((date) => dateHelper.formatDate(date, { format: 'DD.MM.YYYY' })).join(', ')
    const timeText = [
        fromTime && `с ${dateHelper.formatDate(fromTime, { format: 'HH:mm' })}`,
        toTime && `до ${dateHelper.formatDate(toTime, { format: 'HH:mm' })}`,
    ]
        .filter(Boolean)
        .join(' ')

    return (
        <Box display="flex" flexDirection="column">
            <Typography variant="subtitle1">{dateText || 'Дата не указана'}</Typography>
            <Typography variant="subtitle1">{timeText || 'Время не указано'}</Typography>
            {dateText && canChangeDates && (
                <Typography variant="body2" color="grey.600">
                    Возможно, получится в другие дни
                </Typography>
            )}
        </Box>
    )
}

export default OrderDateTime
