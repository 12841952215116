import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import * as React from 'react'

import { Link } from '../Link'

interface BreadcrumbsItem {
    label: string
    link: string
}

interface BreadcrumbsProps {
    items: BreadcrumbsItem[]
}

export default ({ items }: BreadcrumbsProps) => {
    const lastItem = items.pop()

    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            separator={
                <Typography
                    variant="body2"
                    sx={(theme) => ({ color: theme.palette.grey['400'], fontSize: '20px', fontWeight: '400' })}
                >
                    /
                </Typography>
            }
        >
            {items.map((item, index) => {
                return (
                    <Link key={index} href={item.link} underline="hover">
                        {item.label}
                    </Link>
                )
            })}
            <Typography sx={(theme) => ({ color: theme.palette.grey['400'] })}>{lastItem?.label}</Typography>
        </Breadcrumbs>
    )
}
