import { MoreButton, MoreButtonOption } from 'app/components/Button'
import React, { useState } from 'react'

import OrderCancellation from './OrderCancellation'
import useOrder from './useOrder'

function OrderActionsButton() {
    const { canCancel } = useOrder()
    const [viewState, setViewState] = useState<{ type: 'cancelling' }>()

    const options: MoreButtonOption[] = []
    if (canCancel) {
        options.push({ title: 'Отменить заказ', onSelect: () => setViewState({ type: 'cancelling' }) })
    }

    if (options.length === 0) {
        return null
    }

    const openedModal = viewState !== undefined && canCancel

    return (
        <>
            <MoreButton options={options} position="right" />

            {openedModal && viewState?.type === 'cancelling' && (
                <OrderCancellation onClose={() => setViewState(undefined)} />
            )}
        </>
    )
}

export default OrderActionsButton
