import { useEffect, useState } from 'react'
import apiService, { OrderCancellationReason } from 'services/api-service'

const useOrderCancellationReasons = (orderId: string) => {
    const [reasonsData, setReasonsData] = useState<{ reasons: OrderCancellationReason[]; loaded: boolean }>()

    useEffect(() => {
        apiService.getOrderCancellationReasons(orderId).then((reasons) => {
            setReasonsData({ reasons, loaded: true })
        })
    }, [orderId])

    return {
        reasons: reasonsData?.reasons ?? [],
        loaded: reasonsData?.loaded ?? false,
    }
}

export default useOrderCancellationReasons
