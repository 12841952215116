import { Avatar as MUIAvatar, SxProps } from '@mui/material'
import React, { useMemo } from 'react'
import { names } from 'utils'

type AvatarProps = {
    srcUrl?: string | null
    firstName?: string | null
    lastName?: string | null
    size?: 's' | 'l'
}

function Avatar({ srcUrl, firstName, lastName, size = 's' }: AvatarProps) {
    const fullName = useMemo(() => {
        const fullName = names.getFullName({ firstName, lastName })
        if (!fullName) {
            return 'Без имени'
        }

        return fullName
    }, [firstName, lastName])

    const placeholer = useMemo(
        () =>
            fullName
                .split(' ')
                .filter(Boolean)
                .map((c) => c[0].toUpperCase())
                .slice(0, 2),
        [fullName]
    )

    const sx = useMemo(() => {
        const sx: SxProps = {
            width: 40,
            height: 40,
        }

        if (size === 's') {
            sx.width = 40
            sx.height = 40
        }

        if (size === 'l') {
            sx.width = 86
            sx.height = 86
        }

        return sx
    }, [size])

    return (
        <MUIAvatar src={srcUrl ?? undefined} alt={fullName} sx={sx}>
            {placeholer}
        </MUIAvatar>
    )
}

export default Avatar
