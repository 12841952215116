import { ArrowLeft as ArrowLeftIcon, ArrowRight as ArrowRightIcon } from '@mui/icons-material'
import { Box, SxProps } from '@mui/material'
import range from 'lodash/range'
import React, { useCallback, useMemo } from 'react'
import {
    addMonths,
    DAYS_IN_WEEK,
    formatDate,
    getDaysInMonth,
    getWeekDay,
    SHORT_WEEK_NAMES,
    startOfMonth,
} from 'utils/date-helper'

const sx: { [key: string]: SxProps } = {
    root: {
        width: '264px',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        marginBottom: '20px',
    },
    title: {
        fontSize: '14px',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        width: '100px',
    },
    weekNames: {
        display: 'flex',
        whiteSpace: 'nowrap',
        paddingBottom: '5px',
        borderBottom: `1px solid divider`,
        marginBottom: '5px',
        '& .MuiBox-root': {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '32px',
            height: '32px',
        },
    },
    week: {
        display: 'flex',
        whiteSpace: 'nowrap',
        '& .MuiBox-root': {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '32px',
            height: '32px',
        },
    },
    day: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    highlightToday: {
        fontWeight: 600,
    },
    arrow: {
        cursor: 'pointer',
    },
}

type CalendarProps = {
    value?: Date | null
    onChange?: (value: Date) => void
    onClickDay?: (value: Date) => void
    onMouseOverDay?: (value: Date) => void
    onMouseLeaveDay?: (value: Date) => void
    onMouseLeaveBody?: () => void
    highlightToday?: boolean
    daySx?: (day: Date) => SxProps
    className?: string
}

function Calendar({
    value,
    onChange,
    onClickDay,
    onMouseOverDay,
    onMouseLeaveDay,
    onMouseLeaveBody,
    highlightToday,
    daySx,
}: CalendarProps) {
    const calendarDate = useMemo(() => startOfMonth(value || new Date()), [value])
    const firstWeekDay = useMemo(() => getWeekDay(calendarDate), [calendarDate])
    const daysInMonth = useMemo(() => getDaysInMonth(calendarDate), [calendarDate])
    const maxRows = useMemo(() => Math.ceil((daysInMonth + firstWeekDay) / DAYS_IN_WEEK), [daysInMonth, firstWeekDay])
    const title = useMemo(() => {
        const value = formatDate(calendarDate, { format: 'MMMM YYYY' })
        return value[0].toUpperCase() + value.slice(1)
    }, [calendarDate])
    const todayDate = useMemo(() => new Date(), [])

    const getDateByDayNumber = useCallback(
        (dayNumber: number) => new Date(calendarDate.getFullYear(), calendarDate.getMonth(), dayNumber),
        [calendarDate]
    )

    const isDayHighlighted = useCallback(
        (dayNumber: number) => {
            return (
                highlightToday &&
                calendarDate.getMonth() === todayDate.getMonth() &&
                calendarDate.getFullYear() === todayDate.getFullYear() &&
                dayNumber === todayDate.getDate()
            )
        },
        [calendarDate, todayDate, highlightToday]
    )

    const handleClickDay = useCallback(
        (dayNumber: number) => {
            onClickDay?.(getDateByDayNumber(dayNumber))
        },
        [onClickDay, getDateByDayNumber]
    )

    const handleMouseOverDay = useCallback(
        (dayNumber: number) => {
            onMouseOverDay?.(getDateByDayNumber(dayNumber))
        },
        [onMouseOverDay, getDateByDayNumber]
    )

    const handleMouseLeaveDay = useCallback(
        (dayNumber: number) => {
            onMouseLeaveDay?.(getDateByDayNumber(dayNumber))
        },
        [onMouseLeaveDay, getDateByDayNumber]
    )

    const getDaySx = useCallback(
        (dayNumber: number) => ({
            ...sx.day,
            ...(isDayHighlighted(dayNumber) ? sx.highlightToday : {}),
            ...daySx?.(getDateByDayNumber(dayNumber)),
        }),
        [isDayHighlighted, getDateByDayNumber, daySx]
    )

    const handleChange = useCallback(
        (value: Date) => {
            onChange?.(value)
        },
        [onChange]
    )

    const handleNextMonth = useCallback(() => {
        handleChange(addMonths(calendarDate, 1))
    }, [handleChange, calendarDate])

    const handlePrevMonth = useCallback(() => {
        handleChange(addMonths(calendarDate, -1))
    }, [calendarDate, handleChange])

    return (
        <Box>
            <Box sx={sx.header}>
                <ArrowLeftIcon onClick={handlePrevMonth} sx={sx.arrow} />
                <Box sx={sx.title}>{title}</Box>
                <ArrowRightIcon onClick={handleNextMonth} sx={sx.arrow} />
            </Box>

            <Box sx={sx.weekNames}>
                {SHORT_WEEK_NAMES.map((name) => (
                    <Box key={name}>{name}</Box>
                ))}
            </Box>

            <Box onMouseLeave={onMouseLeaveBody}>
                {range(0, maxRows).map((row) => {
                    return (
                        <Box key={row} sx={sx.week}>
                            {range(0, DAYS_IN_WEEK).map((col) => {
                                const dayNumber = DAYS_IN_WEEK * row + col - firstWeekDay + 1
                                return (
                                    <Box key={col}>
                                        {dayNumber >= 1 && dayNumber <= daysInMonth && (
                                            <Box
                                                onClick={onClickDay && (() => handleClickDay(dayNumber))}
                                                onMouseOver={onMouseOverDay && (() => handleMouseOverDay(dayNumber))}
                                                onMouseLeave={onMouseLeaveDay && (() => handleMouseLeaveDay(dayNumber))}
                                                sx={getDaySx(dayNumber)}
                                            >
                                                {dayNumber}
                                            </Box>
                                        )}
                                    </Box>
                                )
                            })}
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default Calendar
