import { Box } from '@mui/material'
import Input from 'app/components/Input'
import { useCatchOutsidedClick, useToggle } from 'hooks'
import React, { useCallback, useMemo } from 'react'
import { formatDate } from 'utils/date-helper'

import DateRangePicker, { DateRangeValues } from '../DatePicker/DateRangePicker'

type DateRangeFilterProps = {
    dates?: DateRangeValues
    onChange: (dates?: DateRangeValues) => void
    label?: string
    placeholder?: string
}
function DateRangeFilter({ dates, onChange, label = 'Период', placeholder = 'Все даты' }: DateRangeFilterProps) {
    const { opened, open, close } = useToggle(false)

    const handleChange = useCallback(
        (dates?: DateRangeValues) => {
            onChange(dates)
            if (dates !== undefined) {
                close()
            }
        },
        [onChange]
    )

    const dateText = useMemo(() => {
        if (!dates) {
            return ''
        }
        const [start, end] = dates
        return `${formatDate(start, { format: 'DD.MM.YYYY' })} — ${formatDate(end, { format: 'DD.MM.YYYY' })}`
    }, [dates])

    const handleClear = useCallback(() => onChange(undefined), [])

    const boxRef = useCatchOutsidedClick({ onCatch: close })

    return (
        <Box ref={boxRef} position="relative" width={280}>
            <Input value={dateText} onClick={open} label={label} placeholder={placeholder} onClear={handleClear} />
            {opened && (
                <Box position="absolute" top="110%" zIndex={10000}>
                    <DateRangePicker values={dates} onChange={handleChange} highlightToday enableDefinedRanges />
                </Box>
            )}
        </Box>
    )
}

export default DateRangeFilter
