import { Box, Paper, Typography } from '@mui/material'
import Table from 'app/components/Table'
import { TitleBlock } from 'app/components/TitleBlock'
import { useRouter } from 'hooks'
import React, { useCallback, useMemo } from 'react'
import { TTableRowData } from 'types/ITable'
import { dateHelper } from 'utils'

import CityName from '../../components/CityName'
import { EMPLOYEE_ROLE_NAMES } from '../../constants'
import Filters from './Filters'
import useEmployees, { Employee } from './useEmployees'

const EmployeesPage: React.FC = () => {
    const { data, loading, changeLimit, changeSort, changePage } = useEmployees()

    const router = useRouter()
    const handleClickByRow = useCallback((employee: Employee) => router.goToEmployeePage(employee.id), [router])

    const tableRows = useMemo(() => getTableRows(), [])

    return (
        <Paper
            sx={{
                bgcolor: 'white',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                py: 4,
            }}
            elevation={0}
        >
            <TitleBlock
                px={4}
                title="Сотрудники"
                count={data.totalCount}
                addText="Добавить сотрудника"
                handleCreate={router.goToNewEmployeePage}
            />
            <Box px={4} mt={4}>
                <Filters />
            </Box>
            <Box mt={4}>
                <Table
                    items={data.employees}
                    rows={tableRows}
                    order={data.sort}
                    pagination={data.pagination}
                    isLoading={loading}
                    handleOrderChange={changeSort}
                    handleLimitChange={changeLimit}
                    handlePageChange={changePage}
                    handleClickRow={handleClickByRow}
                />
            </Box>
        </Paper>
    )
}

function renderNameColumn() {
    return {
        title: 'Имя',
        name: 'name',
        isSort: true,
        xs: 3,
        element: ({ firstname, lastname }: Employee) => {
            let fullName = firstname ?? ''
            if (lastname) {
                fullName += fullName !== '' ? ` ${lastname}` : lastname
            }
            if (!fullName) {
                fullName = 'Без имени'
            }

            return <Typography variant="body2">{fullName}</Typography>
        },
    }
}

function renderEmailColumn() {
    return {
        title: 'E-mail',
        name: 'email',
        xs: 2,
        element: ({ email }: Employee) => (
            <>
                <Typography variant="body2" color="grey.600">
                    {email}
                </Typography>
            </>
        ),
    }
}

function renderRoleColumn() {
    return {
        title: 'Роль',
        name: 'role',
        xs: 2,
        element: ({ role }: Employee) => (
            <>
                <Typography variant="body2" color="grey.600">
                    {EMPLOYEE_ROLE_NAMES[role]}
                </Typography>
            </>
        ),
    }
}

function renderCitiesColumn() {
    return {
        title: 'Города',
        name: 'cities',
        xs: 2,
        element: ({ role, cityIds }: Employee) => {
            const cities =
                cityIds && cityIds.length !== 0
                    ? cityIds.map((cityId) => (
                          <Box key={cityId}>
                              <CityName cityId={cityId} />
                          </Box>
                      ))
                    : 'Не указан'

            return (
                <>
                    <Typography variant="body2" color="grey.600">
                        {role === 'DIRECTOR' || role === 'MANAGER' ? (
                            'Все'
                        ) : (
                            <Box display="flex" flexDirection="column">
                                {cities}
                            </Box>
                        )}
                    </Typography>
                </>
            )
        },
    }
}

function renderCreatedAtColumn() {
    return {
        title: 'Дата регистрации',
        name: 'createdAt',
        isSort: true,
        xs: 3,
        element: ({ createdAt }: Employee) => (
            <Typography variant="body2" color="grey.600">
                {dateHelper.formatDate(createdAt)}
            </Typography>
        ),
    }
}

function getTableRows(): TTableRowData[] {
    return [renderNameColumn(), renderEmailColumn(), renderRoleColumn(), renderCitiesColumn(), renderCreatedAtColumn()]
}

export default EmployeesPage
