import { Clear as ClearIcon } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import { CityFilter, DateRangeFilter } from 'app/components/Filters'
import { PhoneInput } from 'app/components/Input'
import React, { useCallback } from 'react'

import useFilter from './useFilter'

const Filters = () => {
    const { filter, changeFilter, resetFilter, isEmpty } = useFilter()

    const handleChangeFilter = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => changeFilter({ [e.target.name]: e.target.value }),
        [changeFilter]
    )
    const handleChangeCityFilter = useCallback((cityId: string | undefined) => changeFilter({ cityId }), [changeFilter])
    const handleChangeCreatedIntervalFilter = useCallback(
        (createdInterval?: [Date, Date] | undefined) => changeFilter({ createdInterval }),
        [changeFilter]
    )

    return (
        <Grid container columnSpacing={2} rowSpacing={2} alignItems="center">
            <Grid item>
                <PhoneInput name="phone" value={filter.phone} onChange={handleChangeFilter} label="Телефон" cleanable />
            </Grid>
            <Grid item>
                <CityFilter cityId={filter.cityId} onChange={handleChangeCityFilter} />
            </Grid>
            <Grid item>
                <DateRangeFilter
                    dates={filter.createdInterval}
                    onChange={handleChangeCreatedIntervalFilter}
                    label="Дата регистрации"
                    placeholder="Все даты"
                />
            </Grid>
            {!isEmpty && (
                <Grid item>
                    <IconButton onClick={resetFilter} size="medium">
                        <ClearIcon fontSize="medium" />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    )
}

export default Filters
