import { Clear as ClearIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { Autocomplete as MaterialAutocomplete, Box } from '@mui/material'
import React, { useCallback, useMemo } from 'react'

import Input from '../Input'

type Option = {
    title: string
    value: string
}

type AutocompleteProps<T> = {
    value?: T
    onChange: (value: T) => void
    options: Option[]
    label: string
    placeholder?: string
    multiple?: boolean
    minWidth?: number
}

function Autocomplete<T extends (string | null) | string[]>({
    value,
    onChange,
    options,
    label,
    placeholder = 'Не выбрано',
    multiple = false,
    minWidth = 120,
}: AutocompleteProps<T>) {
    const emptyValue = Array.isArray(value) ? value.length === 0 : !value
    const showPlaceholder = emptyValue

    const handleChange = useCallback(
        (_: any, value: any) => {
            if (value === null) {
                onChange(value)
                return
            }

            const v = Array.isArray(value) ? value.map((v) => v.value) : value.value
            onChange(v)
        },
        [onChange]
    )

    const option = useMemo(() => {
        const result = options.filter((option) =>
            Array.isArray(value) ? value.includes(option.value) : option.value === value
        )
        return multiple ? result : result[0]
    }, [value, options, multiple])

    return (
        <Box sx={{ minWidth }}>
            <MaterialAutocomplete
                value={option}
                onChange={handleChange}
                options={options}
                multiple={multiple}
                noOptionsText="Не найдено"
                getOptionLabel={(option) => option.title}
                renderInput={(params: any) => (
                    <div ref={params.InputProps.ref}>
                        <Input
                            {...params.inputProps}
                            startAdornment={params.InputProps.startAdornment}
                            endAdornment={params.InputProps.endAdornment}
                            label={label}
                            placeholder={showPlaceholder ? placeholder : undefined}
                        />
                    </div>
                )}
                clearIcon={<ClearIcon fontSize="small" />}
                popupIcon={<ExpandMoreIcon sx={{ color: 'secondary.main' }} />}
                sx={(theme) => ({
                    '& .MuiAutocomplete-popupIndicator:hover': {
                        backgroundColor: 'transparent',
                    },
                    '& .MuiAutocomplete-popupIndicatorOpen > .MuiSvgIcon-root': {
                        color: theme.palette.primary.main,
                    },
                    '&.MuiAutocomplete-root .MuiFilledInput-root': {
                        paddingBottom: 0.25,
                    },
                })}
                componentsProps={{
                    paper: {
                        sx: (theme) => ({
                            '&.MuiPaper-root': {
                                marginTop: 1,
                                borderRadius: 2,
                                boxShadow: '0 1px 10px rgb(0 0 0 / 10%)',
                            },
                            '& .MuiAutocomplete-option.Mui-focused': {
                                backgroundColor: `${theme.palette.background.default} !important`,
                            },
                            '& .MuiAutocomplete-option[aria-selected="true"]': {
                                backgroundColor: 'rgba(75, 181, 111, 0.08)',
                            },
                        }),
                    },
                }}
            />
        </Box>
    )
}

export default Autocomplete
