import { EmployeeRole } from 'app/constants'
import { useCallback, useMemo, useRef } from 'react'
import apiService from 'services/api-service'
import useImmutableSWR from 'swr/immutable'

const useLoaded = (data: any) => {
    const loaded = useRef(data)
    if (data) {
        loaded.current = true
    }
    return loaded.current
}

function useUser() {
    const { data, error, mutate } = useImmutableSWR('current_user', apiService.getCurrentUser)
    const loaded = useLoaded(data || error)

    const update = useCallback(() => {
        mutate()
    }, [mutate])

    const logout = useCallback(async () => {
        await apiService.logout()
        mutate(undefined, { revalidate: false })
    }, [mutate])

    const permissions = useMemo(
        () => ({
            canManageAllCities: data?.role === EmployeeRole.DIRECTOR || data?.role === EmployeeRole.MANAGER,
            canManageOwnCities: data?.role === EmployeeRole.CITY_DIRECTOR || data?.role === EmployeeRole.CITY_MANAGER,
            canEditCatalog: data?.role === EmployeeRole.DIRECTOR || data?.role === EmployeeRole.MANAGER,
        }),
        [data?.role]
    )

    return {
        user: data,
        permissions,
        loaded,
        logged: Boolean(data),
        update,
        logout,
    }
}

export default useUser
