import { CANCELLABLE_ORDER_STATES } from 'app/constants'
import { useRouteParams, useToast } from 'hooks'
import { useCallback, useMemo, useState } from 'react'
import apiService, { Order as ApiOrder } from 'services/api-service'
import useSWR from 'swr'
import { dateHelper } from 'utils'

export type Order = ApiOrder

const ONE_MINUTE = 1 * 60 * 1000

const useOrder = () => {
    const toast = useToast()
    const { orderId = '' } = useRouteParams()
    const {
        data,
        error,
        mutate: update,
    } = useSWR(['order', orderId], () => apiService.getOrder(orderId), {
        focusThrottleInterval: ONE_MINUTE,
        refreshInterval: ONE_MINUTE,
    })
    const [cancelling, setCancelling] = useState(false)

    const order = useMemo(
        () =>
            data && {
                ...data,
                publishedAt: dateHelper.from(data.publishedAt),
                datetime: {
                    canChangeDates: data.datetime.canChangeDates,
                    dates: data.datetime.dates.map(dateHelper.from),
                    fromTime: data.datetime.fromTime ? dateHelper.from(data.datetime.fromTime) : undefined,
                    toTime: data.datetime.toTime ? dateHelper.from(data.datetime.toTime) : undefined,
                },
            },
        [data]
    )

    const cancel = useCallback(
        async (data: { type: number; comment?: string }) => {
            try {
                setCancelling(true)
                await apiService.cancelOrder({ orderId, ...data })
                await update()
            } catch (error: any) {
                toast.showError(error.message)
            } finally {
                setCancelling(false)
            }
        },
        [orderId, update]
    )

    const canCancel = useMemo(() => (data ? CANCELLABLE_ORDER_STATES.includes(data.state) : false), [data])

    return {
        order,
        loaded: Boolean(data) || Boolean(error),
        error,
        canCancel,
        cancel,
        cancelling,
    }
}

export default useOrder
