import { Box, Tooltip as MuiTooltip } from '@mui/material'
import React from 'react'

type TooltipProps = {
    title: string
    children: React.ReactElement
}

function Tooltip({ title, children }: TooltipProps) {
    return (
        <MuiTooltip
            title={title}
            componentsProps={{
                tooltip: {
                    sx: {
                        fontSize: '0.8rem',
                        backgroundColor: '#22272A',
                    },
                },
            }}
        >
            <Box>{children}</Box>
        </MuiTooltip>
    )
}

export default Tooltip
