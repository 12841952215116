import { useRouteParams, useToast } from 'hooks'
import { useCallback, useState } from 'react'
import apiService from 'services/api-service'

type Values = {
    responsesCount: string
    expirationPeriodDays: string
}

type HookProps = {
    onActivated?: () => Promise<void>
}

const useActivateNewWorkerTariff = ({ onActivated }: HookProps) => {
    const { workerId = '' } = useRouteParams()
    const toast = useToast()
    const [values, setValues] = useState<Values>({
        responsesCount: '10',
        expirationPeriodDays: '30',
    })
    const [activating, setActivating] = useState(false)

    const changeValues = useCallback(
        (newValues: Partial<Values>) => setValues((oldValue) => ({ ...oldValue, ...newValues })),
        []
    )

    const activateTariff = useCallback(async () => {
        try {
            setActivating(true)
            await apiService.activateWorkerTariff({
                workerId,
                responsesCount: Number(values.responsesCount),
                expirationPeriodDays: Number(values.expirationPeriodDays),
            })
            await onActivated?.()
        } catch (error: any) {
            toast.showError(error.message)
        } finally {
            setActivating(false)
        }
    }, [workerId, values, toast])

    return {
        values,
        changeValues,
        activateTariff,
        activating,
    }
}

export default useActivateNewWorkerTariff
