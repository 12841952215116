import { RefObject, useEffect, useRef } from 'react'

type HookArgs = {
    onCatch?: () => void
}

const useCatchOutsidedClick = <T extends HTMLElement>({ onCatch }: HookArgs): RefObject<T> => {
    const ref = useRef<T>(null)

    useEffect(() => {
        if (!onCatch) {
            return
        }

        const handleClose = (event: MouseEvent) => {
            const target = event.target as HTMLElement

            if (ref.current && !ref.current.contains(target)) {
                onCatch()
            }
        }

        document.addEventListener('click', handleClose, true)

        return () => {
            document.removeEventListener('click', handleClose, true)
        }
    }, [onCatch])

    return ref
}

export default useCatchOutsidedClick
