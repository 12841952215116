import { Alert, Box, Grid } from '@mui/material'
import { PrimaryButton } from 'app/components/Button'
import Input from 'app/components/Input'
import React, { useCallback } from 'react'

import useActivateNewWorkerTariff from './useActivateNewWorkerTariff'

type NewWorkerTariffActivationProps = {
    onActivated?: () => Promise<void>
    hasActiveTariff?: boolean
}

function NewWorkerTariffActivation({ onActivated, hasActiveTariff }: NewWorkerTariffActivationProps) {
    const { values, changeValues, activateTariff, activating } = useActivateNewWorkerTariff({ onActivated })

    const onChangeInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = e.target
            changeValues({ [name]: value })
        },
        [changeValues]
    )

    return (
        <Box width={400}>
            {hasActiveTariff && (
                <Alert severity="info" sx={{ mb: 3 }}>
                    В случае активации нового тарифа, текущий активный тариф перестанет действовать
                </Alert>
            )}
            <Grid container rowSpacing={3} direction="column">
                <Grid item>
                    <Input
                        label="Кол-во откликов"
                        placeholder="10"
                        name="responsesCount"
                        value={values.responsesCount}
                        onChange={onChangeInput}
                    />
                </Grid>
                <Grid item>
                    <Input
                        label="Срок действия, в днях"
                        placeholder="30"
                        name="expirationPeriodDays"
                        value={values.expirationPeriodDays}
                        onChange={onChangeInput}
                    />
                </Grid>
                <PrimaryButton loading={activating} size="large" fullWidth sx={{ mt: 4 }} onClick={activateTariff}>
                    Активировать
                </PrimaryButton>
            </Grid>
        </Box>
    )
}

export default NewWorkerTariffActivation
