import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import React from 'react'

type ConfirmationWindowProps = {
    title: string
    opened: boolean
    onConfirm: () => void
    onClose: () => void
    confirming?: boolean
}

function ConfirmationWindow({ title, opened, onConfirm, onClose, confirming }: ConfirmationWindowProps) {
    return (
        <Dialog open={opened} onClose={onClose} aria-labelledby="alert-dialog-title">
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Отмена
                </Button>
                <LoadingButton loading={confirming} onClick={onConfirm} color="error">
                    Подтвердить
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationWindow
