import React from 'react'

export const StarSvg = ({ width = 20, height = 19 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.2659 6.77954L13.1304 6.02716L10.8291 1.36699C10.6595 1.02719 10.3203 0.833008 9.95707 0.833008C9.59379 0.833008 9.25465 1.05141 9.085 1.36699L6.78375 6.02716L1.64823 6.77954C1.28493 6.82814 0.969949 7.09513 0.872966 7.43492C0.776143 7.77472 0.848803 8.16312 1.11528 8.43011L4.84571 12.0709L3.97364 17.1922C3.90098 17.5562 4.07046 17.9204 4.36127 18.1388C4.6519 18.3572 5.06368 18.3816 5.37868 18.2116L9.98127 15.7844L14.5839 18.2116C14.7292 18.2844 14.8745 18.333 15.0442 18.333C15.238 18.333 15.4559 18.2602 15.6256 18.1388C15.9162 17.9204 16.0615 17.5562 16.0132 17.1922L15.1411 12.0709L18.8716 8.43011C19.138 8.16311 19.2349 7.77473 19.1139 7.43492C18.9201 7.07075 18.6294 6.82796 18.2659 6.77954H18.2659Z"
                fill="#FFCC00"
            />
        </svg>
    )
}
