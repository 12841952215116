import { Box, Typography } from '@mui/material'
import React from 'react'

type OrderCancellationReasonProps = {
    reason: {
        title: string
        comment?: string
    }
}

function OrderCancellationReason({ reason }: OrderCancellationReasonProps) {
    let text = reason.title
    if (reason.comment) {
        text += `: ${reason.comment}`
    }
    return (
        <Box display="flex" flexDirection="column">
            <Typography variant="subtitle1">{text}</Typography>
        </Box>
    )
}

export default OrderCancellationReason
