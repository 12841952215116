import React from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { ThemeProvider } from 'styles/theme/ThemeProvider'

import { App } from './app'

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

root.render(
    <ThemeProvider>
        <HelmetProvider>
            <App />
        </HelmetProvider>
    </ThemeProvider>
)
