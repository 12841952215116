export const COUNTRY = {
    RU: 'RU',
}

export const COUNTRY_SETTINGS = {
    [COUNTRY.RU]: {
        id: COUNTRY.RU,
        code: '7',
        phoneLength: 11,
        mask: '+d ddd ddd-dd-dd',
    },
}

function getExactMask(phone: string) {
    const digits = phone.replace(/\D/g, '')

    for (const settings of Object.values(COUNTRY_SETTINGS)) {
        const startsWithCode = digits.startsWith(settings.code)
        const equalsLength = digits.length === settings.phoneLength

        if (startsWithCode && equalsLength) {
            return settings.mask
        }
    }

    return null
}

function applyMask(phone: string, mask: string) {
    const digits = phone.replace(/\D/g, '')
    let result = mask

    for (let i = 0; i < digits.length; i += 1) {
        result = result.replace('d', digits[i])
    }

    return result
}

function formatPhone(phone: string) {
    const mask = getExactMask(phone)

    if (mask === null) {
        return phone
    }

    return applyMask(phone, mask)
}

export default formatPhone
