import { Box, CircularProgress, Grid, Pagination, ToggleButton, ToggleButtonGroup } from '@mui/material'
import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TLimit, TTableOrder, TTablePagination, TTableRowData } from 'types/ITable'

import { DragTableItem } from './components/DragTableItem'
import { TableHeaderRow } from './components/TableHeaderRow'

export interface TableProps<T = any> {
    items: T[]
    rows: TTableRowData[]
    order?: TTableOrder
    pagination?: TTablePagination
    isDraggable?: boolean
    isLoading?: boolean
    hideHeader?: boolean
    handleOrderChange?: (order: TTableOrder) => void
    handleLimitChange?: (limit: TLimit) => void
    handlePageChange?: (page: number) => void
    onPositionChange?: (dragIndex: number, hoverIndex: number) => void
    onPositionChangeFinished?: () => void
    handleClickRow?: (data: any) => void
    px?: number
    rowPx?: number
    emptyText?: string
}

export const Table: React.FC<TableProps> = ({
    items,
    order,
    rows,
    pagination,
    isDraggable,
    isLoading,
    handleOrderChange,
    handleLimitChange,
    handlePageChange,
    onPositionChange,
    onPositionChangeFinished,
    handleClickRow,
    hideHeader,
    px = 2,
    rowPx = 2,
    emptyText = 'Пока что тут пусто',
}) => {
    return (
        <Box px={px} position={'relative'}>
            {isLoading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: 'white',
                        opacity: 0.6,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            {!hideHeader && (
                <Box
                    sx={{
                        px: rowPx,
                    }}
                >
                    <Box sx={{ pb: 1.25, borderBottom: '1px solid #f2f2f7' }}>
                        <Grid container columnSpacing={2}>
                            {rows.map((row, index) => (
                                <Grid key={index} item xs={row.headerXs || row.xs || true}>
                                    <TableHeaderRow
                                        title={row.title}
                                        alignTitle={row.alignTitle}
                                        name={row.name}
                                        isSort={row.isSort}
                                        order={order}
                                        onClick={handleOrderChange}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            )}

            <DndProvider backend={HTML5Backend}>
                {!isLoading && items.length === 0 && <Box pt={2}>{emptyText}</Box>}
                {items.length > 0 &&
                    items.map((item, index) => (
                        <DragTableItem
                            item={item}
                            px={rowPx}
                            rows={rows}
                            key={item.id}
                            positionIndex={index}
                            isDraggable={isDraggable}
                            onPositionChange={onPositionChange}
                            onPositionChangeFinished={onPositionChangeFinished}
                            handleClickRow={handleClickRow}
                        />
                    ))}
            </DndProvider>

            {!!pagination && (
                <Box display={'flex'} justifyContent={pagination.total_pages > 1 ? 'space-between' : 'flex-end'} mt={4}>
                    {pagination.total_pages > 1 && (
                        <Pagination
                            count={pagination.total_pages}
                            page={pagination.page}
                            shape="rounded"
                            onChange={(_, value) => handlePageChange?.(value)}
                        />
                    )}

                    <ToggleButtonGroup
                        size="small"
                        value={pagination.limit}
                        exclusive
                        onChange={(_, value) => handleLimitChange?.(value ?? pagination.limit)}
                    >
                        <ToggleButton value={10}>10</ToggleButton>
                        <ToggleButton value={25}>25</ToggleButton>
                        <ToggleButton value={50}>50</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            )}
        </Box>
    )
}
