import { Box, Divider, Grid, GridProps, SxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useForwardState, usePrevValue } from 'hooks'
import React, { useCallback, useEffect, useState } from 'react'
import { dateHelper } from 'utils'

import Calendar from './Calendar'

const DefinedRangeGridItem = styled(Grid)<GridProps & { selected?: boolean }>(({ theme, selected }) => ({
    cursor: 'pointer',
    '&:hover': {
        color: theme.palette.primary.main,
    },
    color: selected ? theme.palette.primary.main : undefined,
}))

export type DateRangeValues = [Date, Date]

type DateRangePickerProps = {
    values?: DateRangeValues
    onChange?: (value?: DateRangeValues) => void
    highlightToday?: boolean
    enableDefinedRanges?: boolean
}

const defaultValues: [null, null] = [null, null]

function DateRangePicker({
    values: inputValues,
    onChange,
    highlightToday = true,
    enableDefinedRanges = false,
}: DateRangePickerProps) {
    const [values, setValues] = useForwardState<[Date | null, Date | null]>(inputValues ?? defaultValues)
    const [startDate, endDate] = values ?? []
    const prevStartDate = usePrevValue(startDate)
    const prevEndDate = usePrevValue(endDate)
    const [calendarDate, setCalendarDate] = useState(startDate || endDate || undefined)
    const [hoveredDate, setHoveredDate] = useState<Date | null>(null)

    useEffect(() => {
        const startDateChanged = prevStartDate !== startDate
        const endDateChanged = prevEndDate !== endDate

        startDateChanged && !endDateChanged && startDate && setCalendarDate(startDate)
        endDateChanged && !startDateChanged && endDate && setCalendarDate(endDate)
        startDateChanged && endDateChanged && setCalendarDate(startDate || endDate || calendarDate)
    }, [prevStartDate, prevEndDate, startDate, endDate, setCalendarDate, calendarDate])

    const handleClickDay = useCallback(
        (day: Date) => {
            if (startDate && endDate) {
                return onChange?.(undefined)
            }

            if (!startDate && !endDate) {
                return setValues([dateHelper.startOfDay(day), null])
            }

            if (startDate) {
                const period = [startDate, day] as [Date, Date]
                period.sort(dateHelper.compareAsc)
                return onChange?.([dateHelper.startOfDay(period[0]), dateHelper.endOfDay(period[1])])
            }

            if (endDate) {
                const period = [day, endDate] as [Date, Date]
                period.sort(dateHelper.compareAsc)
                return onChange?.([dateHelper.startOfDay(period[0]), dateHelper.endOfDay(period[1])])
            }
        },
        [setValues, onChange, startDate, endDate]
    )

    const handleSelectToday = useCallback(() => {
        onChange?.(dateHelper.period.getToday())
    }, [onChange])

    const handleSelectYesterday = useCallback(() => {
        onChange?.(dateHelper.period.getYesterday())
    }, [onChange])

    const handleSelectCurrentWeek = useCallback(() => {
        onChange?.(dateHelper.period.getWeek())
    }, [onChange])

    const handleSelectLastWeek = useCallback(() => {
        onChange?.(dateHelper.period.getWeek(-1))
    }, [onChange])

    const handleSelectCurrentMonth = useCallback(() => {
        onChange?.(dateHelper.period.getMonth())
    }, [onChange])

    const handleSelectLastMonth = useCallback(() => {
        onChange?.(dateHelper.period.getMonth(-1))
    }, [onChange])

    const handleSelectCurrentYear = useCallback(() => {
        onChange?.(dateHelper.period.getYear())
    }, [onChange])

    const handleSelectLastYear = useCallback(() => {
        onChange?.(dateHelper.period.getYear(-1))
    }, [onChange])

    const clearHoveredDate = useCallback(() => setHoveredDate(null), [setHoveredDate])

    const getDaySx = useCallback(
        (day: Date): SxProps => {
            const selectedBothDays = Boolean(startDate) && Boolean(endDate)
            const existsLeftTail =
                !selectedBothDays && startDate && hoveredDate && dateHelper.isBefore(hoveredDate, startDate)
            const existsRightTail =
                !selectedBothDays && startDate && hoveredDate && dateHelper.isAfter(hoveredDate, startDate)

            const isSelectedStartDay = startDate && dateHelper.isSameDay(startDate, day)
            const isSelectedEndDay = endDate && dateHelper.isSameDay(endDate, day)

            const isDayBetweenSelectedDays =
                selectedBothDays &&
                !isSelectedStartDay &&
                !isSelectedEndDay &&
                dateHelper.period.isWithin(day, [startDate, endDate])

            const isDayInTail =
                !selectedBothDays &&
                !isSelectedStartDay &&
                hoveredDate &&
                startDate &&
                (dateHelper.isSameDay(hoveredDate, day) ||
                    dateHelper.period.isWithin(day, [hoveredDate, startDate]) ||
                    dateHelper.period.isWithin(day, [startDate, hoveredDate]))

            const isDayInEndTail = isDayInTail && hoveredDate && dateHelper.isSameDay(hoveredDate, day)

            const sx: SxProps = {
                borderRadius: '50%',
                cursor: 'pointer',
                '&:hover': {
                    color: 'white',
                    backgroundColor: 'primary.main',
                },
            }

            if (existsLeftTail && isSelectedStartDay) {
                sx.borderBottomLeftRadius = 0
                sx.borderTopLeftRadius = 0
            }

            if (existsRightTail && isSelectedStartDay) {
                sx.borderBottomRightRadius = 0
                sx.borderTopRightRadius = 0
            }

            if (existsLeftTail && isDayInEndTail) {
                sx.borderBottomRightRadius = 0
                sx.borderTopRightRadius = 0
            }

            if (existsRightTail && isDayInEndTail) {
                sx.borderBottomLeftRadius = 0
                sx.borderTopLeftRadius = 0
            }

            if (selectedBothDays && isSelectedStartDay && !isSelectedEndDay) {
                sx.borderBottomRightRadius = 0
                sx.borderTopRightRadius = 0
            }

            if (selectedBothDays && isSelectedEndDay && !isSelectedStartDay) {
                sx.borderBottomLeftRadius = 0
                sx.borderTopLeftRadius = 0
            }

            if (isSelectedStartDay || isSelectedEndDay) {
                sx.color = 'white'
                sx.backgroundColor = 'primary.main'
            }

            if ((isDayInTail && !isDayInEndTail) || isDayBetweenSelectedDays) {
                sx.color = 'white'
                sx.backgroundColor = 'primary.main'
                sx.borderRadius = 0
            }

            return sx
        },
        [startDate, endDate, hoveredDate]
    )

    return (
        <Box
            sx={{
                display: 'flex',
                p: 3,
                backgroundColor: 'white',
                boxShadow: '0 1px 10px rgb(0 0 0 / 10%)',
                borderRadius: 1,
            }}
        >
            {enableDefinedRanges && (
                <>
                    <Grid whiteSpace="nowrap" direction="column" container spacing="7px">
                        <DefinedRangeGridItem
                            item
                            selected={dateHelper.period.isToday(values)}
                            onClick={handleSelectToday}
                        >
                            Сегодня
                        </DefinedRangeGridItem>
                        <DefinedRangeGridItem
                            item
                            selected={dateHelper.period.isYesterday(values)}
                            onClick={handleSelectYesterday}
                        >
                            Вчера
                        </DefinedRangeGridItem>
                        <DefinedRangeGridItem
                            item
                            selected={dateHelper.period.isWeek(values)}
                            onClick={handleSelectCurrentWeek}
                        >
                            Эта неделя
                        </DefinedRangeGridItem>
                        <DefinedRangeGridItem
                            item
                            selected={dateHelper.period.isWeek(values, -1)}
                            onClick={handleSelectLastWeek}
                        >
                            Прошлая неделя
                        </DefinedRangeGridItem>
                        <DefinedRangeGridItem
                            item
                            selected={dateHelper.period.isMonth(values)}
                            onClick={handleSelectCurrentMonth}
                        >
                            Этот месяц
                        </DefinedRangeGridItem>
                        <DefinedRangeGridItem
                            item
                            selected={dateHelper.period.isMonth(values, -1)}
                            onClick={handleSelectLastMonth}
                        >
                            Прошлый месяц
                        </DefinedRangeGridItem>
                        <DefinedRangeGridItem
                            item
                            selected={dateHelper.period.isYear(values)}
                            onClick={handleSelectCurrentYear}
                        >
                            Этот год
                        </DefinedRangeGridItem>
                        <DefinedRangeGridItem
                            item
                            selected={dateHelper.period.isYear(values, -1)}
                            onClick={handleSelectLastYear}
                        >
                            Прошлый год
                        </DefinedRangeGridItem>
                    </Grid>
                    <Divider flexItem orientation="vertical" sx={{ marginLeft: '20px', marginRight: '20px' }} />
                </>
            )}

            <Box>
                <Calendar
                    value={calendarDate}
                    onChange={setCalendarDate}
                    onClickDay={handleClickDay}
                    highlightToday={highlightToday}
                    onMouseOverDay={setHoveredDate}
                    onMouseLeaveBody={clearHoveredDate}
                    daySx={getDaySx}
                />
            </Box>
        </Box>
    )
}

export default DateRangePicker
