import { Add as AddIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Chip, Grid, IconButton, Typography } from '@mui/material'
import ConfirmationWindow from 'app/components/ConfirmationWindow'
import Input from 'app/components/Input'
import Select from 'app/components/Select'
import Switch from 'app/components/Switch'
import { useToast, useToggle } from 'hooks'
import React, { useCallback, useRef, useState } from 'react'
import apiService from 'services/api-service'

type Values = {
    id?: string
    name?: string
    avgPrice?: number | string
    priceUnit?: string
    subcategoryId?: string
    keywords?: string[]
    online?: boolean
}

type WorkFormProps = {
    initialValues?: Values
    onDone?: () => void
}

const sup2 = '\u{00B2}'
const sup3 = '\u{00B3}'

const priceInitOptions = [`м${sup2}`, `м${sup3}`, 'пог.м', 'услуга', `100 м${sup2}`, 'шт.', 'час'].map((value) => ({
    value,
    title: value,
}))

const useWorkForm = (initialValues?: Values, onDone?: () => void) => {
    const isNewWork = useRef(!initialValues?.id)

    const [values, setValues] = useState(initialValues ?? {})
    const [newKeyword, setNewKeyword] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const toast = useToast()

    const showRemoveButton = !isNewWork.current
    const {
        opened: isVisibleRemoveConfirmation,
        open: showRemoveConfirmation,
        close: closeRemoveConfirmation,
    } = useToggle()
    const [removing, setRemoving] = useState(false)

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = event.target
            setValues((values) => ({ ...values, [name]: value }))
        },
        [setValues]
    )

    const handleChangePriceUnit = useCallback(
        (priceUnit: string) => setValues((values) => ({ ...values, priceUnit })),
        [setValues]
    )

    const handleChangeNewKeyword = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => setNewKeyword(event.target.value),
        [setNewKeyword]
    )

    const handleAddKeyword = useCallback(
        (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
            if (newKeyword.length === 0) {
                return
            }

            if (event.type === 'click' || ('code' in event && event.code === 'Enter')) {
                setValues((values) => ({ ...values, keywords: [newKeyword, ...(values.keywords ?? [])] }))
                setNewKeyword('')
            }
        },
        [setValues, setNewKeyword, newKeyword]
    )

    const handleRemoveKeyword = useCallback(
        (keyword: string) =>
            setValues((values) => ({ ...values, keywords: values.keywords?.filter((name) => name !== keyword) })),
        [setValues]
    )

    const toggleOnline = useCallback(() => setValues((values) => ({ ...values, online: !values.online })), [setValues])

    const handleSubmit = useCallback(async () => {
        setSubmitting(true)

        try {
            if (isNewWork.current) {
                await apiService.createWork({
                    name: values.name ?? '',
                    avgPrice: Number(values.avgPrice ?? 0),
                    priceUnit: values.priceUnit ?? '-',
                    keywords: values.keywords ?? [],
                    subcategoryId: values.subcategoryId ?? '-',
                    online: values.online ?? false,
                })
            } else {
                await apiService.changeWork(values.id ?? '-', {
                    name: values.name,
                    avgPrice: values.avgPrice !== undefined ? Number(values.avgPrice) : undefined,
                    priceUnit: values.priceUnit,
                    keywords: values.keywords,
                    online: values.online,
                })
            }

            onDone?.()
        } catch (error: any) {
            toast.showError(error.message)
        } finally {
            setSubmitting(false)
        }
    }, [values, onDone, toast])

    const handleRemove = useCallback(async () => {
        const workId = initialValues?.id
        if (!workId) {
            return
        }

        try {
            setRemoving(true)
            await apiService.removeWork(workId)
        } catch (error: any) {
            toast.showError(error.message)
            closeRemoveConfirmation()
        } finally {
            setRemoving(false)
            onDone?.()
        }
    }, [toast, initialValues?.id, onDone, closeRemoveConfirmation])

    return {
        isNewWork: isNewWork.current,
        showRemoveButton,
        values,
        newKeyword,
        submitting,
        handleChange,
        handleChangePriceUnit,
        handleChangeNewKeyword,
        handleAddKeyword,
        handleRemoveKeyword,
        toggleOnline,
        handleSubmit,
        handleRemove,
        isVisibleRemoveConfirmation,
        showRemoveConfirmation,
        closeRemoveConfirmation,
        removing,
    }
}

function WorkForm({ initialValues, onDone }: WorkFormProps) {
    const {
        isNewWork,
        showRemoveButton,
        values,
        newKeyword,
        submitting,
        handleChange,
        handleChangePriceUnit,
        handleChangeNewKeyword,
        handleAddKeyword,
        handleRemoveKeyword,
        toggleOnline,
        handleSubmit,
        handleRemove,
        isVisibleRemoveConfirmation,
        showRemoveConfirmation,
        closeRemoveConfirmation,
        removing,
    } = useWorkForm(initialValues, onDone)

    const title = isNewWork ? 'Создание услуги' : 'Изменение услуги'
    const submitButtonText = isNewWork ? 'Создать' : 'Сохранить'

    return (
        <Box>
            <Typography variant="h4" fontWeight={700}>
                {title}
            </Typography>
            <Box mt={3}>
                <Input
                    label="Название"
                    placeholder="Починить раковину"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    cleanable
                />
                <Grid
                    container
                    sx={{
                        mt: 3,
                    }}
                    columnSpacing={3}
                >
                    <Grid item xs>
                        <Input
                            label="Цена"
                            placeholder="500 ₽"
                            name="avgPrice"
                            value={values.avgPrice?.toString()}
                            onChange={handleChange}
                            cleanable
                        />
                    </Grid>
                    <Grid item xs>
                        <Select
                            value={values.priceUnit}
                            label="Единица измерения"
                            options={priceInitOptions}
                            onChange={handleChangePriceUnit}
                        />
                    </Grid>
                </Grid>

                <Box mt={3}>
                    <Input
                        label="Ключевое слово"
                        placeholder="Сломалась раковина"
                        value={newKeyword}
                        onChange={handleChangeNewKeyword}
                        onKeyUp={handleAddKeyword}
                        endAdornment={
                            newKeyword.length > 0 ? (
                                <IconButton onClick={handleAddKeyword} size="small">
                                    <AddIcon fontSize="small" />
                                </IconButton>
                            ) : null
                        }
                    />
                    <Box>
                        {values.keywords?.map((keyword) => (
                            <Chip
                                sx={{ mr: 1, mt: 1 }}
                                key={keyword}
                                label={keyword}
                                onDelete={() => handleRemoveKeyword(keyword)}
                            />
                        ))}
                    </Box>
                </Box>

                <Box mt={4}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1" mr={1}>
                            Онлайн-услуга
                        </Typography>
                        <Switch checked={values.online ?? false} onToggle={toggleOnline} />
                    </Box>
                    <Typography variant="subtitle2" color="secondary.main" mt={1}>
                        Если опция включена, то мастер с данной услугой будет получать заявки вне зависимости от его
                        города
                    </Typography>
                </Box>

                <Box display="flex" alignItems="center" mt={5}>
                    <LoadingButton
                        loading={submitting}
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        {submitButtonText}
                    </LoadingButton>

                    {showRemoveButton && (
                        <Button
                            size="large"
                            variant="text"
                            color="error"
                            sx={{ ml: 2 }}
                            onClick={showRemoveConfirmation}
                        >
                            Удалить
                        </Button>
                    )}
                </Box>
            </Box>
            <ConfirmationWindow
                title="Вы уверены, что хотите удалить услугу?"
                opened={isVisibleRemoveConfirmation}
                onClose={closeRemoveConfirmation}
                onConfirm={handleRemove}
                confirming={removing}
            />
        </Box>
    )
}

export default WorkForm
