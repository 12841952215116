import { useUrlQuery } from 'hooks'
import { useCallback, useMemo } from 'react'
import { dateHelper } from 'utils'

type Filter = {
    cityId?: string
    dateInterval?: [Date, Date]
}

type Query = {
    cityId?: string
    dateInterval?: string
}

function useFilter() {
    const { query, changeQuery } = useUrlQuery<Query>()

    const currentFilter: Filter = useMemo(
        () => ({
            ...query,
            dateInterval: query.dateInterval !== undefined ? dateHelper.period.parse(query.dateInterval) : undefined,
        }),
        [query]
    )

    const changeFilter = useCallback(
        (newFilter: Filter) => {
            const nextFilter = { ...currentFilter, ...newFilter }
            const nextQuery = {
                ...nextFilter,
                dateInterval: nextFilter.dateInterval && dateHelper.period.stringify(nextFilter.dateInterval),
            }
            changeQuery(nextQuery)
        },
        [currentFilter, changeQuery]
    )

    const resetFilter = useCallback(() => changeQuery({}), [changeQuery])

    const isEmpty = useMemo(() => Object.values(currentFilter).every((value) => value === undefined), [currentFilter])

    return {
        filter: currentFilter,
        isEmpty,
        changeFilter,
        resetFilter,
    }
}

export default useFilter
