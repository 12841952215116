import React from 'react'

type MultilineTextEllipsisProps = {
    children: string
    lines: number
}

const MultilineTextEllipsis = React.forwardRef<HTMLDivElement, MultilineTextEllipsisProps>(
    ({ lines, children }, ref) => (
        <span
            ref={ref}
            style={{
                lineClamp: lines,
                WebkitLineClamp: lines,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
            }}
        >
            {children}
        </span>
    )
)

export default MultilineTextEllipsis
