import { Box, Typography } from '@mui/material'
import { ReactComponent as SortSVG } from 'assets/icons/Sort.svg'
import { ReactComponent as SortDownSVG } from 'assets/icons/SortDown.svg'
import React from 'react'
import { TTableOrder } from 'types/ITable'

interface TableHeaderRowProps {
    title: string
    alignTitle?: 'right' | 'inherit' | 'left' | 'center' | 'justify'
    name: string
    isSort?: boolean
    order?: TTableOrder
    onClick?: (order: TTableOrder) => void
}

export const TableHeaderRow: React.FC<TableHeaderRowProps> = ({ title, alignTitle, name, isSort, order, onClick }) => {
    const handleCLick = () => {
        if (!isSort) return
        if (name !== order?.row) {
            onClick?.({
                row: name,
                order: 'desc',
            })
        } else {
            onClick?.({
                row: name,
                order: order?.order === 'asc' ? 'desc' : 'asc',
            })
        }
    }

    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            onClick={handleCLick}
            sx={{ cursor: isSort ? 'pointer' : 'default' }}
        >
            {isSort && name !== order?.row && <SortSVG />}

            {name === order?.row && order.order === 'desc' && <SortDownSVG />}
            {name === order?.row && order.order === 'asc' && <SortDownSVG style={{ transform: 'rotate(180deg)' }} />}

            <Typography
                width={'100%'}
                variant="body2"
                fontWeight={600}
                color={name === order?.row ? 'black' : 'grey.600'}
                ml={isSort ? 0.75 : 0}
                textAlign={alignTitle}
            >
                {title}
            </Typography>
        </Box>
    )
}
