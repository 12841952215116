import { Box, Paper } from '@mui/material'
import { PageLoader } from 'app/components/Loaders'
import { useCustomer } from 'app/modules/Customer'
import { useRouter } from 'hooks'
import React from 'react'

import BlockingToggler from './BlockingToggler'
import CustomerBlockingInfo from './CustomerBlockingInfo'
import CustomerOrders from './CustomerOrders'
import CustomerProfile from './CustomerProfile'
import CustomerReviews from './CustomerReviews'
import useCustomerOrders from './useCustomerOrders'
import useCustomerReviews from './useCustomerReviews'

type CustomerPageLayoutProps = {
    profile: React.ReactNode
    blockingToggler: React.ReactNode
    blockingInfo?: React.ReactNode
    orders?: React.ReactNode
    reviews?: React.ReactNode
}
const CustomerPageLayout = ({ profile, blockingToggler, blockingInfo, orders, reviews }: CustomerPageLayoutProps) => {
    return (
        <Paper
            sx={{
                bgcolor: 'white',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                p: 4,
                position: 'relative',
            }}
            elevation={0}
        >
            {profile}
            <Box position="absolute" top={16} right={16}>
                {blockingToggler}
            </Box>
            {blockingInfo && <Box mt={4}>{blockingInfo}</Box>}
            {orders && <Box mt={4}>{orders}</Box>}
            {reviews && <Box mt={4}>{reviews}</Box>}
        </Paper>
    )
}

const CustomerPage = () => {
    const customerData = useCustomer()
    const ordersData = useCustomerOrders()
    const reviewsData = useCustomerReviews()

    const router = useRouter()
    const onClickShowAllReviews = () => router.goToCustomerReviewsPage(customerData.customer?.id ?? '')

    if (customerData.error) {
        return <PageLoader error={customerData.error} />
    }

    if (!customerData.loaded || !customerData.customer || !ordersData.loaded || !reviewsData.loaded) {
        return <PageLoader />
    }

    return (
        <CustomerPageLayout
            profile={<CustomerProfile customer={customerData.customer} />}
            blockingToggler={<BlockingToggler />}
            blockingInfo={
                customerData.customer.blocking ? (
                    <CustomerBlockingInfo blocking={customerData.customer.blocking} />
                ) : undefined
            }
            orders={
                ordersData.orders.length !== 0 ? (
                    <CustomerOrders
                        customerId={customerData.customer.id}
                        orders={ordersData.orders}
                        totalCount={ordersData.totalCount}
                    />
                ) : undefined
            }
            reviews={
                reviewsData.reviews.length !== 0 ? (
                    <CustomerReviews
                        reviews={reviewsData.reviews}
                        totalCount={reviewsData.totalCount}
                        onShowAllReviews={onClickShowAllReviews}
                    />
                ) : undefined
            }
        />
    )
}

export default CustomerPage
