import { Box, Grid, Typography } from '@mui/material'
import Avatar from 'app/components/Avatar'
import CityName from 'app/components/CityName'
import { ReactComponent as CalendarSvg } from 'assets/icons/Calendar.svg'
import { ReactComponent as LocationSvg } from 'assets/icons/Location.svg'
import { ReactComponent as PhoneSvg } from 'assets/icons/Phone.svg'
import { ReactComponent as StarSvg } from 'assets/icons/star.svg'
import React from 'react'
import { dateHelper, formatPhone } from 'utils'

type WorkerProfileProps = {
    worker: {
        id: string
        phone: string
        firstname: string | null
        lastname: string | null
        avatarUrl: string | null
        avgRating?: {
            value: number
            count: number
        }
        description: string | null
        cityId?: string
        createdAt: string
    }
}

function WorkerProfile({ worker }: WorkerProfileProps) {
    return (
        <Box>
            <Grid item>
                <Grid container spacing={4}>
                    <Grid item>
                        <Avatar
                            srcUrl={worker.avatarUrl}
                            firstName={worker.firstname}
                            lastName={worker.lastname}
                            size="l"
                        />
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <Typography variant="h4" fontWeight={700}>
                                            {worker.lastname} {worker.firstname}
                                        </Typography>
                                    </Grid>
                                    {worker.avgRating?.value && (
                                        <Grid item>
                                            <Grid container alignItems="center">
                                                <Grid item>
                                                    <StarSvg />
                                                </Grid>
                                                <Grid item>
                                                    <Box sx={{ marginLeft: '12px', marginRight: '24px' }}>
                                                        <Typography variant="h4" fontWeight={700}>
                                                            {`${worker.avgRating.value.toFixed(1)} (${
                                                                worker.avgRating.count
                                                            })`}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <PhoneSvg />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle2" color="grey.600">
                                            {formatPhone(worker.phone)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ marginLeft: '70px' }}>
                                {worker.cityId && (
                                    <Grid container>
                                        <Grid item sx={{ mr: '6px' }}>
                                            <LocationSvg />
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="subtitle2"
                                                sx={(theme) => ({ color: theme.palette.grey['600'] })}
                                            >
                                                <CityName cityId={worker.cityId} />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Box>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <CalendarSvg />
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2" color="grey.600">
                                        {`На сервисе с ${dateHelper.formatDate(worker.createdAt, {
                                            format: 'DD.MM.YYYY',
                                        })}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Box marginTop={2}>
                {worker.description ? `О себе: ${worker.description}` : 'Отсутствует описание о себе'}
            </Box>
        </Box>
    )
}

export default WorkerProfile
