import { Grid } from '@mui/material'
import { CityFilter, DateRangeFilter } from 'app/components/Filters'
import React, { useCallback } from 'react'

import useFilter from './useFilter'

const Filters = () => {
    const { filter, changeFilter } = useFilter()

    const handleChangeCityFilter = useCallback((cityId: string | undefined) => changeFilter({ cityId }), [changeFilter])
    const handleChangeDateIntervalFilter = useCallback(
        (dateInterval?: [Date, Date] | undefined) => changeFilter({ dateInterval }),
        [changeFilter]
    )

    return (
        <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item>
                <CityFilter cityId={filter.cityId} onChange={handleChangeCityFilter} />
            </Grid>
            <Grid item>
                <DateRangeFilter
                    dates={filter.dateInterval}
                    onChange={handleChangeDateIntervalFilter}
                    label="Период"
                    placeholder="Все даты"
                />
            </Grid>
        </Grid>
    )
}

export default Filters
