import PlaceIcon from '@mui/icons-material/Place'
import { Tooltip } from '@mui/material'
import React from 'react'

interface MarkerProps {
    text?: string
    lat: number // используется в google-map-react
    lng: number // используется в google-map-react
}

function Marker({ text = '' }: MarkerProps) {
    return (
        <Tooltip
            title={text}
            sx={() => ({
                margin: '-17.5px 0 0 -17.5px',
            })}
        >
            <PlaceIcon color="error" fontSize="large" />
        </Tooltip>
    )
}

export default Marker
