export const PROJECT_NAME = process.env.REACT_APP_NAME

export enum OrderState {
    NEW = 'NEW',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
    ARCHIVE = 'ARCHIVE',
}

export const CANCELLABLE_ORDER_STATES = [OrderState.NEW, OrderState.IN_PROGRESS, OrderState]
export const ORDER_STATES_FOR_FILTER = [
    OrderState.NEW,
    OrderState.IN_PROGRESS,
    OrderState.COMPLETED,
    OrderState.CANCELLED,
    OrderState.ARCHIVE,
]

export const ORDERS_STATE_NAMES: { [key in OrderState]: string } = {
    NEW: 'Поиск исполнителя',
    IN_PROGRESS: 'В работе',
    COMPLETED: 'Завершён',
    CANCELLED: 'Отменён',
    ARCHIVE: 'В архиве',
}

export const ORDER_STATE_COLORS = {
    [OrderState.NEW]: '#ff9500',
    [OrderState.IN_PROGRESS]: '#4bb56f',
    [OrderState.COMPLETED]: '#8a8a8e',
    [OrderState.CANCELLED]: '#8a8a8e',
    [OrderState.ARCHIVE]: '#8a8a8e',
}

export enum EmployeeRole {
    DIRECTOR = 'DIRECTOR',
    MANAGER = 'MANAGER',
    CITY_DIRECTOR = 'CITY_DIRECTOR',
    CITY_MANAGER = 'CITY_MANAGER',
}

export const EMPLOYEE_ROLE_NAMES: { [key in keyof typeof EmployeeRole]: string } = {
    DIRECTOR: 'Директор',
    MANAGER: 'Менеджер',
    CITY_DIRECTOR: 'Директор города',
    CITY_MANAGER: 'Менеджер города',
}
