import CloseIcon from '@mui/icons-material/Close'
import { Box, Dialog, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import React from 'react'

type ModalProps = {
    opened: boolean
    onClose: () => void
    children: React.ReactNode
    title?: string | React.ReactElement
}

function Modal({ opened, onClose, children, title }: ModalProps) {
    return (
        <Dialog
            open={opened}
            onClose={onClose}
            onClick={(e) => e.stopPropagation()}
            aria-labelledby="model-dialog"
            maxWidth={false}
        >
            <Box sx={{ py: 3, px: 5, position: 'relative' }}>
                {typeof title === 'string' && (
                    <Typography variant="h6" fontWeight={500} whiteSpace="pre-line">
                        {title}
                    </Typography>
                )}
                {typeof title === 'object' && title}

                <IconButton
                    aria-label="close"
                    onClick={(e) => {
                        e.stopPropagation()
                        onClose()
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 10,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Box sx={{ mt: 4 }}>{children}</Box>
            </Box>
        </Dialog>
    )
}

export default Modal
