import { PageLoader } from 'app/components/Loaders'
import React from 'react'

import OrderActionsButton from './OrderActionsButton'
import OrderCancellationReason from './OrderCancellationReason'
import OrderCustomer from './OrderCustomer'
import OrderDateTime from './OrderDateTime'
import OrderLocation from './OrderLocation'
import OrderPageLayout from './OrderPageLayout'
import OrderPrice from './OrderPrice'
import OrderResponses from './OrderResponses'
import OrderStatus from './OrderStatus'
import OrderTask from './OrderTask'
import OrderWorker from './OrderWorker'
import OrderWorkerViews from './OrderWorkerViews'
import useOrder from './useOrder'

function OrderPage() {
    const { order, loaded } = useOrder()

    if (!loaded) {
        return <PageLoader />
    }

    if (!order) {
        return <PageLoader error="Заказ не найден" />
    }

    return (
        <OrderPageLayout
            orderNumber={order.number}
            viewsCount={<OrderWorkerViews viewsCount={order.viewsCount} />}
            status={<OrderStatus status={order.state} />}
            publishedAt={order.publishedAt}
            task={<OrderTask works={order.works} photoUrls={order.imageUrls} comment={order.comment} />}
            price={<OrderPrice totalPrice={order.totalPrice} customerPrice={order.customerPrice} />}
            dateTime={<OrderDateTime datetime={order.datetime} />}
            customer={<OrderCustomer customer={order.customer} />}
            worker={<OrderWorker worker={order.worker} />}
            location={<OrderLocation location={order.location} />}
            actions={<OrderActionsButton />}
            cancellationReason={
                order.cancellationReason && <OrderCancellationReason reason={order.cancellationReason} />
            }
            orderResponses={<OrderResponses />}
        />
    )
}

export default OrderPage
