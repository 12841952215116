import { useRouteParams, useToast } from 'hooks'
import { useCallback, useState } from 'react'
import apiService from 'services/api-service'
import useSWR from 'swr'

const ONE_MINUTE = 1 * 60 * 1000

const useWorker = () => {
    const toast = useToast()
    const { workerId = '' } = useRouteParams()
    const {
        data,
        error,
        mutate: update,
    } = useSWR(['worker', workerId], () => apiService.getWorker({ workerId }), {
        focusThrottleInterval: ONE_MINUTE,
        refreshInterval: ONE_MINUTE,
    })

    const [blockingLoading, setBlockLoading] = useState(false)
    const block = useCallback(
        async (reason: { type: number; comment?: string }) => {
            try {
                setBlockLoading(true)
                await apiService.blockWorker({ workerId, reason })
                await update()
            } catch (error: any) {
                toast.showError(error.message)
            } finally {
                setBlockLoading(false)
            }
        },
        [workerId, toast, update]
    )
    const unblock = useCallback(async () => {
        try {
            setBlockLoading(true)
            await apiService.unblockWorker({ workerId })
            await update()
        } catch (error: any) {
            toast.showError(error.message)
        } finally {
            setBlockLoading(false)
        }
    }, [workerId, toast, update])

    return {
        worker: data,
        loaded: Boolean(data) || Boolean(error),
        error,
        blockingLoading,
        block,
        unblock,
    }
}

export default useWorker
