import { useUrlQuery } from 'hooks'
import { useCallback, useMemo } from 'react'
import { dateHelper } from 'utils'

type Filter = {
    number?: string
    customerPhone?: string
    workerPhone?: string
    workerId?: string
    customerId?: string
    hideFilters?: boolean
    status?: string
    cityId?: string
    createdInterval?: [Date, Date]
}

type Query = {
    number?: string
    customerPhone?: string
    workerPhone?: string
    status?: string
    cityId?: string
    createdInterval?: string
    workerId?: string
    customerId?: string
    hideFilters?: string
}

function useFilter() {
    const { query, changeQuery } = useUrlQuery<Query>()

    const currentFilter: Filter = useMemo(
        () => ({
            ...query,
            hideFilters: query.hideFilters ? Boolean(query.hideFilters) : undefined,
            createdInterval:
                query.createdInterval !== undefined ? dateHelper.period.parse(query.createdInterval) : undefined,
        }),
        [query]
    )

    const changeFilter = useCallback(
        (newFilter: Filter) => {
            const nextFilter = { ...currentFilter, ...newFilter }
            const nextQuery = {
                ...nextFilter,
                hideFilters: nextFilter.hideFilters ? '1' : undefined,
                customerPhone: nextFilter.customerPhone?.trim()?.replace(/\D/g, '') || undefined,
                workerPhone: nextFilter.workerPhone?.trim()?.replace(/\D/g, '') || undefined,
                createdInterval: nextFilter.createdInterval && dateHelper.period.stringify(nextFilter.createdInterval),
            }
            changeQuery(nextQuery)
        },
        [currentFilter, changeQuery]
    )

    const resetFilter = useCallback(() => changeQuery({}), [changeQuery])

    const isEmpty = useMemo(() => Object.values(currentFilter).every((value) => value === undefined), [currentFilter])

    return {
        filter: currentFilter,
        isEmpty,
        changeFilter,
        resetFilter,
    }
}

export default useFilter
