import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, FormControl, InputLabel, MenuItem, Select as MaterialSelect, SelectChangeEvent } from '@mui/material'
import React, { useCallback } from 'react'

type SelectOption = {
    title: string
    value: string
}

type SelectProps<T> = {
    value?: T
    onChange: (value: T) => void
    options: SelectOption[]
    label: string
    minWidth?: number
}

export function Select<T extends string | string[]>({
    value,
    options,
    label,
    onChange,
    minWidth = 120,
}: SelectProps<T>) {
    const handleChange = useCallback((event: SelectChangeEvent<T>) => onChange(event.target.value as T), [onChange])

    return (
        <Box sx={{ minWidth }}>
            <FormControl fullWidth>
                <InputLabel
                    sx={(theme) => ({
                        '&.MuiInputLabel-root': {
                            color: theme.palette.secondary.main,
                            transform: 'translate(12px, 18px) scale(1)',
                            '&.Mui-focused': {
                                transform: 'translate(12px, 7px) scale(0.75)',
                            },
                            '&.MuiFormLabel-filled': {
                                transform: 'translate(12px, 7px) scale(0.75)',
                            },
                        },
                    })}
                >
                    {label}
                </InputLabel>
                <MaterialSelect
                    id={`${label}-select`}
                    value={value}
                    variant="filled"
                    label={label}
                    onChange={handleChange}
                    disableUnderline
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    MenuProps={{
                        sx: (theme) => ({
                            '& .MuiPaper-root': {
                                marginTop: 1,
                                borderRadius: 2,
                                boxShadow: '0 1px 10px rgb(0 0 0 / 10%)',
                            },
                            '& .MuiMenuItem-root.Mui-focusVisible': {
                                backgroundColor: 'transparent',
                            },
                            '& .MuiMenuItem-root.Mui-selected': {
                                backgroundColor: 'rgba(75, 181, 111, 0.08)',
                            },
                            '& .MuiMenuItem-root:hover': {
                                backgroundColor: theme.palette.background.default,
                            },
                        }),
                    }}
                    sx={(theme) => ({
                        '&.MuiFilledInput-root': {
                            backgroundColor: theme.palette.background.default,
                            borderRadius: 2,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: '#eae9e8',
                            transition: 'border-color ease 0.3s',
                            '&:hover': {
                                filter: 'brightness(99%)',
                                backgroundColor: theme.palette.background.default,
                            },
                            '&.Mui-focused': {
                                borderColor: theme.palette.primary.main,
                            },
                        },
                        '& .MuiSelect-select': {
                            pb: 1.25,
                        },
                        '& .MuiSelect-icon': {
                            color: theme.palette.secondary.main,
                        },
                        '& .MuiSelect-iconOpen': {
                            color: theme.palette.primary.main,
                        },
                    })}
                >
                    {options.map((item) => {
                        return (
                            <MenuItem disableRipple value={item.value} key={item.value}>
                                {item.title}
                            </MenuItem>
                        )
                    })}
                </MaterialSelect>
            </FormControl>
        </Box>
    )
}
