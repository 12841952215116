import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, Button, Grid } from '@mui/material'
import { Feedback } from 'app/components/Feedback'
import { TitleBlock } from 'app/components/TitleBlock'
import React from 'react'

type WorkerReviewsProps = {
    reviews: Array<{
        id: string
        score: number
        message: string
        customer: {
            firstname?: string
            avatarUrl?: string
        }
        createdAt: string
    }>
    totalCount: number
    onShowAllReviews: () => void
}

function WorkerReviews({ reviews, totalCount, onShowAllReviews }: WorkerReviewsProps) {
    return (
        <Box>
            <TitleBlock
                title="Отзывы"
                count={totalCount}
                variant="h5"
                px={0}
                mb={2}
                rightElement={
                    totalCount > 0 && (
                        <Button
                            variant="text"
                            onClick={onShowAllReviews}
                            color="secondary"
                            endIcon={<ChevronRightIcon />}
                        >
                            Показать все
                        </Button>
                    )
                }
            />
            <Grid container spacing={1}>
                {reviews.map((item) => (
                    <Grid item key={item.id} xs={4}>
                        <Feedback
                            name={item.customer.firstname ?? '-'}
                            text={item.message}
                            rating={item.score}
                            id={item.id}
                            date={item.createdAt}
                            photoUrl={item.customer.avatarUrl}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default WorkerReviews
