import { AddBoxOutlined as AddBoxOutlinedIcon } from '@mui/icons-material'
import { Box, Button, Typography, TypographyVariant } from '@mui/material'
import React from 'react'

type TitleBlockProps = {
    title: string
    subtitle?: string
    variant?: TypographyVariant
    count?: number
    px?: number
    mb?: number
    mt?: number
    oldCount?: number
    addText?: string
    handleCreate?: () => void
    rightElement?: React.ReactNode
}

export const TitleBlock: React.FC<TitleBlockProps> = ({
    title,
    subtitle,
    count,
    oldCount,
    addText,
    handleCreate,
    variant = 'h4',
    px = 4,
    mt,
    mb,
    rightElement,
}) => (
    <Box display="flex" justifyContent="space-between" px={px} mt={mt} mb={mb}>
        <Box display={'flex'}>
            <Typography variant={variant} fontWeight={700}>
                {title}
            </Typography>
            {subtitle && (
                <Typography variant={variant} fontWeight={400} color="grey.400" ml={1}>
                    {subtitle}
                </Typography>
            )}
            {!!count && (
                <Typography variant={variant} fontWeight={400} color="grey.400" ml={1}>
                    {count.toLocaleString()}
                </Typography>
            )}
            {!!oldCount && (
                <Typography
                    variant="h5"
                    fontWeight={400}
                    color="grey.400"
                    ml={1}
                >{`(${oldCount.toLocaleString()})`}</Typography>
            )}
        </Box>

        <Box display="flex">
            {!!addText && (
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddBoxOutlinedIcon />}
                    sx={{
                        ml: 1,
                    }}
                    onClick={() => handleCreate?.()}
                >
                    {addText}
                </Button>
            )}

            {rightElement}
        </Box>
    </Box>
)
