import { useRouteParams } from 'hooks'
import { useEffect, useState } from 'react'
import apiService from 'services/api-service'

import { Employee } from './types'

const useEmployee = () => {
    const { employeeId = '' } = useRouteParams()
    const [data, setData] = useState<{ employee?: Employee; loaded?: boolean; error?: string }>({})

    useEffect(() => {
        apiService
            .getEmployee({ employeeId })
            .then((employee) => setData({ employee, loaded: true }))
            .catch((error) => setData({ loaded: true, error: error.data.message }))
    }, [employeeId])

    return data
}

export default useEmployee
