import { useRouteParams } from 'hooks'
import apiService from 'services/api-service'
import useSWR from 'swr'

const useWorkerReviews = () => {
    const { workerId = '' } = useRouteParams()
    const { data } = useSWR(['worker-reviews', workerId], () => apiService.getWorkerReviews({ workerId }))

    return {
        reviews: data?.items ?? [],
        totalCount: data?.totalCount ?? 0,
    }
}

export default useWorkerReviews
