import { useEffect, useState } from 'react'
import apiService, { CustomerBlockingReason } from 'services/api-service'

const useCustomerBlockingReasons = () => {
    const [reasonsData, setReasonsData] = useState<{ reasons: CustomerBlockingReason[]; loaded: boolean }>()

    useEffect(() => {
        apiService.getCustomerBlockingReasons().then((reasons) => {
            setReasonsData({ reasons, loaded: true })
        })
    }, [])

    return {
        reasons: reasonsData?.reasons ?? [],
        loaded: reasonsData?.loaded ?? false,
    }
}

export default useCustomerBlockingReasons
