import { useRouteParams } from 'hooks'
import apiService from 'services/api-service'
import useSWR from 'swr'

const useCustomerReviews = () => {
    const { customerId = '' } = useRouteParams()
    const { data } = useSWR(['customer-reviews', customerId], () => apiService.getCustomerReviews({ customerId }))

    return {
        reviews: data?.items ?? [],
        totalCount: data?.totalCount ?? 0,
    }
}

export default useCustomerReviews
